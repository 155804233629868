import React from 'react';
import { useLocation, useHistory } from 'react-router';
import { parse, stringify } from 'query-string';

export function useQueryParams<T>(
  defaultQueryParams: T,
): {
  search: string;
  queryParams: T;
  setQueryParams: (queryParams: Partial<T>) => void;
} {
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const parsedParams = parse(location.search);
    const stringifiedParams = stringify({ ...defaultQueryParams, ...parsedParams });
    history.push({
      search: '?' + stringifiedParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const parsedQueryParams = location.search
    ? ((parse(location.search) as unknown) as T)
    : defaultQueryParams;

  return {
    search: location.search,
    queryParams: parsedQueryParams,
    setQueryParams: (queryParams: Partial<T>) => {
      const stringifiedParams = stringify({ ...parsedQueryParams, ...queryParams });
      history.push({
        search: '?' + stringifiedParams,
      });
    },
  };
}
