import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';

interface IStatistic {
  label: string | number;
  startIcon?: any;
  endIcon?: any;
  className?: any;
}

export const Statistic = ({ startIcon, endIcon, label, className }: IStatistic) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" className={className}>
      {startIcon && <span>{startIcon}</span>}
      <span className={classes.label}>{label}</span>
      {endIcon && <span>{endIcon}</span>}
    </Box>
  );
};
