import React from 'react';
import { Create } from 'react-admin';
import { Box, Tab, Tabs } from '@material-ui/core';
import { Header } from '../Header';
import { JobMap } from '../JobMap';
import { VehicleTab } from '../VehicleTab';
import { JobTabs } from '../JobUtils';
import { JobFormWrapper } from '../JobFormWrapper';
import { TabLabel } from '../../components/TabLabel';
import { ProvidersLocationsContextProvider } from '../../contexts/ProvidersLocationsContext';
import { MapsProvider } from '../../contexts/MapsContext';
import theme from '../../styles/theme';
import { useStyles } from './styles';

export const JobCreate: React.FC<{}> = (props: any) => {
  const [tabValue, setTabValue] = React.useState(0);
  const classes = useStyles();

  // @ts-ignore
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <MapsProvider>
      <ProvidersLocationsContextProvider>
        <Box display="flex" flexBasis="100%">
          <JobFormWrapper>
            <Box
              borderBottom={`1px solid ${theme.palette.primary.light}`}
              boxShadow={`0px 0px 3px ${theme.palette.primary.light}`}
              zIndex={1}
            >
              <Header isCreatePage />
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label={<TabLabel text="Vehicle" />} value={JobTabs.VEHICLE} />
                <Tab
                  label={<TabLabel text="Contacts" alertBadge />}
                  value={JobTabs.CONTACTS}
                  disabled
                />
                <Tab label={<TabLabel text="Payments" />} value={JobTabs.PAYMENTS} disabled />
                <Tab label={<TabLabel text="Invoice" />} value={JobTabs.INVOICE} disabled />
              </Tabs>
            </Box>
            {tabValue === JobTabs.VEHICLE && (
              <VehicleTab {...props} tabValue={tabValue} formType={Create} />
            )}
          </JobFormWrapper>
          <Box className={classes.mapContainer}>
            <JobMap />
          </Box>
        </Box>
      </ProvidersLocationsContextProvider>
    </MapsProvider>
  );
};
