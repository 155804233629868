import { IJob } from './IJob';
import { useDatabase } from '../DatabaseProvider';
import { COLLECTIONS } from '../utils';
import { JobStatus } from './JobUtils';

export interface IJobsRepository {
  updateJob(id: string, data: Partial<IJob>): Promise<IJob>;
  getProviderCompletedApprovedJobs(providerId: string): Promise<IJob[]>;
}

export class JobsRepository implements IJobsRepository {
  private database = useDatabase().database;
  private static instance: IJobsRepository | undefined;

  static getRepository(): IJobsRepository {
    if (!this.instance) {
      this.instance = new JobsRepository();
    }
    return this.instance;
  }

  async getJob(jobId: string): Promise<IJob> {
    const job = await this.database.collection(COLLECTIONS.JOBS).doc(jobId).get();
    return job.data() as IJob;
  }

  async updateJob(jobId: string, data: Partial<IJob>) {
    await this.database.collection('jobs').doc(jobId).update(data);
    return this.getJob(jobId);
  }

  async getProviderCompletedApprovedJobs(providerId: string): Promise<IJob[]> {
    const jobDocs = await this.database
      .collection(COLLECTIONS.JOBS)
      .where('providerId', '==', providerId)
      .where('status', '==', JobStatus.COMPLETED)
      .where('pendingReview', '==', false)
      .orderBy('createdate', 'desc')
      .get();

    return jobDocs.docs.map(jobDoc => {
      return { ...jobDoc.data(), id: jobDoc.id } as IJob;
    });
  }
}
