import React from 'react';
import classnames from 'classnames';
import { useButtonStyles } from '../../styles/commonStyles';
import { Button, ButtonProps } from './index';

export const PrimaryButton = ({ children, className, ...props }: ButtonProps) => {
  const classes = useButtonStyles();

  return (
    <Button className={classnames(classes.defaultButton, className)} {...props}>
      {children}
    </Button>
  );
};
