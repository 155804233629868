import { getLatLng, GeocodeResult } from 'use-places-autocomplete';
import { parseToLatLong } from './geocoordinates';

export const deserializeGeocode = async (
  geocode: GeocodeResult[],
  getCoordinates: (geocode: GeocodeResult) => Promise<{ lat: number; lng: number }> = getLatLng,
) => {
  const formattedAddress = geocode[0].formatted_address;

  const newCoordinates = await getCoordinates(geocode[0]);
  const latLongCoordinates = parseToLatLong(newCoordinates);

  const { address_components } = geocode[0];
  const state = address_components.find(component =>
    component.types.includes('administrative_area_level_1'),
  )?.short_name;
  const city = address_components.find(component => component.types.includes('locality'))
    ?.long_name;

  return {
    formattedAddress,
    state: state || '',
    city: city || '',
    latLongCoordinates,
  };
};
