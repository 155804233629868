import React, { createContext, useState } from 'react';
import { usaCoordinates } from '../Map/mapUtils';
import { useLoadMap } from '../utils';
import { ICoordinates, IJobLocation } from '../Jobs/IJob';

export interface IMapsContext {
  isLoading: boolean;
  loadError?: Error;
  onMapLoad: (map: google.maps.Map) => void;
  coordinates: ICoordinates;
  setCoordinates: any;
  address: { value: string; type: string; state: string; city: string };
  setAddress: any;
  setLocation: (location: IJobLocation) => void;
}

export const MapsContext = createContext<IMapsContext | null>(null);

export const MapsProvider: React.FC = ({ children }) => {
  const [coordinates, setCoordinates] = useState(usaCoordinates);
  const [address, setAddress] = useState({ value: '', type: 'continent', state: '', city: '' });
  const { isMapLoading, mapLoadError, onMapLoad } = useLoadMap();

  const setLocation = (location: IJobLocation) => {
    if (location) {
      location.address &&
        setAddress({
          value: location.address,
          type: location.type,
          city: location.city,
          state: location.state,
        });
      setCoordinates({ lat: location.lat, long: location.long });
    }
  };

  return (
    <MapsContext.Provider
      value={{
        isLoading: isMapLoading,
        onMapLoad,
        loadError: mapLoadError,
        coordinates,
        setCoordinates,
        address,
        setAddress,
        setLocation,
      }}
    >
      {children}
    </MapsContext.Provider>
  );
};

export const useMaps = () => {
  const context = React.useContext(MapsContext);
  if (!context) throw new Error('useMaps must be used within a MapsProvider.');

  return context;
};
