import React from 'react';
import * as _ from 'lodash';
import theme from '../styles/theme';
import { IJob, IPay, IPayout } from '../Jobs/IJob';
import { IProvider } from '../Providers/Provider.interface';
import { IProviderLocation } from '../Map/Map.interface';
import { ProBadgeIcon, ProPlusBadgeIcon } from '../components/Icons';
import { PayoutStatus } from '../Jobs/JobUtils';

export function getProviderFullName(provider: IProvider | undefined) {
  return provider ? `${provider.firstName || ''} ${provider.lastName || ''}` : '';
}

export const getProviderStatus = (live: boolean, onJob: boolean) =>
  live ? (onJob ? 'onlineOnJob' : 'online') : onJob ? 'offlineOnJob' : 'offline';

export function splitByActivity(providers: IProviderLocation[]) {
  return _.partition(providers, function (provider: IProviderLocation) {
    return provider.live;
  });
}
export const isProPlusAchieved = (provider: IProvider) =>
  hasHighRating(provider) && hasCompletedJobs(provider) && hasHighAcceptRate(provider);

function hasHighRating(provider: IProvider): boolean {
  return Number(provider.rating) >= 4;
}

function hasCompletedJobs(provider: IProvider): boolean {
  return provider.completedJobs > 0;
}

function hasHighAcceptRate(provider: IProvider): boolean {
  return provider.acceptRate >= 95;
}

export const ProBadge = ({ isProviderProPlus }: { isProviderProPlus: boolean }) => {
  return isProviderProPlus ? (
    <ProPlusBadgeIcon style={{ color: theme.palette.primary.main }} />
  ) : (
    <ProBadgeIcon />
  );
};

export const getProviderBalance = (provider: IProvider, jobs: IJob[], payouts: IPayout[]) => {
  const positivePayouts = payouts.filter(
    payout => ![PayoutStatus.FAILED, PayoutStatus.CANCELED].includes(payout.status),
  );
  const payoutsInTotal = _.sumBy(positivePayouts, 'amount');
  const paysInTotal = _.sumBy(jobs, (job: IJob) => (job?.pays ? sumUpPays(job.pays) : 0));
  return paysInTotal - payoutsInTotal;
};

const sumUpPays = (pays: IPay[]) => pays.reduce((sum, pay) => sum + pay.amount * pay.quantity, 0);
