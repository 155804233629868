import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
// @ts-ignore
import { ServiceAreaBadge } from './VehicleTab/ServiceAreaField';
import { JobActions } from './JobActions';
import { IJob } from './IJob';
import { colors, font } from '../styles';
import theme from '../styles/theme';
import { parseJobNumber } from '../helpers/jobHelpers';
import { UserAvatar } from '../components/UserAvatar';
import JobStatusChip from '../components/Chips/JobStatusChip';
import { Statistic } from '../components/Ratings/Statistic';
import { getProviderFullName, getProviderStatus } from '../helpers/providerHelpers';
import { useProvider } from '../utils/hooks/useProviders';
import { StarIcon } from '../components/Icons';
import { PendingReviewChip } from '../components/Chips/PendingReviewChip';
import { closedJobStatuses } from './JobUtils';

export const Header = ({ isCreatePage, job }: { isCreatePage?: boolean; job?: IJob }) => {
  const classes = useStyles();
  const jobNumber = job?.jobNumber ? parseJobNumber(job.jobNumber) : '';

  return (
    <Box pt={3} pl={3} alignItems="left" className={classes.root}>
      <Box display="flex" px={1} justifyContent="space-between">
        <Typography variant="h4" style={{ fontWeight: font.weight.bold }}>
          {isCreatePage ? 'New Job' : `Job #${jobNumber}`}
        </Typography>
        {job && <JobDetails job={job} />}
      </Box>
    </Box>
  );
};

const JobDetails = ({ job }: any) => {
  const providerOnJob = useProvider(job.providerId).provider;
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" pr={3}>
      {providerOnJob && (
        <Box
          pr={1.5}
          mr={1.5}
          borderRight={`1px solid ${theme.palette.primary.light}`}
          height="20px"
        >
          <UserAvatar
            name={getProviderFullName(providerOnJob)}
            status={getProviderStatus(providerOnJob.live, providerOnJob.onJob)}
            width={20}
          />
        </Box>
      )}
      {job.pendingReview && closedJobStatuses.includes(job.status) && (
        <PendingReviewChip fontSize={16} />
      )}
      <JobStatusChip status={job.status} fontSize={16} />
      {job.rating && (
        <Statistic
          label={job.rating}
          endIcon={<StarIcon className={classes.icon} />}
          className={classes.rating}
        />
      )}
      {job.serviceArea && (
        <ServiceAreaBadge
          serviceAreaName={job.serviceArea.name}
          className={classes.serviceAreaBadge}
        />
      )}
      <JobActions job={job} />
    </Box>
  );
};

export const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.common.white,
  },
  serviceAreaBadge: {
    paddingLeft: spacing(1.5),
    marginLeft: spacing(1.5),
    borderLeft: `1px solid ${palette.primary.light}`,
    height: '20px',
  },
  icon: {
    height: '11px',
    width: '12px',
    color: colors.texasRose,
  },
  rating: {
    marginLeft: spacing(1),
  },
}));
