import React from 'react';
import { Box } from '@material-ui/core';
import { TextInput } from '../../components/Inputs/TextInput';
import { ServiceAreaField } from './ServiceAreaField';
import { ServiceSection } from './ServiceSection';
import { VehicleSection } from './VehicleSection';
import { LocationSection } from './LocationSection';
import { IJobCompany } from '../IJob';

interface FormFieldsProps {
  jobCompany: IJobCompany | null;
}

export const FormFields = ({ jobCompany }: FormFieldsProps) => {
  const companyOverridesRates = jobCompany?.override || false;
  const companyMinHoursRate = jobCompany?.minHours || 0;
  return (
    <Box display="flex" flexDirection="column">
      <LocationSection />
      <ServiceSection
        companyOverridesServiceRates={companyOverridesRates}
        companyMinHoursRate={companyMinHoursRate}
      />
      <VehicleSection />
      <Box display="flex" flexDirection="column" pt={0} px={3}>
        <ServiceAreaField
          jobCompanyDetails={
            jobCompany
              ? {
                  name: jobCompany.name,
                  override: jobCompany.override,
                  base: jobCompany.base,
                  afterhour: jobCompany.afterHour,
                  callout: jobCompany.callout,
                }
              : null
          }
        />
        <TextInput source="serviceArea.name" type="hidden" />
      </Box>
    </Box>
  );
};
