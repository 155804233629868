import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { JobEditForm } from '../JobEditForm';
import { IContacts } from '../../../interfaces';
import { sanitizeWithKeys } from '../../../helpers/formHelpers';
import { useStyles } from './styles';
import { FormFields } from './FormFields';

export const ContactsTab = (props: any) => (
  <JobEditForm {...props} transform={sanitizeBeforeSubmit} validation={validateContactInfo}>
    <FormFields />
  </JobEditForm>
);

const sanitizeBeforeSubmit = (data: any) => {
  return sanitizeWithKeys(data, ['dispatcher', 'driver', 'company']);
};

const validateContactInfo = (values: IContacts) => {
  return {
    ...invalidContactError(values, 'driver'),
    ...invalidContactError(values, 'dispatcher'),
    ...sameNumberError(values),
  };
};

const invalidContactError = (values: IContacts, contactType: 'driver' | 'dispatcher') => {
  let errors: any = {};

  if (values[contactType]?.firstName && !values[contactType]?.phoneNumber)
    errors[contactType] = { phoneNumber: 'empty' };
  if (values[contactType]?.phoneNumber && !values[contactType]?.firstName)
    errors[contactType] = { firstName: 'empty' };

  return errors;
};

const sameNumberError = (values: IContacts) => {
  if (values.driver?.phoneNumber === values.dispatcher?.phoneNumber && values.driver?.phoneNumber)
    return { driver: { phoneNumber: 'same number' }, dispatcher: { phoneNumber: 'same number' } };
};

export const ContactLabel = ({ text }: { text: string }) => {
  const classes = useStyles();

  return (
    <Box className={classes.subtitleWrapper}>
      <Typography variant="button" className={classes.subtitle}>
        {text}
      </Typography>
    </Box>
  );
};
