import { currencyFormat } from '../helpers/formatHelpers';
import { formatDateWithFullMonth, formatTime } from '../helpers/timeHelpers';
import { priceWithMarkup, parseJobNumber } from '../helpers/jobHelpers';
import {
  IInvoiceCommentDto,
  IInvoiceDataDto,
  IInvoiceServiceDto,
  IInvoiceVehicleDto,
} from '../Jobs/dtos/IInvoiceData.dto';

export interface IInvoiceDetail {
  name: string;
  description: string;
  calculations: string;
  cost: string;
}

export interface IFormattedInvoiceData {
  formattedTotalCost: string;
  totalPartsCost: string;
  totalLaborCost: string;
  travelCost: string;
  fuelCost: string;
  providerName: string;
  bookedOn: string;
  formattedJobNumber: string;
  dispatcherName: string;
  driverName: string;
  vehicleDescription: string;
  labor: IInvoiceDetail;
  afterHours?: IInvoiceDetail;
  formattedParts?: IInvoiceDetail[];
  formattedComments?: IInvoiceCommentDto[];
  companyName: string;
  referenceNumber: string;
  vehicle?: IInvoiceVehicleDto;
  locationAddress: string;
  service?: IInvoiceServiceDto;
}

const EMPTY_DATA = '-';

export class InvoiceDataFormatter {
  constructor(private readonly invoiceData: IInvoiceDataDto) {}

  getInvoiceData(): IFormattedInvoiceData {
    return {
      formattedTotalCost: currencyFormat(this.invoiceData.totalCost),
      totalPartsCost: currencyFormat(this.invoiceData.totalPartsCost),
      totalLaborCost: currencyFormat(this.invoiceData.laborSummary.totalCost),
      travelCost: currencyFormat(this.invoiceData.travelCost),
      fuelCost: currencyFormat(this.invoiceData.fuelCost),
      providerName: this.getFormattedProvider(),
      bookedOn: this.formatBookedOn(),
      formattedJobNumber: parseJobNumber(this.invoiceData.jobNumber),
      dispatcherName: this.invoiceData.dispatcherFullName,
      driverName: this.invoiceData.driverFullName,
      vehicleDescription: this.getVehicleDescription(),
      labor: this.getLaborSummary(),
      afterHours: this.getAfterHoursSummary(),
      formattedParts: this.getFormattedParts(),
      formattedComments: this.getFormattedComments(),
      companyName: this.invoiceData.companyName || EMPTY_DATA,
      referenceNumber: this.invoiceData.referenceNumber || EMPTY_DATA,
      vehicle: this.invoiceData.vehicle,
      locationAddress: this.invoiceData.locationAddress,
      service: this.invoiceData.service,
    };
  }

  private getFormattedProvider(): string {
    const { provider } = this.invoiceData;

    if (!provider) return EMPTY_DATA;
    return provider.company || provider.fullName || EMPTY_DATA;
  }

  private formatBookedOn(): string {
    return formatDateWithFullMonth(new Date(this.invoiceData.bookedOn));
  }

  private getVehicleDescription(): string {
    const { vehicle } = this.invoiceData;

    return vehicle?.year || vehicle?.make
      ? `${vehicle.year || ''} ${vehicle.make || ''}`
      : EMPTY_DATA;
  }

  private getLaborSummary(): IInvoiceDetail {
    const { service } = this.invoiceData;
    const { quantity, price, minHours } = this.invoiceData.laborSummary;

    return {
      name: service?.name || EMPTY_DATA,
      description: service?.hourly ? `Hourly - ${minHours} hour minimum` : 'Flat rate',
      calculations: `${quantity} @ ${currencyFormat(price)}`,
      cost: currencyFormat(quantity * price),
    };
  }

  private getAfterHoursSummary(): IInvoiceDetail | undefined {
    const { quantity, price } = this.invoiceData.afterHoursSummary;
    if (quantity === 0) return;

    return {
      name: 'Afterhour surcharge',
      description: 'For hours worked between 6PM & 6AM',
      calculations: `${quantity} @ ${currencyFormat(price)}`,
      cost: currencyFormat(quantity * price),
    };
  }

  private getFormattedParts(): IInvoiceDetail[] | undefined {
    const { parts } = this.invoiceData;
    if (!parts || parts.length <= 0) return undefined;

    return parts.map(part => {
      const partQuantity = Number(part.quantity);
      const partPrice = priceWithMarkup(Number(part.price), Number(part.markup));
      return {
        name: part.name,
        description: part.description || EMPTY_DATA,
        calculations: `${partQuantity} @ ${currencyFormat(partPrice)}`,
        cost: currencyFormat(partQuantity * partPrice),
      };
    });
  }

  private getFormattedComments(): IInvoiceCommentDto[] | undefined {
    const { comments } = this.invoiceData;
    if (!comments || comments.length <= 0) return undefined;

    return comments.map(comment => ({
      text: comment.text,
      createdate: formatTime(new Date(comment.createdate)),
    }));
  }
}
