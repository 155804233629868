import React from 'react';
import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Box, Typography } from '@material-ui/core';
import { ServiceAreaIcon } from '../../../components/Icons';
import { font } from '../../../styles';
import theme from '../../../styles/theme';

interface OptionDto {
  id: string;
  name: string;
}

interface SelectProps {
  value: string | null;
  options: OptionDto[];
  onChange: (selectedOptionId: string | null) => void;
  emptyValueName: string;
}

export const Select: React.FC<SelectProps> = (props: SelectProps) => {
  const value = props.value || 'empty-value';
  const emptyValueOption: OptionDto = { id: 'empty-value', name: props.emptyValueName };
  const decoratedOptions: OptionDto[] = [emptyValueOption, ...props.options];
  return (
    <MUISelect
      value={value}
      onChange={event => {
        props.onChange(
          event.target.value === 'empty-value' ? null : (event.target.value as string),
        );
      }}
      fullWidth
      variant="outlined"
      renderValue={(value: any) => {
        const selectedOption = decoratedOptions.find(x => x.id === value);
        const optionNameToDisplay = selectedOption ? selectedOption.name : emptyValueOption.name;
        return (
          <Box display="flex" alignItems="center">
            <ServiceAreaIcon
              style={{
                marginRight: '8px',
                width: '14px',
                height: '14px',
                color: theme.palette.primary.main,
              }}
            />
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: font.weight.light,
                lineHeight: '32px',
                fontSize: font.size.default,
              }}
            >
              {optionNameToDisplay}
            </Typography>
          </Box>
        );
      }}
    >
      {decoratedOptions.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </MUISelect>
  );
};
