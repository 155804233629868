import { useState, useEffect } from 'react';
import { IProvider } from '../../Providers/Provider.interface';
import { ProvidersRepository } from '../../Jobs/ProvidersRepository';

export const useProviders = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [providers, setProviders] = useState<IProvider[]>([]);
  const providersRepository = ProvidersRepository.getRepository();

  useEffect(() => {
    const getProviders = async () => {
      try {
        const providers = await providersRepository.getAllProviders();
        setProviders(providers);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    void getProviders();
  }, [providersRepository]);

  return { providers, isLoadingProviders: isLoading };
};

export const useProvider = (providerId?: string | null) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [provider, setProvider] = useState<IProvider>();
  const providersRepository = ProvidersRepository.getRepository();

  useEffect(() => {
    const getProvider = async () => {
      try {
        const provider = providerId ? await providersRepository.getProvider(providerId) : undefined;
        setProvider(provider);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    void getProvider();
  }, [providersRepository, providerId]);

  return { provider, isLoadingProviders: isLoading };
};
