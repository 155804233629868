import { makeStyles } from '@material-ui/core/styles';
import { font } from '../../../styles';
import { defaultBorder } from '../../../styles/commonStyles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  formContainer: {
    display: 'flex',
  },
  calculation: {
    color: palette.secondary.light,
    paddingRight: spacing(3),
  },
  quantity: {
    width: '15%',
    paddingLeft: spacing(1.25),
    paddingRight: spacing(1.25),
  },
  amount: {
    width: '25%',
  },
  description: {
    flexGrow: 1,
  },
  total: {
    marginBottom: '-5%',
  },
  name: {
    flexGrow: 1,
    paddingRight: spacing(3),
  },
  itemWrapper: {
    display: 'flex',
    width: '100%',
  },
  payoutHead: {
    fontWeight: font.weight.semiBold,
    margin: `0 ${spacing(3)}px`,
    padding: `${spacing(1)}px 0`,
    borderBottom: defaultBorder,
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalPayout: {
    color: palette.primary.main,
  },
  avatar: {
    padding: `${spacing(1)}px ${spacing(3)}px`,
    '& p': {
      fontWeight: font.weight.medium,
    },
  },
  payTotal: {
    width: '90px',
    textAlign: 'end',
    fontWeight: font.weight.semiBold,
  },
}));
