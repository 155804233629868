import React from 'react';
import { JobEditForm } from '../JobEditForm';
import { FormFields } from './FormFields';
import { sanitizeWithKeys } from '../../../helpers/formHelpers';

export const PaymentsTab = (props: any) => (
  <JobEditForm {...props} transform={sanitizeBeforeSubmit}>
    <FormFields job={props.job} />
  </JobEditForm>
);

const sanitizeBeforeSubmit = (data: any) => sanitizeWithKeys(data, ['payment']);
