import React from 'react';
import { Box, Typography } from '@material-ui/core';
import theme from '../../../styles/theme';
import { ONE_MINUTE_IN_SECONDS } from '../../../shared/constants/time';
import { ONE_MILE_IN_FEET } from '../../../shared/constants/length';
import { convertSecondsToTime } from '../../../helpers/timeHelpers';

interface TravelFieldProps {
  distanceInFeet: number;
  durationInMinutes: number;
  addBreakLine?: boolean;
}

export const TravelField = ({
  distanceInFeet,
  durationInMinutes,
  addBreakLine = false,
}: TravelFieldProps) => {
  const distanceInMiles = (distanceInFeet / ONE_MILE_IN_FEET).toFixed(2);
  const distanceToDisplay = distanceInMiles + ' miles';
  const durationToDisplay = convertSecondsToTime(durationInMinutes * ONE_MINUTE_IN_SECONDS);

  const breakLineStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
  };
  return (
    <Box>
      <Typography
        variant="body2"
        style={{
          marginLeft: theme.spacing(0),
          marginRight: theme.spacing(0),
          ...(addBreakLine ? breakLineStyles : {}),
        }}
      >
        <span style={{ whiteSpace: 'nowrap' }}>{distanceToDisplay}</span>{' '}
        <span style={{ whiteSpace: 'nowrap' }}>{durationToDisplay}</span>
      </Typography>
    </Box>
  );
};
