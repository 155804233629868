import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme';
import { BORDER_RADIUS } from '../../styles';

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
  },
  card: {
    display: 'flex',
    height: '469px',
    background: theme.palette.primary.dark,
    borderRadius: BORDER_RADIUS,
    marginTop: '6em',
  },
  content: {
    backgroundColor: theme.palette.common.white,
  },
}));
