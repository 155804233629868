import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { IJob, IRequest } from '../../IJob';
import { RequestsRepository } from '../../RequestsRepository';
import { IProviderLocation } from '../../../Map/Map.interface';
import { OfflineStatusDot, OnJobStatusDot, OnlineStatusDot } from '../../../components/Dots';
import { useDatabase } from '../../../DatabaseProvider';
import { COLLECTIONS } from '../../../utils';
import { IProvider } from '../../../Providers/Provider.interface';
import { useProviders } from '../../../utils/hooks/useProviders';
import { useStyles } from './styles';
import { ProviderList } from './ProviderList';
import { NotificationList } from './NotificationList';
import { ProviderAssignment } from './ProviderAssignment';
import { JobStatus } from '../../JobUtils';

export const NotificationsTab = ({
  job,
  onJobProvider,
  onlineProviders,
  offlineProviders,
  isJobLocked,
}: {
  job: IJob;
  onJobProvider: IProvider | undefined;
  onlineProviders: IProviderLocation[];
  offlineProviders: IProviderLocation[];
  isJobLocked: boolean;
}) => {
  const classes = useStyles();
  const { database } = useDatabase();
  const [requests, setRequests] = React.useState<IRequest[]>([]);
  const { providers } = useProviders();
  const requestsRepository = RequestsRepository.getRepository();

  useEffect(() => {
    const getJobRequests = async () => {
      const requests = await requestsRepository.getJobRequests(job.id);
      setRequests(requests);
    };
    void getJobRequests();
  }, [requestsRepository, job.id]);

  useEffect(() => {
    const subscribeToJobRequests = async () => {
      database
        .collection(COLLECTIONS.REQUESTS)
        .where('jobId', '==', job.id)
        .orderBy('createdate', 'asc')
        .onSnapshot(function (querySnapshot) {
          const requests = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          })) as IRequest[];

          setRequests(requests);
        });
    };
    void subscribeToJobRequests();
  }, [database, job.id]);

  return (
    <Box overflow="scroll">
      {!onJobProvider && <ProviderAssignment job={job} providers={providers} />}
      {onJobProvider && (
        <ProviderList
          header={<OnJobStatusDot className={classes.statusDot}> on this job</OnJobStatusDot>}
          providers={[onJobProvider] as IProviderLocation[]}
          job={job}
          requests={requests}
          isJobLocked={isJobLocked}
          showTravelColumn={job.status === JobStatus.EN_ROUTE}
        />
      )}
      <ProviderList
        header={<OnlineStatusDot className={classes.statusDot}> online providers</OnlineStatusDot>}
        providers={onlineProviders}
        job={job}
        requests={requests}
        showTravelColumn={[JobStatus.UNASSIGNED, JobStatus.NOTIFYING].includes(job.status)}
      />
      <ProviderList
        header={
          <OfflineStatusDot className={classes.statusDot}> offline providers</OfflineStatusDot>
        }
        providers={offlineProviders}
        job={job}
        requests={requests}
        showTravelColumn={[JobStatus.UNASSIGNED, JobStatus.NOTIFYING].includes(job.status)}
      />
      <NotificationList job={job} requests={requests} providers={providers} />
    </Box>
  );
};
