import React from 'react';
import { Delete } from '@material-ui/icons';
import classnames from 'classnames';
import { Button, ButtonProps } from '../../../components/Buttons';
import { useButtonStyles } from '../../../styles/commonStyles';
import { useStyles } from './styles';

export const DeleteButton: React.FC<ButtonProps> = props => {
  const buttonClasses = useButtonStyles();
  const classes = useStyles();

  return (
    <Button
      className={classnames(buttonClasses.removeListItem, classes.actionButton, props.className)}
      {...props}
    >
      <Delete className={classes.actionIcon} />
    </Button>
  );
};
