import React, { useMemo } from 'react';
import { Circle, GoogleMap } from '@react-google-maps/api';
import theme from '../styles/theme';
import { useMaps } from '../contexts/MapsContext';
import { ICoordinates } from '../Jobs/IJob';
import { parseToLatLngNumbers } from '../utils';
import { usaCoordinates } from '../Map/mapUtils';

export const MapField = (props: { center: ICoordinates | undefined }) => {
  const { isLoading, loadError, onMapLoad } = useMaps();
  const center = useMemo(
    () => ({
      lat: props.center?.lat ? parseFloat(props.center?.lat) : 0,
      lng: props.center?.long ? parseFloat(props.center?.long) : 0,
    }),
    [props.center],
  );

  const mapContainerStyle = {
    flexGrow: 1,
    height: '780px',
    overflow: 'hidden',
  };

  const bigCircleOptions = {
    strokeColor: theme.palette.primary.main,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: theme.palette.primary.main,
    fillOpacity: 0.2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
  };

  const smallCircleOptions = {
    strokeColor: theme.palette.primary.main,
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: theme.palette.common.white,
    fillOpacity: 1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
  };

  const initialCenter = parseToLatLngNumbers(usaCoordinates);
  const isCenterProvided = center.lat && center.lng;

  if (loadError) return <span>An error occured when loading maps</span>;
  if (isLoading) return <span>Loading maps...</span>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={7}
      center={isCenterProvided ? center : initialCenter}
      onLoad={onMapLoad}
    >
      {isCenterProvided && (
        <Circle center={center} options={bigCircleOptions} radius={100 * 1609.344} />
      )}
      {isCenterProvided && <Circle center={center} options={smallCircleOptions} radius={3000} />}
    </GoogleMap>
  );
};
