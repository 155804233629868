import React from 'react';
import {
  EngineIcon,
  FuelIcon,
  OilIcon,
  TireIcon,
  TrailerIcon,
  BatteryReplacementIcon,
  BeltReplacementIcon,
  BrakeAirLineIcon,
  BrakeChamberIcon,
  DotInspectionIcon,
  GenericIcon,
  JumpstartIcon,
  LockoutIcon,
  OutOfDefIcon,
  RefigerationIcon,
  TireIncludedIcon,
  UnknownIcon,
  TowingIcon,
} from '../components/Icons';

interface IServiceIcons {
  [key: string]: any;
}
export const serviceIcons: IServiceIcons = [
  { id: 0, name: 'Battery replacement', icon: BatteryReplacementIcon },
  { id: 1, name: 'Belt replace', icon: BeltReplacementIcon },
  { id: 2, name: 'Brake air line', icon: BrakeAirLineIcon },
  { id: 3, name: 'Brake chamber', icon: BrakeChamberIcon },
  { id: 4, name: 'Dot inspection', icon: DotInspectionIcon },
  { id: 5, name: 'Engine failure', icon: EngineIcon },
  { id: 6, name: 'Generic', icon: GenericIcon },
  { id: 7, name: 'Jumpstart', icon: JumpstartIcon },
  { id: 8, name: 'Lockout', icon: LockoutIcon },
  { id: 9, name: 'Oil leak', icon: OilIcon },
  { id: 10, name: 'Out of DEF', icon: OutOfDefIcon },
  { id: 11, name: 'Out of fuel', icon: FuelIcon },
  { id: 12, name: 'Refrigeration', icon: RefigerationIcon },
  { id: 13, name: 'Tire replace - has tire', icon: TireIncludedIcon },
  { id: 14, name: 'Tire replace - needs tire', icon: TireIcon },
  { id: 15, name: 'Towing', icon: TowingIcon },
  { id: 16, name: 'Trailer failure', icon: TrailerIcon },
  { id: 17, name: 'Unknown', icon: UnknownIcon },
];

export const defaultServiceIcon = serviceIcons[6];

export const ServiceIcon = ({ service, style }: { service?: { iconId?: any }; style?: any }) => {
  const serviceIconOption =
    typeof service?.iconId !== 'undefined' ? serviceIcons[service.iconId] : defaultServiceIcon;

  return <serviceIconOption.icon style={style} />;
};
