import React from 'react';
import { JobDetailsDescription, JobItemHighlightedText } from '../components/EditableList';
import { percentFormat, currencyFormat } from '../../../helpers/formatHelpers';
import { priceWithMarkup } from '../../../helpers/jobHelpers';
import { useTabStyles } from '../../../styles/commonStyles';
import { useStyles } from './styles';
import { IPartValues } from './';

export const PartItem = ({
  item: { name, description, markup, price, cost, quantity },
}: {
  item: IPartValues;
}) => {
  const classes = useStyles();
  const tabClasses = useTabStyles();

  return (
    <>
      <JobDetailsDescription
        title={name}
        description={description ? description : '-'}
        className={classes.firstColumn}
      />
      <JobDetailsDescription
        title={currencyFormat(priceWithMarkup(price, markup))}
        description={
          <span className={tabClasses.italic}>
            {`${currencyFormat(price)} + ${percentFormat(markup)}`}
          </span>
        }
        className={classes.partDescriptionCol}
      />
      <JobDetailsDescription title={quantity} className={classes.partDescriptionCol} />
      <JobItemHighlightedText>{currencyFormat(cost)}</JobItemHighlightedText>
    </>
  );
};
