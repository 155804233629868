import React from 'react';
import { Add } from '@material-ui/icons';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonProps } from '../../../components/Buttons';
import { useButtonStyles } from '../../../styles/commonStyles';
import { font } from '../../../styles';

interface AddButtonProps extends ButtonProps {}

export const useStyles = makeStyles(({ spacing }) => ({
  addItem: {
    textTransform: 'none',
    fontWeight: font.weight.semiBold,
    width: '100%',
    border: 0,
    '& > span': {
      justifyContent: 'left',
    },
    padding: `${spacing(1.5)}px ${spacing(3)}px`,
  },
  addIcon: {
    marginRight: spacing(0.5),
  },
}));

export const AddButton: React.FC<AddButtonProps> = ({ children, ...props }) => {
  const buttonClasses = useButtonStyles();
  const classes = useStyles();

  return (
    <Button className={classnames(buttonClasses.reverseButton, classes.addItem)} {...props}>
      <Add className={classes.addIcon} />
      {children}
    </Button>
  );
};
