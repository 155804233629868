import React, { useState } from 'react';
import { SimpleForm } from 'react-admin';
import classnames from 'classnames';
import { useFormState } from 'react-final-form';
import { Box, makeStyles } from '@material-ui/core';
import { IJob } from '../../IJob';
import { Modal } from '../../../components/Modal';
import { Button } from '../../../components/Buttons';
import { SelectInput } from '../../../components/Inputs/SelectInput';
import { IProvider } from '../../../Providers/Provider.interface';
import { useButtonStyles } from '../../../styles/commonStyles';
import { useDatabase } from '../../../DatabaseProvider';
import { getProviderFullName } from '../../../helpers/providerHelpers';
import { LocationsRepository } from '../../LocationsRepository';
import { IFetchTravelInfoDTO } from '../../../DatabaseProvider/geolocation/models/IFetchTravelInfo.dto';

export const ProviderAssignment = ({ job, providers }: { job: IJob; providers: IProvider[] }) => {
  const classes = useStyles();

  return (
    <SimpleForm toolbar={null} className={classes.form}>
      <Box className={classes.wrapper}>
        <SelectInput
          label=""
          source="providerId"
          choices={providers}
          optionValue="uid"
          optionText={(provider: IProvider) => getProviderFullName(provider)}
          validation={false}
          placeholder="Select a provider"
          className={classes.select}
        />
        <AssignButton job={job} providers={providers} />
      </Box>
    </SimpleForm>
  );
};

interface AssignButtonProps {
  job: IJob;
  providers: IProvider[];
}

interface IAssignProviderDTO {
  jobId: string;
  providerId: string;
  travel: { distanceInFeet: number; durationInMinutes: number } | null;
}

const AssignButton = ({ job, providers }: AssignButtonProps) => {
  const buttonClasses = useButtonStyles();
  const classes = useStyles();

  const { functions, geolocation } = useDatabase();
  const { values } = useFormState();
  const [isProcessing, setProcessing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const locationsRepository = LocationsRepository.getRepository();

  const provider = providers.find(p => p.uid === values.providerId);

  const assignProvider = async () => {
    if (!provider) return;
    setProcessing(true);
    const fn = functions.httpsCallable('assignProvider');

    const lastProviderLocation = await locationsRepository.getLastProviderLocation(provider.uid);
    let travelInfo: IFetchTravelInfoDTO | null = null;

    if (lastProviderLocation) {
      travelInfo = await geolocation.fetchTravelInfo(
        {
          lat: parseFloat(lastProviderLocation.latitude),
          long: parseFloat(lastProviderLocation.longitude),
        },
        { lat: +job.location.lat, long: +job.location.long },
      );
    }
    try {
      const dto: IAssignProviderDTO = {
        jobId: job.id,
        providerId: values.providerId,
        travel: travelInfo,
      };
      await fn(dto);
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        size="medium"
        className={classnames(buttonClasses.defaultButton, classes.button)}
        onClick={() => setModalOpen(true)}
        disabled={isProcessing || !values.providerId || !job.service}
        isProcessing={isProcessing}
      >
        assign provider
      </Button>
      <Modal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleConfirm={assignProvider}
        title="Assigning provider"
        isProcessing={isProcessing}
      >
        You are going to assign provider {getProviderFullName(provider)} to this job. This action
        will result in creating an accepted job request for the provider and will change the job to
        a Manual status
      </Modal>
    </>
  );
};

export const useStyles = makeStyles(({ spacing }) => ({
  select: {
    flexGrow: 1,
    marginRight: spacing(2),
    paddingBottom: 0,
  },
  wrapper: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
    padding: `${spacing(1)}px ${spacing(3)}px`,
  },
  form: {
    '& >:first-child': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  button: {
    marginTop: spacing(1),
    marginBottom: spacing(0.5),
  },
}));
