import React from 'react';
import { Datagrid, FunctionField, List, ReferenceManyField, TextField } from 'react-admin';
import { EmptyState } from '../../components/EmptyState';
import { ListWrapper } from '../../components/ListWrapper';
import { ListHeader } from '../../components/ListHeader';
import { currencyFormat } from '../../helpers/formatHelpers';
import { convertNumToTime } from '../../helpers/timeHelpers';
import { ICompany } from '../models/ICompany';
import { JobStatus } from '../../Jobs/JobUtils';
import { RowsPerPage } from '../../shared/common/RowsPerPage';

const displayCellValue = (rateOverride: boolean, value: string): string | null => {
  return rateOverride ? value : null;
};

export const CompanyListPage = (props: any) => {
  return (
    <List actions={null} empty={<EmptyState />} {...props} perPage={RowsPerPage.TWENTY_FIVE}>
      <>
        <ListHeader {...props} />
        <ListWrapper>
          <Datagrid rowClick="edit">
            <TextField source="name" />
            <FunctionField
              label="Hourly"
              render={(company: ICompany) =>
                displayCellValue(company.rateOverride, currencyFormat(company.rates.base))
              }
            />
            <FunctionField
              label="Afterhour"
              render={({ rates, rateOverride }: ICompany) =>
                displayCellValue(rateOverride, currencyFormat(rates.afterHour))
              }
            />
            <FunctionField
              label="Callout"
              render={({ rates, rateOverride }: ICompany) =>
                displayCellValue(rateOverride, currencyFormat(rates.callout))
              }
            />
            <FunctionField
              label="Fuel Surcharge"
              render={({ rates, rateOverride }: ICompany) =>
                displayCellValue(rateOverride, currencyFormat(rates.fuel))
              }
            />
            <FunctionField
              label="Min Hours"
              render={(company: ICompany) =>
                displayCellValue(company.rateOverride, convertNumToTime(company.minHours))
              }
            />
            <ReferenceManyField
              label="Jobs"
              reference="jobs"
              target="company.id"
              filter={{ status: JobStatus.COMPLETED }}
              sortable={false}
            >
              <JobsTotal />
            </ReferenceManyField>
          </Datagrid>
        </ListWrapper>
      </>
    </List>
  );
};
const JobsTotal = (props: { total?: number }) => {
  return <span>{props.total}</span>;
};
