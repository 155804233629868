import { useState, useEffect, useCallback } from 'react';
import { useDatabase } from '../../DatabaseProvider';
import { IInvoiceDataDto } from '../dtos/IInvoiceData.dto';
import { useInterval } from '../../utils/hooks/useInterval';
import { FIVE_MINUTES_IN_MILLIS } from '../../helpers/timeHelpers';

export interface IGetInvoiceDTO {
  jobId: string;
}

export const useInvoiceData = (jobId: string, subscribed?: boolean) => {
  const database = useDatabase();

  const [isLoading, setIsLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState<IInvoiceDataDto>();

  const getInvoiceData = useCallback(async () => {
    try {
      const fn = database.functions.httpsCallable('getInvoice');
      const dto: IGetInvoiceDTO = {
        jobId: jobId,
      };
      const invoice = await fn(dto);

      setInvoiceData(invoice.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [database.functions, jobId]);

  useEffect(() => {
    void getInvoiceData();
  }, [getInvoiceData]);

  useEffect(() => {
    let firstRun = true;
    const unsubscribe = database.database
      .collection('jobs')
      .doc(jobId)
      .onSnapshot({ includeMetadataChanges: true }, async doc => {
        if (doc.metadata.hasPendingWrites || firstRun) {
          firstRun = false;
          return;
        }
        await getInvoiceData();
      });
    return () => {
      unsubscribe();
    };
  }, [database.database, getInvoiceData, jobId]);

  useInterval(() => {
    if (subscribed) void getInvoiceData();
  }, FIVE_MINUTES_IN_MILLIS);

  return { invoiceData, isLoading };
};
