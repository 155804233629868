import React from 'react';
import classnames from 'classnames';
import { Box } from '@material-ui/core';
import { TextInput } from '../../../components/Inputs/TextInput';
import { CheckboxInput } from '../../../components/Inputs/CheckboxInput';
import { InlineFieldsWrapper } from '../../../components/InlineFieldsWrapper';
import { PhoneNumberInput } from '../../../components/Inputs/PhoneNumberInput';
import { IContacts } from '../../../interfaces';
import { ContactLabel } from './index';
import { useStyles } from './styles';

export const DriverSection = ({ values }: { values: IContacts }) => {
  const isDispatcherEmpty = !(values.dispatcher?.firstName || values.dispatcher?.phoneNumber);
  const classes = useStyles();

  return (
    <Box className={classnames(classes.section, classes.driver)}>
      <ContactLabel text="Driver" />
      <InlineFieldsWrapper>
        <TextInput
          source="driver.firstName"
          label="First name"
          value={values.driver?.firstName}
          validation={isDispatcherEmpty}
        />
        <TextInput source="driver.lastName" label="Last name" validation={false} />
      </InlineFieldsWrapper>
      <InlineFieldsWrapper>
        <PhoneNumberInput
          source="driver.phoneNumber"
          value={values.driver?.phoneNumber}
          isRequired={isDispatcherEmpty}
        />
        <TextInput source="driver.email" label="E-mail address" validation={false} />
      </InlineFieldsWrapper>
      <CheckboxInput
        source="driver.noTextMessages"
        label="Driver's device doesn't accept text messages."
        validation={false}
      />
    </Box>
  );
};
