import { makeStyles } from '@material-ui/core/styles';
import { colors, font } from '../../../styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  wrapperWithActions: {
    display: 'flex',
    alignItems: 'center',
    padding: `${spacing(1)}px ${spacing(3)}px`,
    position: 'relative',
    '&:hover': {
      background: colors.catskillWhite,
      '& $actions': {
        display: 'flex',
      },
    },
  },
  title: {
    fontWeight: font.weight.bold,
  },
  description: {
    color: palette.text.secondary,
  },
  highlighted: {
    textAlign: 'right',
    color: palette.primary.main,
    fontWeight: font.weight.bold,
  },
  textWrapper: {
    flexGrow: 1,
    marginRight: spacing(1.5),
  },
  actions: {
    display: 'none',
    width: '20%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    padding: 0,
    right: spacing(3),
    top: 0,
    bottom: 0,
    background: 'linear-gradient(270deg, #F2F4F8 55.85%, rgba(242, 244, 248, 0) 100%)',
  },
  actionButton: {
    borderRadius: '50%',
    border: 0,
    width: '24px',
    minWidth: '24px',
    height: '24px',
    background: 'transparent',
    '&:hover': {
      borderRadius: '50%',
    },
  },
  actionIcon: {
    width: 16,
    height: 16,
  },
  formWrapper: {
    padding: `${spacing(2)}px ${spacing(3)}px`,
    background: palette.common.white,
    border: `2px solid ${palette.primary.main}`,
  },
  dolarIcon: {
    color: palette.primary.main,
  },
}));
