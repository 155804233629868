import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as _ from 'lodash';
import { EditableList } from '../components/EditableList';
import { JobsRepository } from '../../JobsRepository';
import { IJob, IPart } from '../../IJob';
import { useTabStyles } from '../../../styles/commonStyles';
import { PartItem } from './PartItem';
import { PartsForm } from './PartForm';

export interface PartsTabsProps {
  job: IJob;
  isJobLocked: boolean;
}

export interface ISerializedPartValues {
  name: string;
  description: string;
  price: string;
  markup: string;
  quantity: string;
  cost: string;
}

export interface IPartValues {
  name: string;
  description: string;
  price: number;
  markup: number;
  quantity: number;
  cost: number;
}

export const PartsTab: React.FC<PartsTabsProps> = ({ job, isJobLocked }) => {
  const tabClasses = useTabStyles();

  if (!job.parts)
    return (
      <Box p={3}>
        <Typography>No parts</Typography>
      </Box>
    );

  const parts = _.sortBy(job.parts, 'createdate');
  const jobId = job.id;
  const jobsRepository = JobsRepository.getRepository();

  const createPart = async (formData: IPartValues) => {
    const editedParts = [
      ...parts,
      {
        ...serializePartData(formData),
        createdate: new Date().getTime(),
      },
    ];

    await jobsRepository.updateJob(jobId, { parts: editedParts });
  };

  const updatePart = async (formData: IPartValues, id: number) => {
    const editedParts = parts.map((part, index) =>
      index === id
        ? {
            ...part,
            ...serializePartData(formData),
          }
        : part,
    );
    await jobsRepository.updateJob(jobId, { parts: editedParts });
  };

  const deletePart = async (deletedPeriodId: number) => {
    const editedParts = parts.filter((part, index) => index !== deletedPeriodId);
    await jobsRepository.updateJob(jobId, { parts: editedParts });
  };

  return (
    <Box className={tabClasses.listWrapper}>
      <EditableList
        data={parts}
        createForm={(props: { onCancelClick: () => void; id: number }) => (
          <PartsForm handleSubmit={createPart} {...props} />
        )}
        editForm={({ data, ...props }: { onCancelClick: () => void; data: IPart; id: number }) => (
          <PartsForm handleSubmit={updatePart} data={deserializePartData(data)} {...props} />
        )}
        onDelete={deletePart}
        deleteModal={(item: IPart) => ({
          title: 'Delete part?',
          confirmText: 'Delete',
          content: (
            <Typography>
              Remove part for <span className={tabClasses.boldText}>{item.name}</span> ?
            </Typography>
          ),
        })}
        row={({ item }: { item: IPart }) => <PartItem item={deserializePartData(item)} />}
        addItemButtonLabel="Add part"
        isReadOnly={isJobLocked}
      />
    </Box>
  );
};

const serializePartData = ({
  price,
  markup,
  quantity,
  cost,
  ...data
}: IPartValues): ISerializedPartValues => {
  return {
    ...data,
    price: price.toFixed(2),
    markup: markup.toString(),
    quantity: quantity.toString(),
    cost: cost.toFixed(2),
  };
};

const deserializePartData = ({ price, markup, quantity, cost, ...data }: IPart): IPartValues => {
  return {
    ...data,
    price: parseFloat(price),
    markup: parseInt(markup),
    quantity: parseInt(quantity),
    cost: parseFloat(cost),
  };
};
