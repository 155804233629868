import { makeStyles } from '@material-ui/core/styles';
import { extraBoldBorder, separatorBorder } from '../styles/commonStyles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: spacing(6),
    paddingBottom: spacing(3),
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    borderBottom: separatorBorder,
    background: palette.common.white,
  },
  pageContent: {
    display: 'flex',
    height: '100%',
    overflow: 'scroll',

    '& div.gm-style-iw': {
      border: extraBoldBorder,
    },
    '& button.gm-ui-hover-effect': {
      display: 'none !important',
    },
    '& .gm-style-iw-t::after': {
      border: extraBoldBorder,
      background: palette.common.white,
      borderTop: 0,
      borderRight: 0,
    },
  },
  select: {
    minWidth: '266px',
    marginRight: spacing(3),
    '& svg': {
      color: palette.primary.main,
    },
  },
}));
