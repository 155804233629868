import React from 'react';
import { Box } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { SetupCard } from './SetupCard';
import { LaborCard } from './LaborCard';
import { CustomParametersCard } from './CustomParametersCard';

export const FormFields = () => {
  const { values } = useFormState();

  return (
    <Box display="flex" px={3} pt={3}>
      <Box display="flex" flexDirection="column" flexBasis="100%">
        <SetupCard values={values} />
        <LaborCard values={values} />
      </Box>
      <CustomParametersCard values={values} />
    </Box>
  );
};
