import React from 'react';
import { Box, Typography } from '@material-ui/core';
import theme from '../styles/theme';
import { font } from '../styles';

interface IFormParameterBox {
  title: string;
  pt?: number;
  children: any;
  style?: any;
}

export const FormParameterBox = ({ title, pt = 3, children }: IFormParameterBox) => (
  <Box display="flex" flexDirection="column" pt={pt} px={3}>
    <Typography
      variant="h5"
      style={{ fontWeight: font.weight.medium, marginBottom: theme.spacing(2) }}
    >
      {title}
    </Typography>
    {children}
  </Box>
);
