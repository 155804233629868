import React, { useMemo } from 'react';
import { Create, SimpleForm, useRedirect } from 'react-admin';
import { Box } from '@material-ui/core';
import { FormFields } from './FormFields';
import { FormHeader } from './FormHeader';
import { IProvider } from './Provider.interface';
import { BottomToolbar } from '../components/BottomToolbar';
import { useFormStyles } from '../styles/commonStyles';
import { parsePhoneNumber } from '../helpers/formatHelpers';
import { useProviders } from '../utils/hooks/useProviders';
import { validatePhoneNumberUniqueness } from '../helpers/validationHelpers';

export const ProviderCreate: React.FC<{}> = props => {
  const classes = useFormStyles();
  const redirect = useRedirect();
  const { providers } = useProviders();

  const providersPhoneNumbers = useMemo(() => providers.map(provider => provider.phoneNumber), [
    providers,
  ]);

  const transformBeforeSubmit = (data: IProvider) => ({
    ...data,
    phoneNumber: data.phoneNumber && parsePhoneNumber(data.phoneNumber),
  });

  return (
    <Create {...props} className={classes.root} transform={transformBeforeSubmit}>
      <SimpleForm
        validate={(values: IProvider) =>
          validatePhoneNumberUniqueness(providersPhoneNumbers, values.phoneNumber)
        }
        toolbar={<BottomToolbar customRedirect="list" {...props} />}
        className={classes.form}
      >
        <Box display="flex" flexDirection="column">
          <FormHeader
            title="New provider"
            buttonText="Back to providers"
            onClick={() => redirect('/providers')}
          />
          <FormFields />
        </Box>
      </SimpleForm>
    </Create>
  );
};
