import React from 'react';
import { Typography } from '@material-ui/core';
import { currencyFormat } from '../../../helpers/formatHelpers';
import { IPay } from '../../IJob';
import { useStyles } from './styles';

export const PayItem = ({ item: { description, quantity, amount } }: { item: IPay }) => {
  const classes = useStyles();

  return (
    <Typography variant="body2" className={classes.itemWrapper}>
      <span className={classes.name}>{description}</span>
      <span className={classes.calculation}>{`${quantity} @ ${currencyFormat(amount)}`}</span>
      <span className={classes.payTotal}>{currencyFormat(quantity * amount)}</span>
    </Typography>
  );
};
