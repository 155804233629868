import moment from 'moment';
import { IDateRange } from '../components/DateRangePicker/DateRangePicker';

export const allTimeRange: IDateRange = {
  startDate: new Date(2018, 9, 8),
  endDate: new Date(),
  label: 'All time',
};

const lastWeek: IDateRange = {
  startDate: moment().subtract(1, 'week').startOf('isoWeek').toDate(),
  endDate: moment().subtract(1, 'week').endOf('isoWeek').toDate(),
  label: 'Last week',
};

const lastMonth: IDateRange = {
  startDate: moment().subtract(1, 'month').startOf('month').toDate(),
  endDate: moment().subtract(1, 'month').endOf('month').toDate(),
  label: 'Last month',
};

const today: IDateRange = {
  startDate: moment().startOf('day').toDate(),
  endDate: moment().endOf('day').toDate(),
  label: 'Today',
};

const thisWeek: IDateRange = {
  startDate: moment().startOf('isoWeek').toDate(),
  endDate: moment().endOf('isoWeek').toDate(),
  label: 'This week',
};

const thisMonth: IDateRange = {
  startDate: moment().startOf('month').toDate(),
  endDate: moment().endOf('month').toDate(),
  label: 'This month',
};

export const defaultDateRanges = [allTimeRange, lastMonth, lastWeek, today, thisWeek, thisMonth];
