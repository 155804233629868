import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import { BottomToolbar } from '../../../components/BottomToolbar';
import { FormFields } from './FormFields';
import { useFormStyles } from '../../../styles/commonStyles';

export const PayTab = (props: any) => {
  const classes = useFormStyles();

  return (
    <Edit {...props} className={classes.root}>
      <SimpleForm toolbar={<BottomToolbar customRedirect="list" />} className={classes.form}>
        <FormFields />
      </SimpleForm>
    </Edit>
  );
};
