import React from 'react';
import { Box } from '@material-ui/core';
import { TextInput } from '../../components/Inputs/TextInput';
import { InlineFieldsWrapper } from '../../components/InlineFieldsWrapper';

export const VehicleSection = () => (
  <Box display="flex" flexDirection="column" pt={3} px={3}>
    <InlineFieldsWrapper flexBasis={30}>
      <TextInput source="vehicle.year" label="Year" validation={false} />
      <TextInput source="vehicle.make" label="Make" validation={false} />
      <TextInput source="vehicle.model" label="Model" validation={false} />
    </InlineFieldsWrapper>
    <InlineFieldsWrapper>
      <TextInput source="vehicle.unit" label="Unit #" validation={false} />
      <TextInput source="vehicle.vin" label="VIN / Serial #" validation={false} />
      <TextInput source="vehicle.mileage" label="Mileage" validation={false} />
      <TextInput source="vehicle.usdot" label="USDOT #" validation={false} />
    </InlineFieldsWrapper>
  </Box>
);
