import { makeStyles } from '@material-ui/core/styles';
import { colors, font } from '../../styles';
import theme from '../../styles/theme';

export const useMenuStyles = makeStyles(() => ({
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  logoutContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: 1,
    marginBottom: '20px',
  },
  logout: {
    color: theme.palette.common.white,
    fontSize: font.size.semiLarge,
    fontWeight: font.weight.medium,
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    padding: '10px',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
}));

export const useItemsStyles = makeStyles(() => ({
  root: {
    color: colors.grey,
    padding: '10px',
    fontSize: font.size.semiLarge,
    lineHeight: '24px',
    fontWeight: font.weight.light,
    marginLeft: theme.spacing(2),
    '&:hover': {
      color: theme.palette.common.white,
      background: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '4px 0 0 4',
    },
    '&:hover svg': {
      color: theme.palette.common.white,
    },
  },
  active: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px 0 0 4px',
    fontWeight: font.weight.medium,
    marginLeft: theme.spacing(2),
    '& span > span': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
    '& svg': {
      color: theme.palette.common.white,
    },
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}));

export const useIconStyles = makeStyles(() => ({
  root: {
    color: colors.grey,
  },
}));
