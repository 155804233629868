import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import classnames from 'classnames';
import { useStyles } from './styles';
import TextField from '@material-ui/core/TextField';
import { useDatabase } from '../../../DatabaseProvider';
import { InputWithLabel } from '../../../components/InputWithLabel';
import { useField } from 'react-final-form';
import { Modal } from '../../../components/Modal';
import theme from '../../../styles/theme';
import { ICompany } from '../../../Companies/models/ICompany';
import { Autocomplete, AutocompleteOptionDto } from '../../../components/Autocomplete/Autocomplete';
import { IJobCompany } from '../../IJob';

export const CompanySection = () => {
  const classes = useStyles();

  //autocomplete props
  const { database } = useDatabase();
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const field = useField<IJobCompany | null | undefined>('company');
  const jobCompany = field.input.value;
  let selectedCompany: AutocompleteOptionDto | null = jobCompany
    ? { id: jobCompany.id, name: jobCompany.name }
    : null;
  //autocomplete props

  //modal props
  const [isModalOpen, _toggleModalOpen] = React.useState(false);
  const [isModalProcessing, _toggleIsModalProcessing] = React.useState(false);
  const [companyNameToCreate, setCompanyNameToCreate] = React.useState<string>('');
  const openModal = () => _toggleModalOpen(true);
  const closeModal = () => _toggleModalOpen(false);
  const startModalProcessing = () => _toggleIsModalProcessing(true);
  const stopModalProcessing = () => _toggleIsModalProcessing(false);
  //modal props

  const fetchCompanies = useCallback(async () => {
    const result = (await database.collection('companies').get()).docs.map<ICompany>(x => {
      return { ...x.data(), id: x.id } as ICompany;
    });
    setCompanies(result);
  }, [database]);

  const handleSubmit = async () => {
    startModalProcessing();

    const companyWithGivenNameAlreadyExists = !!(
      await database.collection('companies').where('name', '==', companyNameToCreate).get()
    ).size;

    if (companyWithGivenNameAlreadyExists) {
      stopModalProcessing();
      return;
    }

    const companyToCreate: ICompany = {
      name: companyNameToCreate,
      minHours: 0,
      rateOverride: false,
      rates: { base: 0, callout: 0, afterHour: 0, fuel: 0 },
      createdate: new Date(),
      lastupdate: new Date(),
    } as ICompany;

    const companyCreationResult = await database.collection('companies').add(companyToCreate);
    const createdCompany: ICompany = {
      ...(await companyCreationResult.get()).data(),
      id: companyCreationResult.id,
    } as ICompany;
    setCompanies(companies => [createdCompany, ...companies]);

    const jobCompany: IJobCompany = {
      id: companyCreationResult.id,
      name: createdCompany.name,
      override: createdCompany.rateOverride,
      minHours: createdCompany.minHours,
      base: createdCompany.rates.base,
      callout: createdCompany.rates.callout,
      afterHour: createdCompany.rates.afterHour,
      fuel: createdCompany.rates.fuel,
    };

    field.input.onChange({ target: { value: jobCompany } });

    stopModalProcessing();
    closeModal();
  };

  const handleChangeFieldValue = (optionId: string | null) => {
    if (!optionId) {
      field.input.onChange({ target: { value: null } });
      return;
    }

    const company = companies.find(company => company.id === optionId);
    if (!company) {
      return;
    }

    const jobCompany: IJobCompany = {
      id: optionId,
      name: company.name,
      override: company.rateOverride,
      minHours: company.minHours,
      base: company.rates.base,
      callout: company.rates.callout,
      afterHour: company.rates.afterHour,
      fuel: company.rates.fuel,
    };

    field.input.onChange({ target: { value: jobCompany } });
  };

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const options = companies.map<AutocompleteOptionDto>(x => ({ id: x.id, name: x.name }));

  return (
    <Box className={classnames(classes.section, classes.company)}>
      <InputWithLabel label="Company" validation={false}>
        <Autocomplete
          value={selectedCompany}
          options={options}
          onChange={value => handleChangeFieldValue(value)}
          onIntensionToAddNewOption={optionNameToCreate => {
            setCompanyNameToCreate(optionNameToCreate);
            openModal();
          }}
          onBlur={field.input.onBlur}
          onFocus={field.input.onFocus}
        />
        <Modal
          title="New Company"
          open={isModalOpen}
          disabled={!companyNameToCreate}
          isProcessing={isModalProcessing}
          handleClose={closeModal}
          handleConfirm={handleSubmit}
        >
          <InputWithLabel label="Company Name" value={companyNameToCreate}>
            <TextField
              value={companyNameToCreate}
              onChange={props => setCompanyNameToCreate(props.target.value)}
              variant="filled"
              InputProps={{ margin: 'dense' }}
              style={{ marginTop: theme.spacing(1) }}
              error={!companyNameToCreate}
            />
          </InputWithLabel>
        </Modal>
      </InputWithLabel>
    </Box>
  );
};
