import { Box, Typography } from '@material-ui/core';
import { BooleanInput } from 'react-admin';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { defaultBorder } from '../styles/commonStyles';

interface ISwitchInput {
  isSwitchedOn: boolean | undefined;
  label?: string;
  labelOn: any;
  labelOff: any;
  source: string;
  className?: string;
}

export const SwitchInput = ({
  isSwitchedOn,
  label,
  labelOn,
  labelOff,
  source,
  className,
}: ISwitchInput) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      borderTop={defaultBorder}
      alignItems="center"
      className={className}
    >
      {label && (
        <Typography variant="body2" component="label">
          {label}
        </Typography>
      )}
      <BooleanInput
        source={source}
        label={<Typography variant="body2">{isSwitchedOn ? labelOn : labelOff}</Typography>}
        style={{ display: 'flex' }}
        className={classes.switch}
        defaultValue={false}
      />
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  switch: {
    '& span.MuiFormControlLabel-label': {
      padding: theme.spacing(2),
    },
    '& .MuiTypography-body2': {
      minWidth: 24,
    },
  },
}));
