import React from 'react';
import {
  Autocomplete as MaterialUiAutocomplete,
  createFilterOptions,
  FilterOptionsState,
} from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import theme from '../../styles/theme';
import { useStyles } from './styles';

export interface AutocompleteOptionDto {
  id: string;
  name: string;
  inputValue?: string;
}

interface AutocompleteProps {
  value: AutocompleteOptionDto | null;
  options: AutocompleteOptionDto[];
  error?: boolean;
  placeholder?: string;

  onChange: (value: string | null) => void;
  onIntensionToAddNewOption: (optionNameToCreate: string) => void;

  onBlur?: (event?: React.FocusEvent<HTMLElement>) => void;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
}

export const Autocomplete = (props: AutocompleteProps) => {
  const classes = useStyles();
  const filter = createFilterOptions<AutocompleteOptionDto>();

  const handleChange = (
    e: React.ChangeEvent<{}>,
    company: AutocompleteOptionDto | string | null,
  ) => {
    e.preventDefault();
    if (!company) {
      props.onChange(null);
      return;
    }

    if (typeof company === 'string') {
      props.onIntensionToAddNewOption(company);
      return;
    }

    if (company.inputValue) {
      props.onIntensionToAddNewOption(company.inputValue);
      return;
    }

    props.onChange(company.id);
  };

  const handleFilterOptions = (
    options: AutocompleteOptionDto[],
    params: FilterOptionsState<AutocompleteOptionDto>,
  ) => {
    const filtered = filter(options, params);
    const searchText = params.inputValue;
    const isSearchTextEmpty = searchText === '';

    const optionWithGivenName = filtered.find(x => x.name === searchText);
    if (!isSearchTextEmpty && !optionWithGivenName) {
      filtered.unshift({ id: '', name: `Add "${searchText}"`, inputValue: searchText });
    }

    return filtered;
  };

  const handleGetOptionLabel = (option: AutocompleteOptionDto | string) => {
    if (typeof option === 'string') return option;
    return option.inputValue ? option.inputValue : option.name;
  };

  return (
    <MaterialUiAutocomplete
      value={props.value || null}
      options={props.options}
      onChange={handleChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      filterOptions={handleFilterOptions}
      getOptionLabel={handleGetOptionLabel}
      selectOnFocus
      renderOption={option => option.name}
      classes={{ inputRoot: classes.inputRoot }}
      style={{ marginTop: theme.spacing(1) }}
      renderInput={params => (
        <TextField
          {...params}
          variant="filled"
          error={props.error}
          placeholder={props.placeholder}
        />
      )}
    />
  );
};
