import React from 'react';
import { Field, withTypes } from 'react-final-form';
import { Box } from '@material-ui/core';
import { ItemForm } from '../components/ItemForm';
import { JobItemHighlightedText } from '../components/EditableList';
import { greaterThanOrEqualZero } from '../../../helpers/validationHelpers';
import { currencyFormat } from '../../../helpers/formatHelpers';
import { TextInput } from '../../../components/Inputs/TextInput';
import { CurrencyInput } from '../../../components/Inputs/CurrencyInput';
import { NumberInput } from '../../../components/Inputs/NumberInput';
import { IPay } from '../../IJob';
import { useStyles } from './styles';

const { Form } = withTypes<IPay>();

const DEFAULT_COUNT = 1;

const NEW_PAY = {
  description: '',
  quantity: DEFAULT_COUNT,
  modifiable: true,
};

export const PayForm = ({
  data,
  handleSubmit,
  onCancelClick,
  id,
}: {
  data?: IPay;
  handleSubmit: (formData: IPay, id: number) => Promise<void>;
  onCancelClick: () => void;
  id: number;
}) => {
  const classes = useStyles();
  const initialValues = data || NEW_PAY;
  const onSubmit = (values: IPay) => {
    handleSubmit(values, id);
    onCancelClick();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, values, valid, pristine }) => (
        <form onSubmit={handleSubmit}>
          <ItemForm onCancelClick={onCancelClick} isValid={!pristine && valid}>
            <Box className={classes.formContainer}>
              <Field name="description">
                {({ input, ...rest }) => (
                  <TextInput
                    {...input}
                    {...rest}
                    placeholder="Description"
                    className={classes.description}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      input.onChange(e.target.value)
                    }
                    value={values.description}
                    disabled={!initialValues.modifiable}
                  />
                )}
              </Field>
              <Field name="quantity" validate={greaterThanOrEqualZero}>
                {({ input, ...rest }) => (
                  <NumberInput
                    {...input}
                    {...rest}
                    placeholder="Qty"
                    type="number"
                    inputProps={{ min: '1', step: '1' }}
                    className={classes.quantity}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      input.onChange(e.target.value ? parseInt(e.target.value) : undefined)
                    }
                    value={values.quantity}
                    disabled={!initialValues.modifiable}
                  />
                )}
              </Field>
              <Field name="amount">
                {({ input, meta, ...rest }) => (
                  <CurrencyInput
                    {...input}
                    {...rest}
                    className={classes.amount}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      input.onChange(e.target.value ? parseFloat(e.target.value) : undefined)
                    }
                    value={values.amount}
                    allowNegative
                  />
                )}
              </Field>
            </Box>
            <JobItemHighlightedText className={classes.total}>
              {currencyFormat((values.amount || 0) * (values.quantity || 0))}
            </JobItemHighlightedText>
          </ItemForm>
        </form>
      )}
    />
  );
};
