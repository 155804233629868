import React from 'react';
import { InfoWindow } from '@react-google-maps/api';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { FuelIcon } from '../components/Icons';
import JobStatusChip from '../components/Chips/JobStatusChip';
import { colors } from '../styles';
import theme from '../styles/theme';
import { statuses } from '../Jobs/JobUtils';
import { serviceIcons } from '../Services/ServiceUtils';
import { IJob } from '../Jobs/IJob';

export const JobMarkerDetails: React.FC<{
  job: any;
  onClick: () => void;
  selectedJob: any;
}> = ({ job, onClick, selectedJob }) => {
  const location = new google.maps.LatLng(job.location.lat, job.location.long);

  return (
    <InfoWindow position={location}>
      <Box display="flex" maxHeight="30px" overflow="hidden" alignItems="center" onClick={onClick}>
        {serviceIcons[job.service?.name] || <FuelIcon />}
        {selectedJob === job ? <JobDetails job={job} /> : <StatusDot job={job} />}
      </Box>
    </InfoWindow>
  );
};

const JobDetails = ({ job }: { job: IJob }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" ml={1.5}>
      <JobStatusChip status={job.status} />
      <Box ml={1.5} display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="subtitle2" style={{ lineHeight: 'inherit' }}>
          {job.service?.name}
        </Typography>
        <Typography variant="caption" className={classes.jobAddress}>
          {job.location.address}
        </Typography>
      </Box>
    </Box>
  );
};

const StatusDot = ({ job }: { job: IJob }) => {
  const classes = useStyles();
  const statusOption = statuses.find(s => s.id === job.status);

  return <span className={classes.dot} style={{ background: statusOption?.color }} />;
};

const useStyles = makeStyles({
  dot: {
    height: '12px',
    width: '12px',
    borderRadius: '100%',
    border: `3px solid ${colors.catskillWhite}`,
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    alignSelf: 'center',
  },
  jobAddress: {
    overflow: 'hidden',
    height: '14px',
    maxWidth: '200px',
  },
});
