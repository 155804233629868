import React from 'react';
import { Box } from '@material-ui/core';
import { RepairIcon } from '../../components/Icons';
import { SwitchInput } from '../../components/SwitchInput';
import { TextInput } from '../../components/Inputs/TextInput';
import { InfoBox } from '../../components/InfoBox';
import { SelectInput } from '../../components/Inputs/SelectInput';
import { defaultServiceIcon, ServiceIcon, serviceIcons } from '../ServiceUtils';
import { useStyles } from './styles';

interface ISetupCard {
  values: {
    name?: string;
    description?: string;
    active?: boolean;
    iconId?: any;
  };
}

export const SetupCard = ({ values }: ISetupCard) => {
  const classes = useStyles();

  return (
    <InfoBox icon={<RepairIcon />} title="Service" style={{ width: 'auto' }}>
      <Box px={2} display="flex" flexDirection="column">
        <TextInput
          label="Name"
          value={values.name}
          source="name"
          placeholder="e.g. Tire replace - needs tire"
        />
        <TextInput
          label="Description"
          value={values.description}
          source="description"
          placeholder="e.g. Tire replacement & tire is needed"
        />
        <Box display="flex">
          <ServiceIcon service={values} style={{ padding: '10px' }} />
          <SelectInput
            label="Icon"
            source="iconId"
            value={values.iconId}
            choices={serviceIcons}
            defaultValue={defaultServiceIcon.id}
            className={classes.iconSelect}
          />
        </Box>
        <SwitchInput
          isSwitchedOn={values.active}
          label="Service status"
          source="active"
          labelOn="Active"
          labelOff="Inactive"
        />
      </Box>
    </InfoBox>
  );
};
