import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { useStyles } from './styles';

type DotStatusProps = {
  className?: string;
};

const DotStatus: FunctionComponent<DotStatusProps> = ({ children, className }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <span className={classNames(classes.dot, className)} />
      {children}
    </Box>
  );
};

export const OnJobStatusDot: FunctionComponent<DotStatusProps> = ({ className, ...props }) => {
  const classes = useStyles();
  return <DotStatus className={classNames(classes.onJob, className)} {...props} />;
};

export const OnlineStatusDot: FunctionComponent<DotStatusProps> = ({ className, ...props }) => {
  const classes = useStyles();
  return <DotStatus className={classNames(classes.greenDot, className)} {...props} />;
};

export const OfflineStatusDot: FunctionComponent<DotStatusProps> = ({ className, ...props }) => {
  const classes = useStyles();
  return <DotStatus className={classNames(classes.redDot, className)} {...props} />;
};
