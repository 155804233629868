import * as firebase from 'firebase';
import React, { createContext, useContext, useEffect } from 'react';
import { FirebaseAuthProvider, FirebaseDataProvider } from 'react-admin-firebase';
import 'firebase/firestore';
import { messageTypes } from 'react-admin-firebase/dist/misc';
import { GeolocationService, IGeolocationService } from './geolocation/GeolocationService';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const RADAR_IO_API_URL = process.env.REACT_APP_RADAR_IO_API_URL;
const RADAR_IO_PUBLIC_KEY = process.env.REACT_APP_RADAR_IO_PUBLIC_KEY;

type DataProvider = (
  type: string,
  resourceName: string,
  params: any,
) => Promise<messageTypes.IResponseAny>;

type AuthProvider = {
  login: (params: any) => Promise<unknown>;
  logout: () => Promise<void>;
  checkAuth: () => Promise<unknown>;
  checkError: (error: any) => Promise<string>;
  getPermissions: () => Promise<any>;
  getJWTAuthTime: () => Promise<any>;
  getJWTExpirationTime: () => Promise<any>;
  getJWTSignInProvider: () => Promise<any>;
  getJWTClaims: () => Promise<any>;
  getJWTToken: () => Promise<any>;
};

interface IDatabaseContext {
  dataProvider: DataProvider;
  authProvider: AuthProvider;
  database: firebase.firestore.Firestore;
  functions: firebase.functions.Functions;
  geolocation: IGeolocationService;
}

export const DatabaseContext = createContext<IDatabaseContext | null>(null);

const firebaseApp = firebase.initializeApp(firebaseConfig);
const database = firebase.firestore();
const functions = firebase.functions();
database.settings({ experimentalForceLongPolling: true });
const dataProvider = FirebaseDataProvider({}, { app: firebaseApp });

const authProvider = FirebaseAuthProvider({}, { app: firebaseApp, lazyLoading: { enabled: true } });

const initializeSentry = async () => {
  const claims = (await authProvider.getJWTClaims()) as { email: string; user_id: string } | null;
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    initialScope: claims ? { user: { email: claims.email, id: claims.user_id } } : undefined,
  });
};

export const DatabaseProvider: React.FC = ({ children }) => {
  const [isSentryInitializing, setSentryInitializing] = React.useState(false);
  useEffect(() => {
    setSentryInitializing(true);
    initializeSentry().finally(() => {
      setSentryInitializing(false);
    });
  }, []);
  return (
    <DatabaseContext.Provider
      value={{
        // @ts-ignore
        dataProvider,
        // @ts-ignore
        authProvider,
        database,
        functions,
        geolocation: new GeolocationService(RADAR_IO_API_URL, RADAR_IO_PUBLIC_KEY),
      }}
    >
      {isSentryInitializing ? null : children}
    </DatabaseContext.Provider>
  );
};

export const useDatabase = () => {
  const context = useContext(DatabaseContext);

  if (!context) throw new Error('useDatabase has to be used within DatabaseProvider');

  return context;
};
