import React from 'react';
import classnames from 'classnames';
import { Button } from '@material-ui/core';
import { PaymentMethod, PaymentStatus } from '../../JobUtils';
import { PaymentsRepository } from '../../PaymentsRepository';
import { useButtonStyles } from '../../../styles/commonStyles';
import { useStyles } from './styles';
import { IPayment } from '../../IJob';

export const AddPaymentButton = ({
  jobId,
  amount,
  disabled,
  text,
  method,
  providerId,
  onSubmit,
}: {
  jobId: string;
  amount: number;
  disabled: boolean;
  text: string;
  method: PaymentMethod;
  providerId?: string | null;
  onSubmit: () => void;
}) => {
  const paymentsRepository = PaymentsRepository.getRepository();
  const classes = useStyles();
  const buttonClasses = useButtonStyles();

  const onClick = async () => {
    let payment: Partial<IPayment> = {
      amount: amount,
      method: method,
      createdate: new Date(),
      status: PaymentStatus.SUCCEEDED,
      jobId: jobId,
    };

    if (providerId) payment = { ...payment, providerId };

    await paymentsRepository.createPayment(payment);
    onSubmit();
  };

  return (
    <Button
      className={classnames(buttonClasses.defaultButton, classes.addPayment)}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};
