import { useState } from 'react';
import { useInterval } from './useInterval';

export default () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useInterval(() => {
    setCurrentTime(new Date());
  }, 60000);

  return currentTime;
};
