import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Drawer, IconButton, Tab, Tabs, Typography } from '@material-ui/core';
import { IJob } from '../IJob';
import { JobDrawerTabs } from '../JobUtils';
import { ProvidersRepository } from '../ProvidersRepository';
import { useProvidersLocations } from '../../contexts/ProvidersLocationsContext';
import { splitByActivity } from '../../helpers/providerHelpers';
import { OfflineStatusDot, OnlineStatusDot } from '../../components/Dots';
import { LeftArrowsIcon, RightArrowsIcon, HistoryIcon } from '../../components/Icons';
import { Loader } from '../../components/Loader';
import { IProviderLocation } from '../../Map/Map.interface';
import { TabLabel } from '../../components/TabLabel';
import { IProvider } from '../../Providers/Provider.interface';
import { useDatabase } from '../../DatabaseProvider';
import { COLLECTIONS, parseToLatLngLiteral } from '../../utils';
import { useStyles } from './styles';
import { LaborTab } from './LaborTab';
import { PartsTab } from './PartsTab';
import { PayoutTab } from './PayoutTab';
import { CommentsTab } from './CommentsTab';
import { NotificationsTab } from './NotificationsTab/index';
import { LocationsRepository } from '../LocationsRepository';
import { PhotosTab } from './PhotosTab/PhotosTab';

interface JobRequestsDrawerProps {
  job: IJob;
  isOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  isJobLocked: boolean;
  props: any;
}
export const JobDrawer = ({
  job,
  isOpen,
  openDrawer,
  closeDrawer,
  isJobLocked,
  props,
}: JobRequestsDrawerProps) => {
  const classes = useStyles();

  const {
    isLoadingLocations: isLoadingProvidersLocations,
    getProvidersFromArea,
  } = useProvidersLocations();

  const [tabValue, setTabValue] = useState<number>(0);
  const [onJobProvider, setOnJobProvider] = useState<IProviderLocation>();
  const providersRepository = ProvidersRepository.getRepository();
  const locationsRepository = LocationsRepository.getRepository();

  const { database } = useDatabase();

  useEffect(() => {
    const getOnJobProvider = async () => {
      const provider = job.providerId
        ? await providersRepository.getProvider(job.providerId)
        : undefined;

      if (!provider) return;

      const lastLocation = await locationsRepository.getLastProviderLocation(provider.uid);
      if (lastLocation) {
        setOnJobProvider({ ...provider, ...lastLocation, ...parseToLatLngLiteral(lastLocation) });
        return;
      }
    };
    void getOnJobProvider();
  }, [job.providerId, providersRepository, locationsRepository]);

  useEffect(() => {
    if (job.providerId) {
      const unsubscribe = database
        .collection(COLLECTIONS.PROVIDERS)
        .doc(job.providerId)
        .onSnapshot(async doc => {
          const lastLocation = await locationsRepository.getLastProviderLocation(job.providerId);
          if (!lastLocation) return;
          const provider = doc.data() as IProvider;
          setOnJobProvider({
            ...provider,
            ...lastLocation,
            ...parseToLatLngLiteral(lastLocation),
          });
        });

      return () => unsubscribe();
    }
  }, [database, job.providerId, locationsRepository]);

  const handleTabChange = useCallback((event: any, newValue: number) => {
    setTabValue(newValue);
  }, []);

  if (!job) return null;

  const { serviceArea } = job;
  const inJobAreaProviders = getProvidersFromArea(serviceArea);
  const isAnyProviderInJobArea = inJobAreaProviders.length > 0;
  const notOnJobProviders = inJobAreaProviders.filter(
    (provider: IProviderLocation) => provider.uid !== job.providerId,
  );
  const [onlineProviders, offlineProviders] = splitByActivity(notOnJobProviders);
  const tabs = new Map<JobDrawerTabs, React.ReactNode>();
  tabs.set(
    JobDrawerTabs.LABOR,
    <LaborTab
      provider={onJobProvider}
      jobId={job.id}
      job={job}
      isJobLocked={isJobLocked}
      {...props}
    />,
  );
  tabs.set(
    JobDrawerTabs.PARTS,
    <PartsTab
      provider={onJobProvider}
      jobId={job.id}
      job={job}
      isJobLocked={isJobLocked}
      {...props}
    />,
  );
  tabs.set(
    JobDrawerTabs.NOTIFICATIONS,
    <NotificationsTab
      job={job}
      offlineProviders={offlineProviders}
      onlineProviders={onlineProviders}
      onJobProvider={onJobProvider}
      isJobLocked={isJobLocked}
    />,
  );
  tabs.set(
    JobDrawerTabs.COMMENTS,
    <CommentsTab provider={onJobProvider} jobId={job.id} job={job} {...props} />,
  );
  tabs.set(JobDrawerTabs.PHOTOS, <PhotosTab jobId={job.id} />);
  tabs.set(
    JobDrawerTabs.PAYOUT,
    <PayoutTab provider={onJobProvider} job={job} isJobLocked={isJobLocked} {...props} />,
  );
  return (
    <div>
      <TriggerButton
        onClick={openDrawer}
        onlineProvidersCount={onlineProviders.length}
        offlineProvidersCount={offlineProviders.length}
        isLoading={isLoadingProvidersLocations}
        withDetails={isAnyProviderInJobArea}
      />
      <Drawer
        open={isOpen}
        variant="persistent"
        onClose={closeDrawer}
        anchor="right"
        ModalProps={{ hideBackdrop: true }}
        className={classes.drawer}
      >
        <Box className={classes.drawerContent}>
          <Box className={classes.headerWrapper}>
            <DrawerHeader closeDrawer={closeDrawer} />
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label={<TabLabel text="Notifications" />} value={JobDrawerTabs.NOTIFICATIONS} />
              <Tab label={<TabLabel text="Labor" />} value={JobDrawerTabs.LABOR} />
              <Tab label={<TabLabel text="Parts" />} value={JobDrawerTabs.PARTS} />
              <Tab label={<TabLabel text="Comments" />} value={JobDrawerTabs.COMMENTS} />
              <Tab label={<TabLabel text="Photos" />} value={JobDrawerTabs.PHOTOS} />
              <Tab label={<TabLabel text="Payout" />} value={JobDrawerTabs.PAYOUT} />
            </Tabs>
          </Box>
          {tabs.get(tabValue)}
        </Box>
      </Drawer>
    </div>
  );
};

interface TriggerButtonProps {
  onClick: () => void;
  isLoading: boolean;
  onlineProvidersCount: number;
  offlineProvidersCount: number;
  withDetails: boolean;
}

const TriggerButton = ({
  onClick,
  isLoading,
  onlineProvidersCount,
  offlineProvidersCount,
  withDetails,
}: TriggerButtonProps) => {
  const classes = useStyles();

  const showDetails = !isLoading && withDetails;
  return (
    <Button onClick={onClick} className={classes.drawerTrigger} disabled={isLoading}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <LeftArrowsIcon className={classes.drawerTriggerArrowIcon} />
          <HistoryIcon className={classes.drawerTriggerIcon} />
          <div className={classes.drawerTriggerLabel}>job details</div>
          {showDetails && (
            <Box className={classes.drawerTriggerContent}>
              <OnlineStatusDot className={classes.statusDot} />
              <span className={classes.statusDot}>{onlineProvidersCount}</span>
              <OfflineStatusDot className={classes.statusDot} />
              <span>{offlineProvidersCount}</span>
            </Box>
          )}
        </>
      )}
    </Button>
  );
};

const DrawerHeader = ({ closeDrawer }: { closeDrawer: () => void }) => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2.5} px={3}>
      <Typography variant="h5" component="h5" className={classes.drawerTitle}>
        <HistoryIcon />
        Job details
      </Typography>
      <IconButton onClick={closeDrawer}>
        <RightArrowsIcon />
      </IconButton>
    </Box>
  );
};
