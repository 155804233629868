import React from 'react';
import BarChartIcon from '@material-ui/icons/BarChart';
import { Box } from '@material-ui/core';
import { ChartBox } from '../../ChartBox';
import { IProvider } from '../../Provider.interface';
import {
  AcceptIcon,
  JobsDoneIcon,
  ProPlusBadgeIcon,
  RepairIcon,
  StarIcon,
  StarRatingIcon,
} from '../../../components/Icons';
import { InfoBox } from '../../../components/InfoBox';
import { isProPlusAchieved } from '../../../helpers/providerHelpers';
import { useStyles } from './styles';

export const ProMetricsCard = ({ provider }: { provider: IProvider }) => {
  const isProPlus = isProPlusAchieved(provider);
  const classes = useStyles({ isProPlus: isProPlus });

  return (
    <InfoBox title="pro+ metrics" icon={<BarChartIcon style={{ height: '16px', width: '16px' }} />}>
      <Box p={3} className={classes.chartsWrapper} display="flex" justifyContent="space-between">
        <ChartBox
          title="Accept Rate"
          subtitle="95% required"
          icon={AcceptIcon}
          isDone={(provider.acceptRate || 0) >= 95}
          value={provider.acceptRate}
          label={provider.acceptRate?.toFixed(0)}
          unit="%"
        />
        <ChartBox
          title="Star Rating"
          subtitle="4.00 required"
          isDone={(provider.rating || 0) >= 4.0}
          icon={StarRatingIcon}
          value={(provider.rating || 0) * 20}
          label={provider.rating?.toFixed(2) || '0.00'}
          unit={<StarIcon />}
        />
        <ChartBox
          title="Jobs done"
          subtitle="1 required"
          isDone={(provider.completedJobs || 0) > 0}
          icon={JobsDoneIcon}
          value={((provider.completedJobs || 0) / 1) * 100}
          label={provider.completedJobs}
          unit={<RepairIcon />}
        />
      </Box>
      <Box
        margin={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.metricsTextContainer}
      >
        <ProPlusBadgeIcon className={classes.proPlusBadge} />
        {isProPlus ? (
          <span>{' metrics have been met.'}</span>
        ) : (
          <span>{' metrics have not been met.'}</span>
        )}
      </Box>
    </InfoBox>
  );
};
