import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { IServiceAreaBase } from '../Jobs/IJob';
import { useDatabase } from '../DatabaseProvider';
import { Heading } from '../components/Heading';
import { extractDataFromRef } from '../helpers/dataHelpers';
import { openedJobStatuses } from '../Jobs/JobUtils';
import { MapsProvider } from '../contexts/MapsContext';
import { ProvidersLocationsContextProvider } from '../contexts/ProvidersLocationsContext';
import { ServiceAreaMap } from './ServiceAreaMap';
import { Select } from '../shared/components/Select/Select';
import { JobsListSidebar } from './JobsListSidebar';
import { useStyles } from './styles';
import { useServiceAreas } from '../utils/hooks';
import { parseToLatLngNumbers } from '../utils';

export const Map: React.FC = () => {
  const [serviceAreaId, setServiceAreaId] = useState<string | null>(null);
  const [jobLocation, setJobLocation] = useState<{ lat: number; lng: number } | null>(null);
  const classes = useStyles();
  const { database } = useDatabase();
  const { serviceAreas } = useServiceAreas();

  const [openedJobs, setOpenedJobs]: [IServiceAreaBase[], any] = useState([]);

  useEffect(() => {
    const getJobs = async () => {
      let openedJobsRef = await database
        .collection('jobs')
        .where('status', 'in', openedJobStatuses)
        .get();

      const openedJobs = extractDataFromRef(openedJobsRef);
      setOpenedJobs(openedJobs);
    };

    void getJobs();
  }, [database]);
  const serviceArea2 = serviceAreas.find(x => x.id === serviceAreaId);

  let zoom = 5;
  if (serviceArea2) {
    zoom = 9;
  } else if (jobLocation) {
    zoom = 15;
  }

  let center = { lat: 37.0902, lng: -95.7129 };
  if (serviceArea2) {
    center = parseToLatLngNumbers(serviceArea2.epicenter);
  } else if (jobLocation) {
    center = jobLocation;
  }

  return (
    <MapsProvider>
      <ProvidersLocationsContextProvider>
        <Box className={classes.pageWrapper}>
          <Box className={classes.pageHeader}>
            <Heading>Map</Heading>
            <Box minWidth="266px">
              <Select
                value={serviceAreaId}
                emptyValueName="All service areas"
                options={serviceAreas.map(x => ({ id: x.id, name: x.name }))}
                onChange={serviceAreaId => {
                  setServiceAreaId(serviceAreaId);
                  setJobLocation(null);
                }}
              />
            </Box>
          </Box>
          <Box className={classes.pageContent}>
            <JobsListSidebar
              jobs={openedJobs}
              onJobLocationChange={(lat, lng) => {
                setJobLocation({ lat, lng });
                setServiceAreaId(null);
              }}
            />
            <ServiceAreaMap jobs={openedJobs} zoom={zoom} center={center} />
          </Box>
        </Box>
      </ProvidersLocationsContextProvider>
    </MapsProvider>
  );
};
