import React from 'react';
import { InvoiceBlock, InvoiceListItem } from './index';
import { IInvoiceDetail } from '../../../utils/InvoiceDataFormatter';
import { IInvoiceServiceDto } from '../../dtos/IInvoiceData.dto';

interface LaborSectionProps {
  labor: IInvoiceDetail;
  afterHours?: IInvoiceDetail;
  service?: IInvoiceServiceDto;
}

export const LaborSection = ({ labor, afterHours, service }: LaborSectionProps) => {
  if (!service) return <InvoiceBlock title="Labor">-</InvoiceBlock>;

  return (
    <InvoiceBlock title="Labor">
      <InvoiceListItem
        name={labor.name}
        description={labor.description}
        calculations={labor.calculations}
        cost={labor.cost}
      />
      {afterHours && (
        <InvoiceListItem
          name={afterHours.name}
          description={afterHours.description}
          calculations={afterHours.calculations}
          cost={afterHours.cost}
        />
      )}
    </InvoiceBlock>
  );
};
