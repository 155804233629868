import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export type IPickersDate = MaterialUiPickersDate;

export const DatePickersUtilsProvider: React.FC = ({ children }) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>{children}</MuiPickersUtilsProvider>
);

export const getPickerFormatDate = (date: Date) => moment(date);
