import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../styles';

export const useStyles = makeStyles(() => ({
  goodResponseTime: {
    color: colors.green,
  },
  badResponseTime: {
    color: colors.red,
  },
}));
