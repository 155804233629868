import React from 'react';
import { Box, Typography } from '@material-ui/core';
import theme from '../styles/theme';

export const HelperText = ({
  icon,
  text,
  className,
}: {
  icon: any;
  text: string;
  className?: any;
}) => {
  const Icon = icon;
  return (
    <Box
      display="flex"
      alignItems="center"
      color={theme.palette.text.secondary}
      height="12px"
      className={className}
    >
      <Icon style={{ height: '12px' }} />
      <Typography variant="caption">{text}</Typography>
    </Box>
  );
};
