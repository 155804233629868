import { GoogleMap, Marker } from '@react-google-maps/api';
import { default as React, useEffect, useState } from 'react';
import { getGeocode } from 'use-places-autocomplete';
import greenCarIcon from '../assets/greenCarIcon.png';
import jobMarkerIcon from '../assets/jobMarkerIcon.svg';
import redCarIcon from '../assets/redCarIcon.png';
import whiteCarIcon from '../assets/whiteCarIcon.png';
import { Loader } from '../components/Loader';
import { useMaps } from '../contexts/MapsContext';
import { useProvidersLocations } from '../contexts/ProvidersLocationsContext';
import { zoomLevels } from '../Map/mapUtils';
import { ProviderMarkerDetails } from '../Map/ProviderMarkerDetails';
import { providerTypes } from '../Providers/ProviderUtils';
import { parseToLatLngNumbers, parseToLatLong } from '../utils';
import { deserializeGeocode } from '../utils/deserializeGeocode';
import { IJob } from './IJob';
import { RouteRenderer } from './RouteRenderer';

export const mapContainerStyle = {
  flexGrow: 1,
  height: '100%',
};

export const JobMap = ({
  job,
  isJobMarkerDraggable = true,
}: {
  job?: IJob;
  isJobMarkerDraggable?: boolean;
}) => {
  const {
    isLoading: isMapLoading,
    loadError,
    onMapLoad,
    coordinates,
    setCoordinates,
    address,
    setAddress,
    setLocation,
  } = useMaps();
  const { locations, isLoadingLocations } = useProvidersLocations();
  const [selectedProvider, setSelectedProvider] = useState<any>(null);
  const latLngCoordinates = parseToLatLngNumbers(coordinates);
  const isLoading = isLoadingLocations || isMapLoading;

  useEffect(() => {
    if (job) {
      setLocation(job.location);
    } // eslint-disable-next-line
  }, [job]);

  if (loadError) return <>An error occured when loading maps</>;
  if (isLoading) return <Loader text="Map loading" />;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={zoomLevels.hasOwnProperty(address.type) ? zoomLevels[address.type] : 15}
      onLoad={onMapLoad}
      center={latLngCoordinates}
      options={{ scaleControl: true }}
      onClick={() => {
        setSelectedProvider(null);
      }}
    >
      {Object.keys(locations).map(providerId => {
        return (
          <ProviderMarker
            key={providerId}
            location={locations[providerId]}
            onClick={() => setSelectedProvider(providerId)}
          />
        );
      })}
      {selectedProvider && <ProviderMarkerDetails provider={locations[selectedProvider]} />}
      {address.value && coordinates && (
        <JobMarker
          coordinates={latLngCoordinates}
          setCoordinates={setCoordinates}
          setAddress={setAddress}
          draggable={isJobMarkerDraggable}
        />
      )}
      {job?.drivingStartDate && <RouteRenderer job={job} />}
    </GoogleMap>
  );
};

const ProviderMarker: React.FC<{
  location: any;
  onClick: () => void;
}> = ({ location, onClick }) => {
  const icon = location.live
    ? location.type === providerTypes[0].id
      ? whiteCarIcon
      : greenCarIcon
    : redCarIcon;

  return (
    <Marker
      position={location}
      icon={{
        url: icon,
        anchor: new google.maps.Point(22, 22),
        scaledSize: new google.maps.Size(45, 45),
      }}
      onClick={onClick}
    />
  );
};

const JobMarker: React.FC<{
  coordinates: { lat: number; lng: number };
  setCoordinates: any;
  setAddress: any;
  draggable: boolean;
}> = ({ coordinates, setCoordinates, setAddress, draggable }) => {
  return (
    <Marker
      position={coordinates}
      icon={{
        url: jobMarkerIcon,
        anchor: new google.maps.Point(22, 22),
        scaledSize: new google.maps.Size(45, 45),
      }}
      draggable={draggable}
      onDragEnd={async ({ latLng }) => {
        const newCoordinates = { lat: latLng.lat(), lng: latLng.lng() };
        setCoordinates(parseToLatLong(newCoordinates));

        try {
          const results = await getGeocode({ location: newCoordinates });
          if (!results) return;
          const { formattedAddress, city, state } = await deserializeGeocode(results);

          setAddress({ value: formattedAddress, state, city });
        } catch (error) {
          console.error(error);
        }
      }}
    />
  );
};
