import React from 'react';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import theme from '../../styles/theme';
import { font } from '../../styles';

export const BackToButton = ({ onClick, text }: { onClick: () => void; text: string }) => {
  const classes = useStyles();

  return (
    <Box>
      <IconButton onClick={onClick} className={classes.icon}>
        <ArrowBackIcon color="primary" className={classes.arrow} />
      </IconButton>
      <Typography
        variant="overline"
        style={{ fontWeight: font.weight.semiBold, color: theme.palette.text.secondary }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export const useStyles = makeStyles(() => ({
  icon: {
    padding: theme.spacing(1),
  },
  arrow: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '100%',
    stroke: theme.palette.primary.main,
    strokeWidth: 2,
    height: '10px',
    width: '10px',
  },
}));
