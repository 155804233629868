import React from 'react';
import { Avatar, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import theme from '../styles/theme';
import { DefaultAvatarIcon } from './Icons';
import { colors } from '../styles';

export const useStyles = makeStyles(() => ({
  avatar: {
    width: ({ width }: { width: number }) => `${width}px`,
    height: ({ width }: { width: number }) => `${width}px`,
    color: colors.grey,
  },
  badge: {
    alignSelf: 'center',
    '& svg': {
      width: ({ width }: { width: number }) => `${width / 2}px`,
    },
    '& .MuiBadge-root': {
      width: ({ width }: { width: number }) => `${width}px`,
      height: ({ width }: { width: number }) => `${width}px`,
    },
    '& .MuiBadge-badge': {
      zIndex: 0,
    },
    '& .MuiBadge-dot': {
      width: '40%',
      height: '40%',
      minWidth: '40%',
      minHeight: '40%',
      position: 'absolute',
      bottom: 0,
      right: 0,
      boxShadow: ({ width, status }: { width: number; status: string }) =>
        `inset 0 0 0 ${width / 13}px ${statusStyles[status].outerCircleColor}`,
      borderRadius: '50%',
      transform: 'none',
      '&::after': {
        content: '""',
        width: '50%',
        height: '50%',
        backgroundColor: ({ status }: { status: string }) => statusStyles[status].background,
        boxShadow: ({ status, width }: { status: string; width: number }) =>
          `0 0 0 ${width / 22}px ${statusStyles[status].innerCircleColor}`,
        borderRadius: '50%',
      },
    },
  },
}));

export const UserAvatar = (props: {
  name: string;
  status: string;
  width: number;
  style?: any;
  className?: string;
}) => {
  const classes = useStyles(props);

  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot"
      invisible={props.status === 'offline'}
      className={classnames(classes.badge, props.className)}
    >
      <Avatar alt={props.name} className={classes.avatar} style={props.style}>
        <DefaultAvatarIcon />
      </Avatar>
    </Badge>
  );
};

const statusStyles: {
  [key: string]: {
    background: string;
    outerCircleColor: string;
    innerCircleColor: string;
  };
} = {
  offline: {
    background: 'transparent',
    outerCircleColor: 'transparent',
    innerCircleColor: 'transparent',
  },
  offlineOnJob: {
    background: theme.palette.common.white,
    outerCircleColor: theme.palette.common.white,
    innerCircleColor: theme.palette.error.main,
  },
  online: {
    background: theme.palette.primary.main,
    outerCircleColor: theme.palette.common.white,
    innerCircleColor: theme.palette.primary.main,
  },
  onlineOnJob: {
    background: theme.palette.common.white,
    outerCircleColor: theme.palette.common.white,
    innerCircleColor: theme.palette.primary.main,
  },
};
