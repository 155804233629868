import React from 'react';
import classnames from 'classnames';
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Table,
} from '@material-ui/core';
import { RequestStatus } from '../../JobUtils';
import { IJob, IRequest } from '../../IJob';
import { RequestStatusChip } from '../../../components/RequestStatusChip';
import { formatDate, formatTime, timeAgo } from '../../../helpers/timeHelpers';
import { FullNameField } from '../../../components/FullNameField';
import { IProvider } from '../../../Providers/Provider.interface';
import useCurrentTime from '../../../utils/hooks/useCurrentTime';
import { useStyles } from './styles';
import { TravelField } from './TravelField';
import {
  getProviderFullName,
  getProviderStatus,
  isProPlusAchieved,
} from '../../../helpers/providerHelpers';

export const NotificationList = ({
  job,
  requests,
  providers,
}: {
  job: IJob;
  requests: IRequest[];
  providers: IProvider[];
}) => {
  const classes = useStyles();
  return (
    <Box mt={3}>
      <Table className={classes.notifications}>
        <NotificationsTableHead />
        <TableBody>
          {requests.map((request: IRequest) => {
            const requestedProvider = providers.find(
              provider => provider.uid === request.providerId,
            );
            return (
              <NotificationItem
                request={request}
                isProviderAssigned={!!job.providerId}
                key={`${request.providerId}-${request.id}`}
                requestedProvider={requestedProvider}
              />
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

const NotificationsTableHead = () => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.firstColumn} align="left">
          Provider
        </TableCell>
        <TableCell align="left">Response</TableCell>
        <TableCell>Travel</TableCell>
        <TableCell className={classes.lastColumn} align="right">
          Notification sent
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const NotificationItem = ({
  request,
  isProviderAssigned,
  requestedProvider,
}: {
  request: IRequest;
  isProviderAssigned: boolean;
  requestedProvider: IProvider | undefined;
}) => {
  const classes = useStyles();
  const isCanceled = request.status === RequestStatus.CANCELED;

  return (
    <TableRow>
      <TableCell
        className={classnames(classes.firstColumn, isCanceled && classes.canceledRequest)}
        align="left"
      >
        {requestedProvider && (
          <FullNameField
            withProBadge={false}
            providerFullName={getProviderFullName(requestedProvider)}
            providerStatus={getProviderStatus(requestedProvider.live, requestedProvider.onJob)}
            isProviderProPlus={isProPlusAchieved(requestedProvider)}
          />
        )}
      </TableCell>
      <TableCell align="left">
        {request.status !== undefined && (
          <RequestStatusChip status={request.status} online={request.online} />
        )}
      </TableCell>
      <TableCell>
        {request.travel && (
          <TravelField
            distanceInFeet={request.travel.distanceInFeet}
            durationInMinutes={request.travel.durationInMinutes}
          />
        )}
      </TableCell>
      <TableCell
        className={classnames(classes.lastColumn, isCanceled && classes.canceledRequest)}
        align="right"
      >
        <NotificationSent
          date={request.createdate.toDate()}
          withTimeAgoFormat={isProviderAssigned}
        />
      </TableCell>
    </TableRow>
  );
};

const NotificationSent = ({
  date,
  withTimeAgoFormat,
}: {
  date: Date;
  withTimeAgoFormat: boolean;
}) => {
  const classes = useStyles();
  const currentTime = useCurrentTime();

  if (withTimeAgoFormat) {
    return (
      <>
        <Typography variant="body2">
          <span className={classes.createDate}>{formatDate(date)}</span>
          {formatTime(date)}
        </Typography>
      </>
    );
  } else {
    return <Typography variant="body2">{timeAgo(date, currentTime)}</Typography>;
  }
};
