import React, { useCallback, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { useDatabase } from '../../DatabaseProvider';
import { COLLECTIONS } from '../../utils';
import { ICardTransactionDto } from '../dtos/ICardTransaction.dto';
import { ISortSettings } from '../../shared/common/SortOrder';
import { IPaginationSettings } from '../../shared/common/IPaginationSettings';

export class CardTransactionListResponse {
  cardTransactions: ICardTransactionDto[] = [];
  total: number = 0;
  assignedCardTransactionsCount: number = 0;
  unassignedCardTransactionsCount: number = 0;
}

export const useCardTransactionList = (
  filter: boolean,
  sortSettings: ISortSettings<CardTransactionSortField>,
  paginationSettings: IPaginationSettings,
) => {
  const { functions, database } = useDatabase();
  const [isLoading, setLoading] = React.useState(false);
  const [listState, setListState] = React.useState(new CardTransactionListResponse());

  const notify = useNotify();

  const fetchCardTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const result = await functions.httpsCallable('getCardTransactionList')({
        assigned: filter,
        sortField: sortSettings.orderBy,
        sortOrder: sortSettings.order.toUpperCase(),
        pagination: { perPage: paginationSettings.rowsPerPage, page: paginationSettings.page },
      });
      if (result.data?.data) setListState(result.data?.data);
    } catch (error) {
      notify('ra.message.error', 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [functions, notify, filter, sortSettings, paginationSettings]);

  useEffect(() => {
    fetchCardTransactions();
  }, [fetchCardTransactions, functions, notify, filter, sortSettings, paginationSettings]);

  useEffect(() => {
    let firstRun = true;
    const unsubscribe = database.collection(COLLECTIONS.CARD_TRANSACTIONS).onSnapshot(() => {
      if (firstRun) {
        firstRun = false;
        return;
      }
      fetchCardTransactions();
    });
    return () => unsubscribe();
  }, [database, fetchCardTransactions]);

  return { listState, isLoading };
};
