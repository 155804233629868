import React from 'react';
import { Box } from '@material-ui/core';
import theme from '../styles/theme';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';

export const OptionWithIconRenderer = (props: {
  name: string;
  icon: any;
  color?: string;
  strokeWidth?: number;
}) => {
  const Icon = props.icon;
  const classes = useStyles(props);

  return (
    <Box display="flex" alignItems="center">
      <Icon className={classes.icon} />
      {props.name}
    </Box>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    color: ({ color }: { color?: string }) => color || palette.primary.main,
    stroke: ({ color }: { color?: string }) => color || palette.primary.main,
    strokeWidth: ({ strokeWidth }: { strokeWidth?: number }) => strokeWidth || 0,
    marginRight: theme.spacing(1),
    width: '16px',
  },
}));

export const sanitizeWithKeys = (data: any, keys: string[]) => {
  if (!data || !keys) return {};

  return _.pick(data, keys);
};

export function parseIdToIndex(inputId: string) {
  const number = Number(inputId.match(/\d/g)?.join('')) || 0;
  return number;
}
