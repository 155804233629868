import { ListHeader } from '../../components/ListHeader';
import {
  Box,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
} from '@material-ui/core';
import { TabLabel } from '../../components/TabLabel';
import React, { useCallback, useState } from 'react';
import { formatDateWithTime } from '../../helpers/timeHelpers';
import { currencyFormat } from '../../helpers/formatHelpers';
import { useCardTransactionList } from '../hooks/useCardTransactionList';
import { IHeadCell, ListTableHead } from '../../components/ListTableHead/ListTableHead';
import { CardTransactionStatus } from '../dtos/ICardTransaction.dto';
import { ListViewProps } from '../../interfaces';
import { ISortSettings, SortOrder } from '../../shared/common/SortOrder';
import { RowsPerPage } from '../../shared/common/RowsPerPage';
import { Loader } from '../../components/Loader';
import { IPaginationSettings } from '../../shared/common/IPaginationSettings';
import { TablePagination } from '../../components/TablePagination/TablePagination';

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(2),
  },
}));

export const CardTransactionListPage = (props: ListViewProps) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState<number>(0);
  const [isAssignedToJobFilter, setIsAssignedToJobFilter] = React.useState(false);
  const [sortSettings, setSortSettings] = React.useState<ISortSettings<CardTransactionSortField>>({
    order: SortOrder.DESC,
    orderBy: 'createdate',
  });
  const [paginationSettings, setPaginationSettings] = React.useState<IPaginationSettings>({
    page: 1,
    rowsPerPage: RowsPerPage.TWENTY_FIVE,
  });

  const { listState, isLoading } = useCardTransactionList(
    isAssignedToJobFilter,
    sortSettings,
    paginationSettings,
  );

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setIsAssignedToJobFilter(newValue !== 0);
      setTabValue(newValue);
      setPaginationSettings({
        page: 1,
        rowsPerPage: paginationSettings.rowsPerPage,
      });
    },
    [paginationSettings.rowsPerPage],
  );

  const headCells: IHeadCell<CardTransactionSortField>[] = [
    { id: 'createdate', label: 'Date' },
    { id: 'description', label: 'Description' },
    { id: 'providerFullName', label: 'Provider' },
    { id: 'cardLast4', label: 'Card' },
    { id: 'status', label: 'Status' },
    { id: 'amountInDollars', label: 'Amount', align: 'right' },
  ];

  if (isLoading) return <Loader text="Loading card transactions..." />;
  return (
    <Box display="flex" flexDirection="column">
      <ListHeader {...props}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab
            label={<TabLabel text="Unassigned" total={listState.unassignedCardTransactionsCount} />}
            value={0}
          />
          <Tab
            label={<TabLabel text="Assigned" total={listState.assignedCardTransactionsCount} />}
            value={1}
          />
        </Tabs>
      </ListHeader>
      <Box display="flex" m={3} className={classes.wrapper}>
        <TableContainer>
          <Table className="table">
            <ListTableHead<CardTransactionSortField>
              sortSettings={sortSettings}
              onSortSettingsChange={setSortSettings}
              headCells={headCells}
            />
            <TableBody>
              {listState.cardTransactions.map(ct => (
                <TableRow key={ct.cardTransactionId}>
                  <TableCell>{formatDateWithTime(new Date(ct.createdate))}</TableCell>
                  <TableCell>{ct.description}</TableCell>
                  <TableCell>{ct.providerFullName}</TableCell>
                  <TableCell>{ct.cardLast4}</TableCell>
                  <TableCell>
                    {ct.status === CardTransactionStatus.CAPTURE ? 'Completed' : 'Refunded'}
                  </TableCell>
                  <TableCell align="right">{currencyFormat(ct.amountInDollars)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        count={listState.total}
        paginationSettings={paginationSettings}
        onPaginationSettingsChange={setPaginationSettings}
      />
    </Box>
  );
};
