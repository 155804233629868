import React, { useEffect, useState } from 'react';
import { Polyline } from '@react-google-maps/api';
import * as firebase from 'firebase';
import { IJob } from './IJob';
import { useDatabase } from '../DatabaseProvider';
import { extractDataFromRef } from '../helpers/dataHelpers';
import { parseToLatLngLiteral } from '../utils';

interface RouteRendererProps {
  job: IJob;
}

export interface IDocument {
  data: () => any;
  exists: boolean;
}

const polylineOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  radius: 60000,
};

export const RouteRenderer: React.FC<RouteRendererProps> = ({ job }) => {
  const { database } = useDatabase();
  const [path, setPath] = useState([] as { lat: number; lng: number }[]);

  useEffect(() => {
    const getPath = async () => {
      const lastLocations = await getLastLocations(database, job);
      const lastLocationsData = extractDataFromRef(lastLocations);
      const path = lastLocationsData.map((loc: ILocation) => parseToLatLngLiteral(loc));

      setPath(path);
    };

    void getPath();
  }, [job, setPath, database]);

  return <Polyline path={path} options={polylineOptions} />;
};

async function getLastLocations(database: firebase.firestore.Firestore, job: IJob) {
  return await database
    .collection('locations')
    .where('providerId', '==', job.providerId)
    .orderBy('createdate', 'desc')
    .where('createdate', '>=', job.drivingStartDate!.toDate())
    .where('createdate', '<=', job.drivingEndDate ? job.drivingEndDate.toDate() : new Date())
    .get();
}
