import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MaterialModal from '@material-ui/core/Modal';
import { Typography, Box, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { Button } from '../Buttons';
import { useButtonStyles } from '../../styles/commonStyles';
import { colors, font } from '../../styles';
import { SecondaryButton } from '../Buttons/SecondaryButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'absolute',
      top: ' 50%',
      left: ' 50%',
      transform: 'translate(-50%, -50%)',
      width: 648,
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(3),
      backgroundColor: theme.palette.primary.dark,
    },
    icon: {
      marginRight: '10px',
    },
    title: {
      fontWeight: font.weight.bold,
      fontSize: font.size.medium,
      color: theme.palette.common.white,
      textTransform: 'uppercase',
    },
    cancelButton: {
      color: '#636D7B',
    },
    content: {
      margin: theme.spacing(3),
      padding: theme.spacing(1.5),
      color: theme.palette.text.secondary,
      fontSize: font.size.default,
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2, 3),
      background: colors.catskillWhite,
    },
  }),
);

export const Modal = ({
  handleClose,
  children,
  open,
  handleConfirm,
  title,
  isProcessing,
  confirmText,
  disabled,
}: {
  handleClose: () => void;
  open: boolean;
  children: any;
  handleConfirm: () => void;
  title: any;
  isProcessing?: boolean;
  confirmText?: string;
  disabled?: boolean;
}) => {
  const classes = useStyles();
  const btnClasses = useButtonStyles();

  return (
    <MaterialModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Typography className={classes.title} variant="h5">
            {title}
          </Typography>

          <IconButton aria-label="close" className={classes.cancelButton} onClick={handleClose}>
            <CancelIcon fontSize="small" />
          </IconButton>
        </Box>

        <Box className={classes.content}>{children}</Box>

        <Box className={classes.buttonsWrapper}>
          <SecondaryButton onClick={handleClose} disabled={isProcessing}>
            Cancel
          </SecondaryButton>
          <Button
            onClick={handleConfirm}
            className={btnClasses.defaultButton}
            isProcessing={isProcessing}
            disabled={disabled}
          >
            {confirmText || 'Confirm'}
          </Button>
        </Box>
      </Box>
    </MaterialModal>
  );
};
