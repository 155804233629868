import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
// @ts-ignore
import { setSidebarVisibility } from 'react-admin';

import useStyles from './styles';
import { SidebarClosedIcon, SidebarOpenIcon } from '../../Icons';

export const ToggleMenuButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // @ts-ignore
  const open = useSelector(state => state.admin.ui.sidebarOpen);

  return (
    <MenuItem className={classes.logout} onClick={() => dispatch(setSidebarVisibility(!open))}>
      {open ? <SidebarOpenIcon /> : <SidebarClosedIcon />}
    </MenuItem>
  );
};
