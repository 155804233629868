import { createMuiTheme } from '@material-ui/core';
import { colors, font, BORDER_RADIUS } from './index';
import { calendarStyles, INPUT_BORDER_WIDTH } from './commonStyles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: colors.geyser,
      main: colors.green,
      dark: colors.shark,
    },
    secondary: {
      main: colors.white,
      light: colors.rollingStone,
    },
    error: {
      main: colors.bittersweet,
      dark: colors.red,
    },
    type: 'light',
    text: {
      primary: colors.shark,
      secondary: colors.rollingStone,
    },
  },
  typography: {
    fontFamily: font.family.primary,
  },
  spacing: 8,
  overrides: {
    MuiLinearProgress: {
      root: {
        display: 'none',
      },
    },
    MuiListItemIcon: {
      root: {
        marginRight: '10%',
        color: 'inherit',
      },
    },
    MuiTableCell: {
      head: {
        '-webkit-transition': 'all 0.5s ease',
        fontSize: `${font.size.default}px`,
        fontWeight: font.weight.bold,
        lineHeight: '16px',
        textTransform: 'capitalize',
        color: colors.shark,
        padding: '24px 16px 24px 0px',
        whiteSpace: 'nowrap',
        height: '64px',
        zIndex: '0 !important' as any,
      },
      body: {
        fontSize: `${font.size.default}px`,
        fontWeight: font.weight.light,
        lineHeight: '16px',
        padding: '0px 5px 0px 0px',
        borderBottom: `${colors.catskillWhite} 1px solid`,
        whiteSpace: 'nowrap',
        height: '48px',
      },
      paddingCheckbox: {
        display: 'none',
      },
      sizeSmall: {
        padding: '0 16px 0 16px',
      },
    },
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTableRow: {
      hover: {
        backgroundColor: colors.white,
        '&:hover': {
          backgroundColor: `${colors.lightGrey} !important`,
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: font.size.default,
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        '& p.MuiTypography-body2': {
          paddingLeft: '24px',
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: `${font.size.default}px`,
        color: colors.grey,
      },
      shrink: {
        display: 'none',
        padding: '10px',
      },
      filled: {
        transform: 'translate(12px, 16px) scale(1)',
        '&.MuiInputLabel-marginDense': {
          transform: 'translate(14px, 15px) scale(1)',
        },
      },
    },
    MuiInputBase: {
      root: {
        height: '40px',
        padding: '12px',
      },
    },
    MuiFormHelperText: {
      root: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        fontWeight: font.weight.bold,
        color: colors.black,
        padding: '16px 0 12px 0',
        marginRight: '12px',
        textTransform: 'unset',
        '&.Mui-selected > span > h6 > span': {
          background: colors.green,
          color: colors.white,
        },
      },
      textColorInherit: {
        minWidth: 0,
        opacity: 1,
      },
    },
    MuiTabs: {
      flexContainer: {
        backgroundColor: colors.white,
        padding: '0 24px',
      },
      indicator: {
        backgroundColor: `${colors.green} !important`,
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: colors.catskillWhite,
      },
    },
    MuiSwitch: {
      root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        marginRight: '8px',
      },
      switchBase: {
        padding: 2,
        color: colors.white,
        '&$checked': {
          transform: 'translateX(12px)',
          color: `${colors.white} !important`,
          '& + $track': {
            opacity: 1,
            backgroundColor: `${colors.green} !important`,
            borderColor: colors.geyser,
          },
        },
      },
      thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
      },
      track: {
        borderRadius: '5000px',
        opacity: 1,
        backgroundColor: colors.geyser,
      },
    },
    MuiFilledInput: {
      root: {
        border: `${INPUT_BORDER_WIDTH}px solid ${colors.geyser}`,
        borderRadius: BORDER_RADIUS,
        height: '40px',
        padding: '12px',
        backgroundColor: colors.white,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        '&:hover': {
          backgroundColor: colors.white,
          border: `${INPUT_BORDER_WIDTH}px solid ${colors.grey}`,
        },
        '&.Mui-focused': {
          border: `${INPUT_BORDER_WIDTH}px solid ${colors.green}`,
          backgroundColor: colors.white,
          borderRadius: BORDER_RADIUS,
        },
        '&.Mui-disabled': {
          background: colors.catskillWhite,
          color: colors.grey,
          border: 0,
        },
        '&.Mui-error': {
          border: `${INPUT_BORDER_WIDTH}px solid ${colors.bittersweet} !important`,
          borderRadius: BORDER_RADIUS,
          '& svg': {
            color: colors.bittersweet,
          },
        },
      },
      input: {
        padding: '10px',
        fontSize: `${font.size.default}px`,
      },
      inputMarginDense: {
        paddingBottom: '10px',
        paddingTop: '10px',
        paddingLeft: 0,
      },
      underline: {
        '&:before': {
          borderBottom: 0,
        },
        '&:after': {
          borderBottom: 0,
        },
        '&:hover': {
          '&:before': {
            borderBottom: 0,
          },
        },
      },
    },
    MuiChip: {
      label: {
        color: colors.white,
        letterSpacing: '0.02em',
        textTransform: 'uppercase',
        fontWeight: font.weight.bold,
        lineHeight: '16px',
        padding: '2px 8px 2px 8px',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        padding: 0,
        borderRadius: BORDER_RADIUS,
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.grey,
        },
      },
      notchedOutline: {
        borderColor: colors.geyser,
        '&:hover': {
          borderColor: colors.grey,
        },
      },
    },
    MuiPaper: {
      root: {
        '& .MuiPickerDTToolbar-toolbar': {
          backgroundColor: colors.whiteIce,
        },
        '& .MuiPickersDay-day': {
          ...calendarStyles.button.default,
        },
        '& .MuiPickersDay-daySelected': {
          ...calendarStyles.button.active,
        },
      },
    },
  },
});

export default theme;
