import React from 'react';
import { Box } from '@material-ui/core';
import { InfoBox } from '../../components/InfoBox';
import { useFormState } from 'react-final-form';
import { NumberInput } from '../../components/Inputs/NumberInput';
import { PayRateIcon } from '../../components/Icons';
import { ICompany } from '../models/ICompany';

interface LaborCardProps {
  controlsDisabled?: boolean;
}

export const LaborCard = ({ controlsDisabled }: LaborCardProps) => {
  const { values } = useFormState<ICompany>();

  return (
    <InfoBox title="Labor" icon={<PayRateIcon />} width="100%">
      <Box px={2}>
        <NumberInput
          source="minHours"
          label="Minimum hours"
          value={values.minHours}
          initialValue={0}
          placeholder="e.g. 2"
          disabled={controlsDisabled}
          allowNegative={false}
        />
      </Box>
    </InfoBox>
  );
};
