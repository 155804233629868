import React from 'react';
import { Box } from '@material-ui/core';
import { InfoBox } from '../../components/InfoBox';
import { useFormState } from 'react-final-form';
import { CurrencyInput } from '../../components/Inputs/CurrencyInput';
import { PayRateIcon } from '../../components/Icons';
import { ICompany } from '../models/ICompany';

interface CompanyRatesCardProps {
  controlsDisabled?: boolean;
}

export const CompanyRatesCard = ({ controlsDisabled }: CompanyRatesCardProps) => {
  const { values } = useFormState<ICompany>();
  return (
    <InfoBox title="Company Rates" icon={<PayRateIcon />} width="100%">
      <Box px={2}>
        <CurrencyInput
          source="rates.base"
          label="Per hour"
          value={values.rates?.base}
          defaultValue={0}
          disabled={controlsDisabled}
        />
        <CurrencyInput
          source="rates.callout"
          label="Travel (callout)"
          value={values.rates?.callout}
          defaultValue={0}
          disabled={controlsDisabled}
        />
        <CurrencyInput
          source="rates.afterHour"
          label="Afterhour (per hour)"
          value={values.rates?.afterHour}
          defaultValue={0}
          disabled={controlsDisabled}
        />
        <CurrencyInput
          source="rates.fuel"
          label="Fuel"
          value={values.rates?.fuel}
          defaultValue={0}
          disabled={controlsDisabled}
        />
      </Box>
    </InfoBox>
  );
};
