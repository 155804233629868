import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { ISortSettings, SortOrder } from '../../shared/common/SortOrder';

export interface IHeadCell<T> {
  id: T;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  label: string;
}

interface EnhancedTableHeadProps<T> {
  headCells: IHeadCell<T>[];
  sortSettings: ISortSettings<T>;
  onSortSettingsChange: (sortSettings: ISortSettings<T>) => void;
}

export function ListTableHead<T>(props: EnhancedTableHeadProps<T>) {
  const { headCells, sortSettings, onSortSettingsChange } = props;
  const { order, orderBy } = sortSettings;

  const handleTableSortLabelClick = (property: T) => {
    const isAsc = orderBy === property && order === SortOrder.ASC;
    onSortSettingsChange({
      order: isAsc ? SortOrder.DESC : SortOrder.ASC,
      orderBy: property,
    });
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          const isActive = orderBy === headCell.id;

          return (
            <TableCell
              key={i}
              align={headCell.align}
              padding="none"
              style={{ padding: '0 16px' }}
              sortDirection={isActive ? order : false}
            >
              <TableSortLabel
                active={isActive}
                direction={isActive ? order : 'asc'}
                onClick={() => handleTableSortLabelClick(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
