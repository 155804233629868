import React from 'react';
// @ts-ignore
import { CreateButton } from 'react-admin';
import { CreateIcon } from '../Icons';
import { useButtonStyles } from '../../styles/commonStyles';

export const CreateResourceButton = ({
  basePath,
  singular,
}: {
  basePath: string;
  singular: string;
}) => {
  const classes = useButtonStyles();

  return (
    <CreateButton
      basePath={basePath}
      className={classes.defaultButton}
      icon={<CreateIcon style={{ width: '14px' }} />}
      label={`new ${singular}`}
    />
  );
};
