import { ICoordinates } from './models/ICoordinates';
import { IFetchTravelInfoDTO } from './models/IFetchTravelInfo.dto';
import { IRadarIOFetchRouteDistanceAndDurationResponse } from './models/IRadarIOFetchRouteDistanceAndDurationResponse';

export interface IGeolocationService {
  fetchTravelInfo: (
    origin: ICoordinates,
    destination: ICoordinates,
  ) => Promise<IFetchTravelInfoDTO | null>;
}

export class GeolocationService implements IGeolocationService {
  private readonly apiUrl: string;
  private readonly publicKey: string;

  constructor(apiUrl: string | undefined, publicKey: string | undefined) {
    if (!apiUrl) {
      throw new Error('Geolocation service api url is not defined');
    }
    if (!publicKey) {
      throw new Error('Geolocation service public key is not defined');
    }
    this.apiUrl = apiUrl;
    this.publicKey = publicKey;
  }

  fetchTravelInfo = async (
    origin: ICoordinates,
    destination: ICoordinates,
  ): Promise<IFetchTravelInfoDTO | null> => {
    if (!this.areCoordinatesValid(origin) || !this.areCoordinatesValid(destination)) {
      throw new Error('Coordinates are invalid');
    }
    const params = {
      origin: `${origin.lat},${origin.long}`,
      destination: `${destination.lat},${destination.long}`,
      units: 'imperial',
      modes: 'car',
    };
    try {
      const searchParams = new URLSearchParams(params).toString();
      const response = await fetch(`${this.apiUrl}/route/distance?${searchParams}`, {
        headers: { Authorization: this.publicKey },
      });
      const json: IRadarIOFetchRouteDistanceAndDurationResponse = await response.json();
      if (json.meta.code !== 200 || !json.routes.car) {
        return null;
      }
      const { distance, duration } = json.routes.car;
      return { distanceInFeet: distance.value, durationInMinutes: duration.value };
    } catch (error) {
      return null;
    }
  };

  private areCoordinatesValid = (coordinates: ICoordinates): boolean => {
    if (!coordinates.lat || !coordinates.long) return false;
    return true;
  };
}
