import { useStyles } from '../NotificationsTab/styles';
import { useDatabase } from '../../../DatabaseProvider';
import React, { useState } from 'react';
import { Button } from '../../../components/Buttons';

interface StopNotifyingButtonProps {
  requestId: string;
}

interface IStopNotifyingDTO {
  requestId: string;
}

export const StopNotifyingButton = (props: StopNotifyingButtonProps) => {
  const classes = useStyles();
  const database = useDatabase();

  const [isStopping, setIsStopping] = useState(false);
  const [isProcessing, setProcessing] = useState(false);

  const stopNotifyingProvider = async () => {
    setProcessing(true);
    const fn = database.functions.httpsCallable('cancelRequest');
    try {
      const dto: IStopNotifyingDTO = {
        requestId: props.requestId,
      };
      setIsStopping(true);
      await fn(dto);
    } catch (e) {
      console.error(e);
    } finally {
      setIsStopping(false);
      setProcessing(false);
    }
  };

  return (
    <Button
      size="medium"
      className={classes.stopNotifyingButton}
      onClick={stopNotifyingProvider}
      disabled={isStopping}
      isProcessing={isProcessing}
    >
      Stop notifying
    </Button>
  );
};
