import React from 'react';
import { Box, Typography } from '@material-ui/core';
import * as _ from 'lodash';
import { EditableList } from '../components/EditableList';
import { getDurationInSeconds, getFirebaseTimestampFromDate } from '../../../helpers/timeHelpers';
import { getProviderFullName } from '../../../helpers/providerHelpers';
import { JobsRepository } from '../../JobsRepository';
import { IProviderLocation } from '../../../Map/Map.interface';
import { IJob, IRepairPeriod } from '../../IJob';
import { getPickerFormatDate, IPickersDate } from '../../../DatePickersUtilsProvider';
import { JobStatus } from '../../JobUtils';
import { useTabStyles } from '../../../styles/commonStyles';
import { LaborDescription, LaborItem } from './LaborItem';
import { LaborForm } from './LaborForm';

export interface ILaborValues {
  repairPeriodStartTime: IPickersDate;
  repairPeriodEndTime: IPickersDate;
}

export const LaborTab = ({
  provider,
  job,
  isJobLocked,
}: {
  provider: IProviderLocation;
  job: IJob;
  isJobLocked: boolean;
}) => {
  const tabClasses = useTabStyles();

  if (!provider)
    return (
      <Box p={3}>
        <Typography>No provider</Typography>
      </Box>
    );

  const labors = _.sortBy(job.repairPeriods, 'repairPeriodStartTime');

  const jobId = job.id;
  const jobsRepository = JobsRepository.getRepository();
  const isReadOnly =
    ![JobStatus.COMPLETED, JobStatus.MANUAL, JobStatus.IN_PROGRESS, JobStatus.PAUSED].includes(
      job.status,
    ) || isJobLocked;
  const allowAddAndDelete =
    [JobStatus.COMPLETED, JobStatus.MANUAL].includes(job.status) && !isJobLocked;
  const providerName = getProviderFullName(provider);

  const createLabor = async (formData: ILaborValues) => {
    const serializedPeriod = serializeLaborData(formData);
    if (!serializedPeriod) return;

    const editedPeriods = [...labors, serializedPeriod];

    await jobsRepository.updateJob(jobId, { repairPeriods: editedPeriods });
  };

  const updateLabor = async (formData: ILaborValues, id: number, inProgress?: boolean) => {
    const serializedPeriod = serializeLaborData(formData, inProgress);
    if (!serializedPeriod) return;

    const editedPeriods = labors.map((labor, index) => (index === id ? serializedPeriod : labor));
    await jobsRepository.updateJob(jobId, { repairPeriods: editedPeriods });
  };

  const deleteLabor = async (deletedPeriodId: number) => {
    const editedPeriods = labors.filter((labor, index) => index !== deletedPeriodId);
    await jobsRepository.updateJob(jobId, { repairPeriods: editedPeriods });
  };

  return (
    <Box className={tabClasses.listWrapper}>
      <EditableList
        data={labors}
        createForm={(props: { onCancelClick: () => void; id: number }) => (
          <LaborForm providerFullName={providerName} handleSubmit={createLabor} {...props} />
        )}
        editForm={({
          data,
          ...props
        }: {
          onCancelClick: () => void;
          data: IRepairPeriod;
          id: number;
        }) => {
          const isInProgress = !data.repairPeriodEndTime;
          return (
            <LaborForm
              providerFullName={providerName}
              handleSubmit={(newData: ILaborValues, id: number) =>
                updateLabor(newData, id, isInProgress)
              }
              data={deserializeLaborData(data)}
              inProgress={isInProgress}
              {...props}
            />
          );
        }}
        onDelete={deleteLabor}
        deleteModal={(item: IRepairPeriod) => ({
          title: 'Delete labor?',
          confirmText: 'Delete',
          content: (
            <Typography>
              Remove labor for <span className={tabClasses.boldText}>{providerName}</span>{' '}
              <LaborDescription data={item} />?
            </Typography>
          ),
        })}
        row={({ item }: { item: IRepairPeriod }) => (
          <LaborItem item={item} providerName={providerName} />
        )}
        addItemButtonLabel="Add labor"
        isReadOnly={isReadOnly}
        allowDelete={allowAddAndDelete}
        allowAdd={allowAddAndDelete}
      />
    </Box>
  );
};

const deserializeLaborData = (repairPeriod: IRepairPeriod): ILaborValues => {
  return {
    ...repairPeriod,
    repairPeriodStartTime: getPickerFormatDate(repairPeriod.repairPeriodStartTime.toDate()),
    repairPeriodEndTime: repairPeriod.repairPeriodEndTime
      ? getPickerFormatDate(repairPeriod.repairPeriodEndTime.toDate())
      : getPickerFormatDate(new Date()),
  };
};

const serializeLaborData = (
  repairPeriod: ILaborValues,
  inProgress?: boolean,
): IRepairPeriod | undefined => {
  if (!repairPeriod.repairPeriodStartTime) return;

  const startedRepairPeriod = {
    repairPeriodStartTime: getFirebaseTimestampFromDate(
      repairPeriod.repairPeriodStartTime.toDate(),
    ),
  };

  if (inProgress) return startedRepairPeriod;

  const repairPeriodEndTime = repairPeriod.repairPeriodEndTime
    ? repairPeriod.repairPeriodEndTime.toDate()
    : new Date();

  return {
    ...startedRepairPeriod,
    repairPeriodEndTime: getFirebaseTimestampFromDate(repairPeriodEndTime),
    durationInSeconds: getDurationInSeconds(
      repairPeriod.repairPeriodStartTime.toDate(),
      repairPeriodEndTime,
    ),
  };
};
