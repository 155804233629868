import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../styles/theme';
import { UserAvatar } from './UserAvatar';
import { ProBadge } from '../helpers/providerHelpers';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 24,
  },
  avatar: {
    width: '16px',
    height: '16px',
    margin: '2px',
  },
}));

interface FullNameFieldProps {
  withProBadge?: boolean;
  className?: string;

  providerFullName: string;
  providerStatus: 'onlineOnJob' | 'online' | 'offlineOnJob' | 'offline';
  isProviderProPlus: boolean;
}

export const FullNameField = ({
  withProBadge = true,
  className,
  providerFullName,
  providerStatus,
  isProviderProPlus,
}: FullNameFieldProps) => {
  const classes = useStyles();
  return (
    <Box className={classnames(classes.root, className)}>
      <UserAvatar name={providerFullName} status={providerStatus} width={20} />
      <Typography
        variant="body2"
        style={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }}
      >
        {providerFullName}
      </Typography>
      {withProBadge && <ProBadge isProviderProPlus={isProviderProPlus} />}
    </Box>
  );
};
