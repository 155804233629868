import { COLLECTIONS } from '../utils';
import { useDatabase } from '../DatabaseProvider';

export interface ILocationsRepository {
  getLastProviderLocation(providerId: string | undefined | null): Promise<ILocation | undefined>;
}

export class LocationsRepository implements ILocationsRepository {
  private database = useDatabase().database;
  private static instance: ILocationsRepository | undefined;

  static getRepository(): ILocationsRepository {
    if (!this.instance) {
      this.instance = new LocationsRepository();
    }
    return this.instance;
  }
  async getLastProviderLocation(
    providerId: string | undefined | null,
  ): Promise<ILocation | undefined> {
    const lastLocationRef = await this.database
      .collection(COLLECTIONS.LOCATIONS)
      .where('providerId', '==', providerId)
      .orderBy('createdate', 'desc')
      .limit(1)
      .get();

    const lastProviderLocation = lastLocationRef.docs[0]?.data() as ILocation | undefined;
    return lastProviderLocation;
  }
}
