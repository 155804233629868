import React from 'react';
import * as _ from 'lodash';
import { timeAgo } from '../../helpers/timeHelpers';

export const LastOnline = (props: any) => {
  const activity = lastActivity(props.data);
  if (!activity) return <div>never</div>;

  return <div>{activity.endTime ? timeAgo(activity.endTime) : 'now'}</div>;
};

function lastActivity(activities: any) {
  return _.sortBy(activities, 'startTime').slice(-1)[0];
}
