import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme';
import { colors, font, BORDER_RADIUS } from '../../styles';

export const useStyles = makeStyles(() => ({
  createButton: {
    fontSize: font.size.medium,
    fontWeight: font.weight.semiBold,
    width: '151px',
    height: '52px',
    background: theme.palette.primary.main,
    border: `2px solid ${colors.jade}`,
    boxSizing: 'border-box',
    color: 'white',
    margin: '20px',
    borderRadius: BORDER_RADIUS,
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'white',
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&:hover svg': {
      color: theme.palette.primary.main,
    },
  },
  container: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '240px',
  },
}));
