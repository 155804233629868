import { makeStyles } from '@material-ui/core/styles';
import { colors, font } from '../../styles';
import theme from '../../styles/theme';

export const useStyles = makeStyles(() => ({
  icon: {
    height: '12px',
    width: '12px',
  },
  highStreak: {
    color: colors.red,
  },
  mediumStreak: {
    color: colors.orange,
  },
  lowStreak: {
    color: colors.texasRose,
  },
  highRating: {
    color: theme.palette.primary.main,
  },
  mediumRating: {
    color: colors.texasRose,
  },
  lowRating: {
    color: colors.red,
  },
  highAcceptRate: {
    color: theme.palette.primary.main,
  },
  mediumAcceptRate: {
    color: colors.texasRose,
  },
  lowAcceptRate: {
    color: colors.red,
    '& path:nth-child(2)': {
      color: 'rgba(212, 32, 39, 0.3)',
    },
    '& path:nth-child(1)': {
      color: 'rgba(212, 32, 39, 0.3)',
    },
  },
  label: {
    fontSize: font.size.default,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));
