import React, { Dispatch, ReactNode } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { font } from '../styles';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.primary.light}`,

    '& svg': {
      height: '12px',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
  title: {
    textTransform: 'uppercase',
    fontSize: font.size.regular,
    fontWeight: font.weight.bold,
    display: 'contents',
  },
  editButton: {
    color: theme.palette.primary.main,
    height: '18px',
  },
}));

interface IInfoBox {
  title: string;
  icon: any;
  children?: ReactNode;
  setIsEditing?: Dispatch<boolean>;
  style?: any;
  width?: string;
}

export const InfoBox = ({
  icon,
  title,
  children,
  setIsEditing,
  style,
  width = '427px',
}: IInfoBox) => {
  const classes = useStyles();

  return (
    <Box width={width} className={classes.wrapper} style={style}>
      <Box
        display="flex"
        justifyContent="space-between"
        color="secondary.light"
        alignItems="center"
        p={0.5}
        className={classes.header}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" className={classes.title}>
            {icon}
            {title}
          </Typography>
        </Box>
        {setIsEditing && (
          <Button
            onClick={() => setIsEditing(true)}
            startIcon={<EditIcon style={{ marginRight: 0 }} />}
            className={classes.editButton}
          >
            <Typography variant="overline" style={{ fontWeight: font.weight.bold }}>
              Edit
            </Typography>
          </Button>
        )}
      </Box>
      {children}
    </Box>
  );
};
