import React, { Dispatch, useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { Box } from '@material-ui/core';

import { InfoBox } from '../components/InfoBox';
import {
  EpicenterIcon,
  MapPreviewIcon,
  PayRateIcon,
  ServiceAreaNameIcon,
} from '../components/Icons';
import { MapField } from './MapField';
import { ICoordinates } from '../Jobs/IJob';
import { CurrencyInput } from '../components/Inputs/CurrencyInput';
import { SwitchInput } from '../components/SwitchInput';
import { TextInput } from '../components/Inputs/TextInput';
import theme from '../styles/theme';
import { MapsProvider } from '../contexts/MapsContext';

interface IFormFields {
  setCenter: Dispatch<ICoordinates>;
  center: ICoordinates | undefined;
}

export const FormFields = ({ setCenter, center }: IFormFields) => {
  const { values } = useFormState();

  useEffect(() => {
    setCenter({
      long: values.epicenter?.long,
      lat: values.epicenter?.lat,
    });
  }, [values.epicenter, setCenter]);

  return (
    <Box display="flex" px={3} pt={3}>
      <Box>
        <SetupCard values={values} />
        <EpicenterCard values={values} />
        <PayRatesCard values={values} />
      </Box>
      <MapPreviewCard center={center} />
    </Box>
  );
};

interface SetupCardProps {
  values: {
    name?: string;
    state?: string;
    active?: boolean;
    googleReviewLinkUrl?: string;
  };
}
const SetupCard = ({ values }: SetupCardProps) => (
  <InfoBox icon={<ServiceAreaNameIcon />} title="Service area setup">
    <Box px={2} display="flex" flexDirection="column">
      <TextInput source="name" label="Name" value={values.name} placeholder="e.g. Kansas City" />
      <TextInput
        source="googleReviewLinkUrl"
        label="Google review link"
        value={values.googleReviewLinkUrl}
      />
      <TextInput
        label="State"
        source="state"
        value={values.state}
        placeholder="e.g. Missouri"
        style={{ paddingBottom: theme.spacing(1.5) }}
      />
      <SwitchInput
        isSwitchedOn={values.active}
        label="Service area status"
        source="active"
        labelOn="Active"
        labelOff="Inactive"
      />
    </Box>
  </InfoBox>
);

const EpicenterCard = ({ values }: { values: { epicenter?: ICoordinates } }) => (
  <InfoBox icon={<EpicenterIcon />} title="Epicenter">
    <Box px={2} display="flex" flexDirection="column">
      <TextInput
        label="Latitude"
        value={values.epicenter?.lat}
        source="epicenter.lat"
        placeholder="e.g. 39.099728"
      />
      <TextInput
        label="Longitude"
        value={values.epicenter?.long}
        source="epicenter.long"
        placeholder="e.g. -94.578568"
      />
    </Box>
  </InfoBox>
);

const PayRatesCard = ({
  values,
}: {
  values: { callout?: number; base?: number; afterHour?: number; fuel?: number };
}) => (
  <InfoBox icon={<PayRateIcon />} title="Service rates">
    <Box px={2} display="flex" flexDirection="column">
      <CurrencyInput source="callout" label="Travel (callout)" value={values.callout} />
      <CurrencyInput source="base" label="Rate per hour" value={values.base} />
      <CurrencyInput source="afterHour" label="Afterhour add" value={values.afterHour} />
      <CurrencyInput source="fuel" label="Fuel" value={values.fuel} defaultValue={25} />
    </Box>
  </InfoBox>
);

const MapPreviewCard = ({ center }: { center: ICoordinates | undefined }) => (
  <InfoBox
    icon={<MapPreviewIcon />}
    title="Map preview"
    style={{
      flexGrow: 1,
      marginLeft: theme.spacing(2),
    }}
  >
    <Box px={2} pb={2}>
      <MapsProvider>
        <MapField center={center} />
      </MapsProvider>
    </Box>
  </InfoBox>
);
