import React from 'react';
import { MenuItem, makeStyles } from '@material-ui/core';
import { useDatabase } from '../DatabaseProvider';
import { DropdownMenu } from '../components/DropdownMenu';
import { Modal } from '../components/Modal';
import { colors } from '../styles';
import { IJob } from './IJob';
import { closedJobStatuses, JobStatus } from './JobUtils';
import { JobsRepository } from './JobsRepository';

export const JobActions = ({ job }: { job: IJob }) => {
  const classes = useStyles();
  const jobsRepository = JobsRepository.getRepository();
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);
  const [completeModalOpen, setCompleteModalOpen] = React.useState(false);
  const [isProcessing, setProcessing] = React.useState(false);
  const database = useDatabase();

  const handleAction = async (actionName: string) => {
    setProcessing(true);
    const fn = database.functions.httpsCallable(actionName);
    try {
      await fn({ jobId: job.id });
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
    }
    setCancelModalOpen(false);
    setCompleteModalOpen(false);
  };

  const handleLock = async (locked: boolean) => {
    setProcessing(true);

    try {
      await jobsRepository.updateJob(job.id, { pendingReview: locked });
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <DropdownMenu>
        {job.status !== JobStatus.COMPLETED && job.pendingReview && (
          <MenuItem
            key="complete-action"
            onClick={() => setCompleteModalOpen(true)}
            className={classes.complete}
          >
            Complete job
          </MenuItem>
        )}
        {job.status !== JobStatus.CANCELED && job.pendingReview && (
          <MenuItem
            key="cancel-action"
            onClick={() => setCancelModalOpen(true)}
            className={classes.cancel}
          >
            Cancel job
          </MenuItem>
        )}
        {closedJobStatuses.includes(job.status) && (
          <MenuItem
            key="lock-action"
            onClick={() => handleLock(!job.pendingReview)}
            className={classes.lock}
          >
            {job.pendingReview ? `Approve & lock job` : 'Unlock job'}
          </MenuItem>
        )}
      </DropdownMenu>
      <Modal
        open={cancelModalOpen}
        handleClose={() => setCancelModalOpen(false)}
        handleConfirm={() => handleAction('cancelRepair')}
        title="Canceling job"
        isProcessing={isProcessing}
      >
        You are going to cancel the job. This action will influence provider's statistics.
      </Modal>
      <Modal
        open={completeModalOpen}
        handleClose={() => setCompleteModalOpen(false)}
        handleConfirm={() => handleAction('completeRepair')}
        title="Completing job"
        isProcessing={isProcessing}
        disabled={!job.providerId}
      >
        {job.providerId
          ? "You are going to complete the job. This action will influence provider's statistics."
          : 'You cannot perform this action without assigning the provider and service.'}
      </Modal>
    </>
  );
};

export const useStyles = makeStyles(({ palette }) => ({
  cancel: {
    color: palette.error.main,
  },
  complete: {
    color: palette.primary.main,
  },
  lock: {
    color: colors.sunshade,
  },
}));
