import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  firstColumn: {
    width: '40%',
    alignItems: 'flex-start',
  },
  partDescriptionCol: {
    alignItems: 'flex-start',
  },
  formContainer: {
    display: 'flex',
  },
  firstFormCol: {
    flex: '0 0 60%',
    marginRight: spacing(1.5),
  },
  secondFormCol: {
    flex: '0 0 40%',
  },
  inputWrapper: {
    width: '100%',
    paddingBottom: 0,
    marginBottom: spacing(1),
  },
  countInput: {
    marginRight: spacing(1.5),
  },
  flexRow: {
    display: 'flex',
  },
  lowercase: {
    textTransform: 'lowercase',
  },
  errorMessage: {
    textTransform: 'capitalize',
  },
}));
