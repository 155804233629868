import React from 'react';
// @ts-ignore
import { useGetList } from 'react-admin';
import { Datagrid, TextField, List, ReferenceManyField, FunctionField } from 'react-admin';
import { useSelector } from 'react-redux';
import { Tab, Tabs } from '@material-ui/core';
import classnames from 'classnames';
import { COLLECTIONS } from '../../utils';
import { FullNameField } from '../../components/FullNameField';
import { ListWrapper } from '../../components/ListWrapper';
import { ListViewProps } from '../../interfaces';
import { ListHeader } from '../../components/ListHeader';
import { TabLabel } from '../../components/TabLabel';
import { StarRating } from '../../components/Ratings/StarRating';
import { AcceptRating } from '../../components/Ratings/AcceptRating';
import { ResponseTime } from '../components/ResponseTime/ResponseTime';
import { currencyFormat, formatPhoneNumber } from '../../helpers/formatHelpers';
import { useStyles } from './styles';
import { LastOnline } from './LastOnline';
import { IProvider } from '../Provider.interface';
import {
  getProviderFullName,
  getProviderStatus,
  isProPlusAchieved,
} from '../../helpers/providerHelpers';
import { RowsPerPage } from '../../shared/common/RowsPerPage';

export const ProviderList: React.FC<ListViewProps> = ({
  baseResourcePath = COLLECTIONS.PROVIDERS,
  ...props
}) => {
  const classes = useStyles();
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
  const [filter, setFilter] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const totalDisabledProviders = useGetDisabledProviders();
  const totalEnabledProviders = useGetEnabledProviders();

  const handleChange = (event: any, newValue: any) => {
    setFilter(newValue !== 0);
    setTabValue(newValue);
  };
  return (
    <List
      {...props}
      actions={null}
      perPage={RowsPerPage.TWENTY_FIVE}
      filterDefaultValues={{ disabled: filter }}
    >
      <>
        <ListHeader {...props}>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label={<TabLabel text="Unblocked" total={totalEnabledProviders} />} value={0} />
            <Tab label={<TabLabel text="Blocked" total={totalDisabledProviders} />} value={1} />
          </Tabs>
        </ListHeader>
        <ListWrapper>
          <Datagrid
            className={classnames(classes.table, {
              [classes.tableSmall]: open,
            })}
            rowClick="edit"
          >
            <FunctionField
              source="Name"
              render={(record: IProvider) => (
                <FullNameField
                  providerFullName={getProviderFullName(record)}
                  providerStatus={getProviderStatus(record.live, record.onJob)}
                  isProviderProPlus={isProPlusAchieved(record)}
                />
              )}
            />
            <FunctionField
              source="phoneNumber"
              render={({ phoneNumber }: { phoneNumber: string }) => formatPhoneNumber(phoneNumber)}
            />
            <TextField source="email" label="E-mail Address" />
            <FunctionField
              source="systemName"
              label="OS"
              render={({ systemName, systemVersion }: IProvider) =>
                systemName && systemVersion ? `${systemName} ${systemVersion}` : null
              }
            />
            <FunctionField
              label="App Version"
              source="appVersion"
              render={({ appVersion, appBuild }: IProvider) =>
                appVersion && appBuild ? `${appVersion} (${appBuild})` : null
              }
            />
            <ReferenceManyField label="Last online" reference="activities" target="providerId">
              <LastOnline />
            </ReferenceManyField>
            <ReferenceManyField
              label="Jobs"
              reference="jobs"
              target="providerId"
              sort={{ field: 'jobs', order: 'ASC' }}
            >
              <JobsTotal />
            </ReferenceManyField>
            <FunctionField
              source="balance"
              label="Cash Balance"
              render={({ balance }: { balance: number }) => currencyFormat(balance)}
            />
            <FunctionField
              source="rating"
              label="Rating"
              render={({ rating }: { rating: number }) => <StarRating value={rating} />}
            />
            <FunctionField
              source="acceptRate"
              label="Acc. Rate"
              render={({ acceptRate }: { acceptRate: number }) => (
                <AcceptRating value={acceptRate} />
              )}
            />
            <FunctionField
              label="Response Time"
              source="responseTimeRate"
              render={({ responseTimeRate }: { responseTimeRate: number }) => (
                <ResponseTime responseTimeRate={responseTimeRate} />
              )}
            />
          </Datagrid>
        </ListWrapper>
      </>
    </List>
  );
};

const JobsTotal = (props: any) => <div>{props.total}</div>;

const useGetDisabledProviders = () => {
  const { total: totalDisabled } = useGetList(
    'providers',
    { perPage: 1, page: 1 },
    { field: 'id', order: 'ASC' },
    { disabled: true },
  );

  return totalDisabled;
};

const useGetEnabledProviders = () => {
  const { total: totalEnabled } = useGetList(
    'providers',
    { perPage: 1, page: 1 },
    { field: 'id', order: 'ASC' },
    { disabled: false },
  );

  return totalEnabled;
};
