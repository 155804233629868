import React from 'react';
// @ts-ignore
import { useGetList } from 'react-admin';
import { Datagrid, List, TextField, FunctionField } from 'react-admin';
import { EmptyState } from '../components/EmptyState';
import { ListViewProps } from '../interfaces';
import { ListWrapper } from '../components/ListWrapper';
import { ListHeader } from '../components/ListHeader';
import { Tab, Tabs } from '@material-ui/core';
import { TabLabel } from '../components/TabLabel';
import Box from '@material-ui/core/Box';
import { getServicesAverageDuration } from '../helpers/jobHelpers';
import { convertNumToTime, convertSecondsToTime } from '../helpers/timeHelpers';
import { makeStyles } from '@material-ui/core/styles';
import { currencyFormat } from '../helpers/formatHelpers';
import { ServiceIcon } from './ServiceUtils';
import { RowsPerPage } from '../shared/common/RowsPerPage';

export const ServiceList = (props: ListViewProps) => {
  const classes = useStyles();
  const [filter, setFilter] = React.useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const totalActiveServices = useGetActiveServices();
  const totalInactiveServices = useGetInactiveServices();
  const servicesAverageDuration = useGetServicesAverageDurations();
  // @ts-ignore
  const handleChange = (event, newValue) => {
    setFilter(newValue === 0 ? true : false);
    setTabValue(newValue);
  };

  return (
    <List
      empty={<EmptyState />}
      actions={null}
      {...props}
      filterDefaultValues={{ active: filter }}
      perPage={RowsPerPage.TWENTY_FIVE}
    >
      <>
        <ListHeader {...props}>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label={<TabLabel text="Active" total={totalActiveServices} />} value={0} />
            <Tab label={<TabLabel text="Inactive" total={totalInactiveServices} />} value={1} />
          </Tabs>
        </ListHeader>
        <ListWrapper>
          <Datagrid rowClick="edit">
            <FunctionField
              source="name"
              label="Name"
              render={(service: { name: string; iconId: number }) => {
                return (
                  <Box display="flex" alignItems="center">
                    <ServiceIcon service={service} style={{ marginRight: '8px', width: '20px' }} />
                    <span style={{ padding: '2px' }}>{service.name}</span>
                  </Box>
                );
              }}
            />
            <TextField source="description" />
            <FunctionField
              source="flatRate"
              headerClassName={classes.alignment}
              cellClassName={classes.alignment}
              render={({ flatRate, hourly }: { flatRate: number; hourly: boolean }) =>
                hourly ? '-' : currencyFormat(flatRate)
              }
            />
            <FunctionField
              label="Minimum hours"
              source="minHours"
              headerClassName={classes.alignment}
              cellClassName={classes.alignment}
              render={({ minHours, hourly }: { minHours: number; hourly: boolean }) =>
                hourly ? convertNumToTime(minHours) : '-'
              }
            />
            <FunctionField
              source="id"
              label="Average Hours"
              headerClassName={classes.alignment}
              cellClassName={classes.alignment}
              render={({ id }: { id: string }) =>
                servicesAverageDuration[id]
                  ? convertSecondsToTime(servicesAverageDuration[id])
                  : '-'
              }
            />
          </Datagrid>
        </ListWrapper>
      </>
    </List>
  );
};

const useGetActiveServices = () => {
  const { total: totalActive } = useGetList(
    'services',
    { perPage: 1, page: 1 },
    { field: 'id', order: 'ASC' },
    { active: true },
  );

  return totalActive;
};

const useGetInactiveServices = () => {
  const { total: totalInactive } = useGetList(
    'services',
    { perPage: 1, page: 1 },
    { field: 'id', order: 'ASC' },
    { active: false },
  );

  return totalInactive;
};

const useGetServicesAverageDurations = () => {
  const { data: jobs } = useGetList(
    'jobs',
    { perPage: 1000, page: 1 },
    { field: 'id', order: 'ASC' },
  );

  return getServicesAverageDuration(jobs);
};

export const useStyles = makeStyles(() => ({
  alignment: {
    textAlign: 'end',
    maxWidth: '50px',
  },
}));
