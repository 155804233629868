import React from 'react';
import { Box, Typography } from '@material-ui/core';
import theme from '../styles/theme';
import { ApprovedIcon } from './Icons';
import { font } from '../styles';

export const ProviderStatusBadge = ({
  isBlocked,
  withLabel,
}: {
  isBlocked: boolean;
  withLabel?: boolean;
}) => {
  return (
    <Box display="flex" alignItems="center">
      <ApprovedIcon
        style={{
          color: isBlocked ? theme.palette.primary.light : theme.palette.primary.main,
        }}
      />
      {withLabel && (
        <Typography
          variant="overline"
          style={{
            fontWeight: font.weight.bold,
            marginLeft: theme.spacing(1),
            fontSize: font.size.default,
          }}
        >
          {isBlocked ? 'blocked' : 'unblocked'}
        </Typography>
      )}
    </Box>
  );
};
