import React from 'react';
import { Box } from '@material-ui/core';
import { ContentWrapper } from '../../../components/ContentWrapper';
import { InformationCard } from './InformationCard';
import { ProMetricsCard } from './ProMetricsCard';
import { IProvider } from '../../Provider.interface';
import { PayoutsCard } from './PayoutsCard';

export const DetailsTab = ({
  provider,
  setIsEditing,
}: {
  provider: IProvider;
  setIsEditing: any;
}) => {
  return (
    <ContentWrapper>
      <Box display="flex">
        <Box display="flex" flexDirection="column">
          <InformationCard provider={provider} setIsEditing={setIsEditing} />
          <ProMetricsCard provider={provider} />
        </Box>
        <PayoutsCard provider={provider} />
      </Box>
    </ContentWrapper>
  );
};
