import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import { BottomToolbar } from '../components/BottomToolbar';
import { Box } from '@material-ui/core';
import { SingleResourceHeader } from '../components/SingleResourceHeader';
import { FormFields } from './FormFields';
import { useFormStyles } from '../styles/commonStyles';

export const ServiceEdit: React.FC = (props: any) => {
  const classes = useFormStyles();

  return (
    <Edit {...props} className={classes.root}>
      <SimpleForm toolbar={<BottomToolbar />} className={classes.form}>
        <Box display="flex" flexDirection="column">
          <SingleResourceHeader singular={props.options.singular} />
          <FormFields />
        </Box>
      </SimpleForm>
    </Edit>
  );
};
