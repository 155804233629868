import React, { useEffect } from 'react';
import { Edit } from 'react-admin';
import { Box, Tab, Tabs } from '@material-ui/core';
import { PaymentsTab } from './PaymentsTab';
import { ContactsTab } from './ContactsTab';
import { Header } from '../Header';
import { JobMap } from '../JobMap';
import { VehicleTab } from '../VehicleTab';
import { JobDrawer } from '../JobDrawer';
import { JobTabs } from '../JobUtils';
import { JobFormWrapper } from '../JobFormWrapper';
import { TabLabel } from '../../components/TabLabel';
import theme from '../../styles/theme';
import { MapsProvider } from '../../contexts/MapsContext';
import { ProvidersLocationsContextProvider } from '../../contexts/ProvidersLocationsContext';
import { useDatabase } from '../../DatabaseProvider';
import { cameFromCreate } from '../../helpers/jobHelpers';
import { Loader } from '../../components/Loader';
import { InvoiceTab } from './InvoiceTab';

export const JobEdit: React.FC<{}> = (props: any) => {
  const isThisCreateJobPage = cameFromCreate(props.history);
  const [tabValue, setTabValue] = React.useState(
    isThisCreateJobPage ? JobTabs.CONTACTS : JobTabs.VEHICLE,
  );
  const [job, setJob] = React.useState<any>(null);
  const [refreshedJob, setRefreshedJob] = React.useState<any>(job);
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const { database } = useDatabase();
  const isContactFulfilled =
    refreshedJob?.driver?.phoneNumber || refreshedJob?.dispatcher?.phoneNumber;
  const isJobLocked = !refreshedJob?.pendingReview;

  useEffect(() => {
    const getJob = async () => {
      const newJob = await database.collection('jobs').doc(props.id).get();
      const newJobData = newJob.data();
      setJob({ ...newJobData, id: props.id });
    };
    void getJob();
  }, [database, props.id, tabValue]);

  useEffect(() => {
    if (isThisCreateJobPage && job && tabValue === JobTabs.CONTACTS) {
      setTimeout(() => setDrawerOpen(true), 1500);
    }
    // eslint-disable-next-line
  }, [job, isThisCreateJobPage]);

  useEffect(() => {
    const unsubscribe = database
      .collection('jobs')
      .doc(props.id)
      .onSnapshot(function (doc) {
        setRefreshedJob({ ...doc.data(), id: props.id });
      });
    return () => unsubscribe();
  }, [database, props.id]);

  // @ts-ignore
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (!job) return <Loader />;

  return (
    <MapsProvider>
      <ProvidersLocationsContextProvider>
        <Box display="flex" flexBasis="100%">
          <JobFormWrapper>
            <Box
              borderBottom={`1px solid ${theme.palette.primary.light}`}
              boxShadow={`0px 0px 3px ${theme.palette.primary.light}`}
              zIndex={1}
            >
              <Header job={refreshedJob} />
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label={<TabLabel text="Vehicle" />} value={JobTabs.VEHICLE} />
                <Tab
                  label={<TabLabel text="Contacts" alertBadge={!isContactFulfilled} />}
                  value={JobTabs.CONTACTS}
                />
                <Tab label={<TabLabel text="Payments" />} value={JobTabs.PAYMENTS} />
                <Tab label={<TabLabel text="Invoice" />} value={JobTabs.INVOICE} />
              </Tabs>
            </Box>
            {tabValue === JobTabs.VEHICLE && (
              <VehicleTab
                {...props}
                formType={Edit}
                tabValue={tabValue}
                setTabValue={setTabValue}
                job={refreshedJob}
                isJobLocked={isJobLocked}
                jobCompany={job.company || null}
              />
            )}
            {tabValue === JobTabs.CONTACTS && (
              <ContactsTab
                {...props}
                tabValue={tabValue}
                setTabValue={setTabValue}
                isJobLocked={isJobLocked}
              />
            )}
            {tabValue === JobTabs.PAYMENTS && (
              <PaymentsTab
                {...props}
                tabValue={tabValue}
                setTabValue={setTabValue}
                isJobLocked={isJobLocked}
                job={refreshedJob}
              />
            )}
            {tabValue === JobTabs.INVOICE && (
              <InvoiceTab
                {...props}
                tabValue={tabValue}
                setTabValue={setTabValue}
                isJobLocked={isJobLocked}
                job={refreshedJob}
              />
            )}
          </JobFormWrapper>
          <Box width="100%">
            <JobMap job={job} isJobMarkerDraggable={tabValue === JobTabs.VEHICLE} />
            <JobDrawer
              job={refreshedJob}
              isOpen={isDrawerOpen}
              openDrawer={() => setDrawerOpen(true)}
              closeDrawer={() => setDrawerOpen(false)}
              isJobLocked={isJobLocked}
              props={props}
            />
          </Box>
        </Box>
      </ProvidersLocationsContextProvider>
    </MapsProvider>
  );
};
