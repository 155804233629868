import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { IPayment } from '../../IJob';
import { PaymentsRepository } from '../../PaymentsRepository';
import { DeleteButton } from '../../JobDrawer/components/DeleteButton';
import { PaymentStatus } from '../../JobUtils';
import { currencyFormat } from '../../../helpers/formatHelpers';
import theme from '../../../styles/theme';
import { colors } from '../../../styles';
import {
  formatDate,
  formatTime,
  getDateFromFirestoreTimestamp,
} from '../../../helpers/timeHelpers';
import { useProviders } from '../../../utils/hooks/useProviders';
import { IProvider } from '../../../Providers/Provider.interface';
import { getProviderFullName } from '../../../helpers/providerHelpers';
import { PaymentStatusChip } from './PaymentStatusChip';
import { useStyles } from './styles';

interface PaymentHistorySectionProps {
  payments: IPayment[];
}

export const PaymentHistorySection = ({ payments }: PaymentHistorySectionProps) => {
  const classes = useStyles();
  const { providers } = useProviders();
  console.log(payments);
  return (
    <Box display="flex" flexDirection="column" pt={3} px={3}>
      <Typography className={classes.title}>Payment history</Typography>
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow className={classes.header}>
              <TableCell>amount</TableCell>
              <TableCell>payment method</TableCell>
              <TableCell>provider</TableCell>
              <TableCell>date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {payments.map(payment => (
              <PaymentItem payment={payment} providers={providers} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

interface PaymentItemProps {
  payment: IPayment;
  providers: IProvider[];
}

const PaymentItem = ({ payment, providers }: PaymentItemProps) => {
  const classes = useStyles();
  const paymentsRepository = PaymentsRepository.getRepository();
  const provider = providers.find(p => p.uid === payment.providerId);
  const isCard = payment.method.toLowerCase().includes('card');
  const paymentSucceeded = payment.status === PaymentStatus.SUCCEEDED;
  const paymentStatusName = payment.status.replaceAll('_', ' ');

  const onClick = async () => {
    await paymentsRepository.deletePayment(payment.id);
  };

  return (
    <TableRow className={classes.paymentItem}>
      <TableCell component="th" scope="row" className={classes.amount}>
        <span>{currencyFormat(payment.amount)}</span>
        {isCard && (
          <PaymentStatusChip
            name={paymentStatusName}
            fontColor={paymentSucceeded ? theme.palette.primary.main : colors.red}
            backgroundColor={paymentSucceeded ? colors.lightGreen : colors.bittersweetLight}
          />
        )}
      </TableCell>
      <TableCell component="th" scope="row">
        {payment.method}
      </TableCell>
      <TableCell component="th" scope="row">
        {getProviderFullName(provider)}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box className={classes.actionWrapper}>
          {`${formatDate(getDateFromFirestoreTimestamp(payment.createdate))} ${formatTime(
            getDateFromFirestoreTimestamp(payment.createdate),
          )}`}
          {!isCard && <DeleteButton className={classes.deleteButton} onClick={onClick} />}
        </Box>
      </TableCell>
    </TableRow>
  );
};
