import React from 'react';
import { Box, Chip, makeStyles } from '@material-ui/core';
import * as JobUtils from '../Jobs/JobUtils';
import { RequestStatus } from '../Jobs/JobUtils';
import { OfflineStatusDot } from './Dots';

export const RequestStatusChip = ({
  status,
  fontSize = 12,
  online = true,
}: {
  status: RequestStatus;
  fontSize?: number;
  online?: boolean;
}) => {
  const classes = useStyles();

  return (
    <Chip
      label={<StatusLabel status={status} online={online} />}
      className={classes.root}
      style={{
        color: JobUtils.requestStatuses[status]?.color,
        background: JobUtils.requestStatuses[status]?.background,
        fontSize: `${fontSize}px`,
      }}
    />
  );
};

const StatusLabel = ({ status, online }: { status: RequestStatus; online: boolean }) => {
  const classes = useStyles();
  const isCanceled = status === RequestStatus.CANCELED;

  return (
    <Box display="flex">
      {!online && !isCanceled && <OfflineStatusDot className={classes.statusDot} />}
      {JobUtils.requestStatuses[status]?.name}
    </Box>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: '24px',
    '& .MuiChip-label': {
      color: 'inherit',
    },
  },
  statusDot: {
    marginRight: spacing(0.5),
  },
}));
