import React, { useState } from 'react';
import { Create, SimpleForm } from 'react-admin';
import { Box } from '@material-ui/core';
import { SingleResourceHeader } from '../components/SingleResourceHeader';
import { BottomToolbar } from '../components/BottomToolbar';
import { FormFields } from './FormFields';
import { useFormStyles } from '../styles/commonStyles';
import { ICoordinates } from '../Jobs/IJob';

export const ServiceAreaCreate: React.FC = (props: any) => {
  const classes = useFormStyles();
  const [center, setCenter] = useState<ICoordinates | undefined>();

  return (
    <Create {...props} className={classes.root}>
      <SimpleForm
        toolbar={<BottomToolbar {...props} isCreate={true} customRedirect="list" />}
        className={classes.form}
      >
        <Box display="flex" flexDirection="column">
          <SingleResourceHeader singular={props.options.singular} isCreate={true} />
          <FormFields setCenter={setCenter} center={center} />
        </Box>
      </SimpleForm>
    </Create>
  );
};
