import React from 'react';
import { FIVE_MINUTES_IN_SECONDS, getParsedMinutesAndSeconds } from '../../../helpers/timeHelpers';
import { useStyles } from './styles';

interface ResponseTimeProps {
  responseTimeRate: number;
}

export const ResponseTime = ({ responseTimeRate = 0 }: ResponseTimeProps) => {
  const classes = useStyles();
  const formattedResponseTime = getParsedMinutesAndSeconds(responseTimeRate);
  const isGoodResponseTime = responseTimeRate <= FIVE_MINUTES_IN_SECONDS;
  const classToUse = isGoodResponseTime ? classes.goodResponseTime : classes.badResponseTime;

  return <span className={classToUse}>{formattedResponseTime}</span>;
};
