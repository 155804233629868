import React from 'react';
import * as _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import { EditableList } from '../components/EditableList';
import { IJob, IPay } from '../../IJob';
import { JobsRepository } from '../../JobsRepository';
import { IProvider } from '../../../Providers/Provider.interface';
import { currencyFormat } from '../../../helpers/formatHelpers';
import { FullNameField } from '../../../components/FullNameField';
import { useTabStyles } from '../../../styles/commonStyles';
import { useStyles } from './styles';
import { PayItem } from './PayItem';
import { PayForm } from './PayForm';
import {
  getProviderFullName,
  getProviderStatus,
  isProPlusAchieved,
} from '../../../helpers/providerHelpers';

export interface PayTabsProps {
  job: IJob;
  provider: IProvider | undefined;
  isJobLocked: boolean;
}

export const PayoutTab: React.FC<PayTabsProps> = ({ job, provider, isJobLocked }) => {
  const classes = useStyles();
  const tabClasses = useTabStyles();

  if (!provider)
    return (
      <Box p={3}>
        <Typography variant="body2" className={tabClasses.italic}>
          Payout will not show until a provider is assigned.
        </Typography>
      </Box>
    );

  const pays = _.sortBy(job.pays, 'createdate');
  const jobId = job.id;
  const jobsRepository = JobsRepository.getRepository();

  const createPay = async (formData: IPay) => {
    const editedPays = [
      ...pays,
      {
        ...formData,
        createdate: new Date().getTime(),
      },
    ];

    await jobsRepository.updateJob(jobId, { pays: editedPays });
  };

  const updatePay = async (formData: IPay, id: number) => {
    const editedPays = pays.map((pay, index) =>
      index === id
        ? {
            ...pay,
            ...formData,
          }
        : pay,
    );
    await jobsRepository.updateJob(jobId, { pays: editedPays });
  };

  const deletePay = async (deletedPeriodId: number) => {
    const editedPays = pays.filter((pay, index) => index !== deletedPeriodId);
    await jobsRepository.updateJob(jobId, { pays: editedPays });
  };

  const getTotalPayout = (pays: IPay[]) => {
    return _.sumBy(pays, pay => pay.amount * pay.quantity);
  };

  return (
    <Box className={tabClasses.listWrapper}>
      <FullNameField
        withProBadge={false}
        className={classes.avatar}
        providerFullName={getProviderFullName(provider)}
        providerStatus={getProviderStatus(provider.live, provider.onJob)}
        isProviderProPlus={isProPlusAchieved(provider)}
      />
      <Typography variant="body2" className={classes.payoutHead}>
        <span>Total Payout</span>
        <span className={classes.totalPayout}>{currencyFormat(getTotalPayout(pays))}</span>
      </Typography>
      <EditableList
        data={pays}
        createForm={(props: { onCancelClick: () => void; id: number }) => (
          <PayForm handleSubmit={createPay} {...props} />
        )}
        editForm={({ data, ...props }: { onCancelClick: () => void; data: IPay; id: number }) => (
          <PayForm handleSubmit={updatePay} data={data} {...props} />
        )}
        onDelete={deletePay}
        deleteModal={(item: IPay) => ({
          title: 'Delete pay?',
          confirmText: 'Delete',
          content: (
            <Typography>
              Remove pay for <span className={tabClasses.semiBoldText}>{item.description}</span> ?
            </Typography>
          ),
        })}
        row={({ item }: { item: IPay }) => <PayItem item={item} />}
        addItemButtonLabel="Add pay"
        isReadOnly={isJobLocked}
      />
    </Box>
  );
};
