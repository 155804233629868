import * as _ from 'lodash';
import { COLLECTIONS } from '../utils';
import { useDatabase } from '../DatabaseProvider';
import { IPayment } from './IJob';
import { PaymentStatus } from './JobUtils';

export interface IPaymentsRepository {
  getPayment(paymentId: string): Promise<IPayment>;
  getAllPaymentsForJob(jobId: string): Promise<IPayment[]>;
  getSucceededPaymentsForJob(jobId: string): Promise<IPayment[]>;
  getTotalPaymentForJob(jobId: string): Promise<number>;
  createPayment(data: Partial<IPayment>): Promise<void>;
  deletePayment(paymentId: string): Promise<void>;
  subscribeToJobPayments(jobId: string, setPayments: any): any;
}

export class PaymentsRepository implements IPaymentsRepository {
  private static instance: IPaymentsRepository | undefined;
  private database = useDatabase().database;

  static getRepository(): IPaymentsRepository {
    if (!this.instance) {
      this.instance = new PaymentsRepository();
    }
    return this.instance;
  }

  async getPayment(paymentId: string): Promise<IPayment> {
    const payment = await this.database.collection(COLLECTIONS.PAYMENTS).doc(paymentId).get();
    return payment.data() as IPayment;
  }

  async getAllPaymentsForJob(jobId: string): Promise<IPayment[]> {
    const paymentsDocs = await this.database
      .collection(COLLECTIONS.PAYMENTS)
      .where('jobId', '==', jobId)
      .orderBy('createdate', 'desc')
      .get();

    return paymentsDocs.docs.map(
      paymentDoc => ({ ...paymentDoc.data(), id: paymentDoc.id } as IPayment),
    );
  }

  async getSucceededPaymentsForJob(jobId: string): Promise<IPayment[]> {
    const paymentsDocs = await this.database
      .collection(COLLECTIONS.PAYMENTS)
      .where('jobId', '==', jobId)
      .where('status', '==', PaymentStatus.SUCCEEDED)
      .get();

    return paymentsDocs.docs.map(
      paymentDoc => ({ ...paymentDoc.data(), id: paymentDoc.id } as IPayment),
    );
  }

  async getTotalPaymentForJob(jobId: string): Promise<number> {
    const jobPayments = await this.getSucceededPaymentsForJob(jobId);

    return _.sumBy(jobPayments, 'amount');
  }

  async createPayment(data: Partial<IPayment>): Promise<void> {
    await this.database.collection(COLLECTIONS.PAYMENTS).add(data);
  }

  async deletePayment(paymentId: string): Promise<void> {
    await this.database.collection(COLLECTIONS.PAYMENTS).doc(paymentId).delete();
  }

  subscribeToJobPayments(jobId: string, setPayments: any): any {
    return this.database
      .collection(COLLECTIONS.PAYMENTS)
      .where('jobId', '==', jobId)
      .orderBy('createdate', 'desc')
      .onSnapshot(function (querySnapshot) {
        const payments = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })) as IPayment[];

        setPayments(payments);
      });
  }
}
