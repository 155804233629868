import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import { colors, font } from '../../styles';

export const PendingReviewChip = ({ fontSize = 12 }: { fontSize?: number }) => {
  const classes = useStyles();
  return <Chip className={classes.root} label="PR" style={{ fontSize }} />;
};

export const useStyles = makeStyles(({ spacing }) => ({
  root: {
    background: colors.sunshade,
    fontSize: font.size.regular,
    height: '24px',
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5),
  },
}));
