import React, { useState, useMemo } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { ProviderMarkerDetails } from './ProviderMarkerDetails';
import { ProviderMarker } from './ProviderMarker';
import { JobMarkerDetails } from './JobMarkerDetails';
import { useMaps } from '../contexts/MapsContext';
import { useProvidersLocations } from '../contexts/ProvidersLocationsContext';
import { Loader } from '../components/Loader';

export const mapContainerStyle = {
  flexGrow: 1,
};

interface ServiceAreaMapProps {
  jobs: any[];
  zoom: number;
  center: { lat: number; lng: number };
}

export const ServiceAreaMap = ({ jobs, zoom, center }: ServiceAreaMapProps) => {
  const { isLoading: isLoadingMaps, loadError, onMapLoad } = useMaps();
  const { locations, isLoadingLocations } = useProvidersLocations();

  const isLoading = isLoadingLocations || isLoadingMaps;

  const [selectedProvider, setSelectedProvider] = useState<any>(null);
  const [selectedJob, setSelectedJob] = useState<any>(null);
  const options = useMemo(
    () => ({
      zoom,
      center,
      scaleControl: true,
    }),
    [zoom, center],
  );

  if (loadError) return <>An error occured when loading maps</>;
  if (isLoading) return <Loader text="Loading maps..." />;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      onLoad={onMapLoad}
      options={options}
      onClick={() => {
        setSelectedProvider(null);
        setSelectedJob(null);
      }}
    >
      {Object.keys(locations).map(providerId => {
        return (
          <ProviderMarker
            key={providerId}
            location={locations[providerId]}
            onClick={() => setSelectedProvider(providerId)}
          />
        );
      })}
      {Object.values(jobs).map(job => {
        return (
          <JobMarkerDetails
            key={job.id}
            job={job}
            onClick={() => setSelectedJob(job)}
            selectedJob={selectedJob}
          />
        );
      })}
      {selectedProvider && <ProviderMarkerDetails provider={locations[selectedProvider]} />}
    </GoogleMap>
  );
};
