export function supressConsoleWarnings() {
  const excludedPatterns = [
    /To locate the bad setState/i,
    /Warning: React does not recognize the .* prop on a DOM element./im,
    /Warning: Received .* for a non-boolean attribute/im,
    /Unknown event handler property .*. It will be ignored/im,
  ];

  const originalConsoleError = console.error;

  console.error = function () {
    const errorMessage = arguments[0];

    if (typeof errorMessage !== 'string') {
      originalConsoleError.apply(console, arguments as any);
      return;
    }

    !excludedPatterns.some(msg => msg.test(errorMessage)) &&
      originalConsoleError.apply(console, arguments as any);
  };
}
