import { useState, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { IServiceArea } from '../../Jobs/IJob';

export const useServiceAreas = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serviceAreas, setServiceAreas] = useState<IServiceArea[]>([]);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    const getServiceAreas = async () => {
      try {
        const response = await dataProvider.getList('serviceAreas', {
          pagination: { page: 1, perPage: 100 },
          filter: { active: true },
        });
        if (response.data?.length) setServiceAreas(response.data as IServiceArea[]);
      } catch (error) {
        notify('ra.message.error', 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getServiceAreas();
  }, [dataProvider, notify]);

  return { serviceAreas, isLoadingAreas: isLoading };
};
