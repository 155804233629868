import { useState, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { IService } from '../../Jobs/IJob';

export const useServices = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [services, setServices] = useState<IService[]>([]);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    const getServices = async () => {
      try {
        const response = await dataProvider.getList('services', {
          pagination: { page: 1, perPage: 100 },
          filter: { active: true },
        });
        if (response.data?.length) setServices(response.data as IService[]);
      } catch (error) {
        notify('ra.message.error', 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    void getServices();
  }, [dataProvider, notify]);

  return { services, isLoadingAreas: isLoading };
};
