import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme';

export const useStyles = makeStyles(() => ({
  table: {
    '& td.MuiTableCell-root': {
      '&:nth-child(2)': {
        paddingLeft: theme.spacing(1),
      },
    },
    '& th.MuiTableCell-head': {
      '&:nth-child(2)': {
        paddingLeft: theme.spacing(2),
        paddingRight: '20px',
      },
      '&:nth-child(3)': {
        paddingRight: '54px',
      },
      '&:nth-child(4)': {
        paddingRight: '20px',
      },
      '&:nth-child(5)': {
        paddingRight: '40px',
      },
      '&:nth-child(6)': {
        paddingRight: '20px',
      },
      '&:nth-child(7)': {
        paddingRight: '20px',
      },
      '&:nth-child(8)': {
        paddingRight: '20px',
      },
      '&:nth-child(9)': {
        paddingRight: '20px',
      },
    },
  },
  tableSmall: {
    '& th.MuiTableCell-head': {
      '&:nth-child(2)': {
        paddingLeft: theme.spacing(2),
        paddingRight: '100px',
      },
      '&:nth-child(3)': {
        paddingRight: '30px',
      },
      '&:nth-child(4)': {
        paddingRight: '40px',
      },
      '&:nth-child(5)': {
        paddingRight: '20px',
      },
      '&:nth-child(6)': {
        paddingRight: 0,
      },
      '&:nth-child(7)': {
        paddingRight: 0,
      },
      '&:nth-child(8)': {
        paddingRight: 0,
      },
      '&:nth-child(9)': {
        paddingRight: '20px',
      },
    },
  },
}));
