import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
  },
  nameHeader: {
    paddingRight: theme.spacing(25),
  },
  alignment: {
    textAlign: 'end',
  },
  state: {
    paddingRight: theme.spacing(30),
  },
  tabs: {
    backgroundColor: theme.palette.common.white,
  },
}));
