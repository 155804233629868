import React from 'react';
import { NumberInput } from 'react-admin';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { DollarIcon } from '../Icons';
import { greaterThanOrEqualZero, required, typeOfNumber } from '../../helpers/validationHelpers';
import { InputWithLabel } from '../InputWithLabel';

interface ICurrencyInput {
  source?: string;
  label?: string;
  value?: number;
  className?: string;
  onChange?: (event: React.ChangeEvent<{ value: string }>) => void;
  defaultValue?: number;
  allowNegative?: boolean;
  validation?: boolean;
  disabled?: boolean;
}

export const CurrencyInput: React.FC<ICurrencyInput> = ({
  source = '',
  label,
  value,
  className,
  defaultValue,
  allowNegative,
  validation = true,
  onChange,
  disabled,
  ...props
}) => {
  const classes = useStyles();

  const formatToMaxTwoDigitsAfterDecimal = (value: string): string =>
    value.replace(/^(\d+.?\d{0,2})\d*$/, '$1');
  return (
    <InputWithLabel label={label} value={value} className={className} validation={validation}>
      <NumberInput
        source={source}
        validate={validation && [required, typeOfNumber, allowNegative || greaterThanOrEqualZero]}
        placeholder="0.00"
        step={0.5}
        autoComplete="off"
        defaultValue={defaultValue}
        parse={(value: string) => {
          if (!value) return value;
          return +formatToMaxTwoDigitsAfterDecimal(value);
        }}
        InputProps={{
          className: classes.root,
          startAdornment: (
            <InputAdornment position="start">
              <DollarIcon className={classes.icon} />
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        onChange={(e: any) => {
          e.target.value = e.target.value
            ? formatToMaxTwoDigitsAfterDecimal(e.target.value)
            : e.target.value;
          onChange && onChange(e);
        }}
        {...props}
      />
    </InputWithLabel>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  root: {
    '& div:first-child': {
      marginTop: '0px !important',
    },
  },
  icon: {
    color: palette.primary.main,
  },
}));
