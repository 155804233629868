import React from 'react';
import { colors } from '../../styles';
import classnames from 'classnames';
import { useButtonStyles } from '../../styles/commonStyles';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonProps } from './';

export const CancelButton = (props: ButtonProps) => {
  const defaultBtnClasses = useButtonStyles();
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classnames(defaultBtnClasses.defaultButton, classes.cancelBtn)}
      startIcon={props.startIcon}
      {...props}
    >
      {props.children || 'Cancel'}
    </Button>
  );
};

const useStyles = makeStyles(() => ({
  cancelBtn: {
    background: colors.bittersweet,
    '&:hover': {
      background: colors.red,
    },
  },
}));
