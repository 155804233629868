import { Chip } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { JobStatus, statuses } from '../../Jobs/JobUtils';

const JobStatusChip = ({ status, fontSize = 12 }: { status: JobStatus; fontSize?: number }) => {
  const classes = useStyles();
  const statusOption = statuses.find(s => s.id === status);

  return (
    <Chip
      label={statusOption?.name}
      className={classes.root}
      style={{
        background: statusOption?.color,
        fontSize: `${fontSize}px`,
      }}
    />
  );
};

const useStyles = makeStyles(() => ({
  root: {
    height: '24px',
  },
}));

export default JobStatusChip;
