import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme';

export const useStyles = makeStyles(() => ({
  iterator: {
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      '& li': {
        borderBottom: 0,
        alignItems: 'center',
        '& p': {
          display: 'none',
        },
        '& section .ra-input div:first-child': {
          height: 'auto',
        },
      },
      '& li:last-child': {
        order: -1,
        marginTop: '-85px',
        marginBottom: theme.spacing(2),
        width: '100%',
        justifyContent: 'flex-end',
      },
    },
  },
  disclaimer: {
    '& div.MuiInputBase-root': {
      height: '76px',
      paddingTop: theme.spacing(1),
      alignItems: 'flex-start',
    },
  },
  iconSelect: {
    flexGrow: 1,
  },
}));
