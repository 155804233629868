import React from 'react';
import { Box } from '@material-ui/core';

export const InlineFieldsWrapper = ({
  children,
  flexBasis = 45,
  wrap = 'wrap',
  className,
}: {
  children: any;
  flexBasis?: number;
  wrap?: string;
  className?: string;
}) => (
  <Box display="flex" mx={-1} flexWrap={wrap} className={className}>
    {children.map((child: any, index: number) => (
      <Box flex={`1 1 ${flexBasis}%`} mx={1} key={`inlineField${index}`}>
        {child}
      </Box>
    ))}
  </Box>
);
