import { useDatabase } from '../DatabaseProvider';
import { COLLECTIONS } from '../utils';
import { IProvider } from '../Providers/Provider.interface';

export interface IProvidersRepository {
  getProvider(providerId: string): Promise<IProvider>;
  getAllProviders(): Promise<IProvider[]>;
}

export class ProvidersRepository implements IProvidersRepository {
  private database = useDatabase().database;
  private static instance: IProvidersRepository | undefined;

  static getRepository(): IProvidersRepository {
    if (!this.instance) {
      this.instance = new ProvidersRepository();
    }
    return this.instance;
  }

  async getProvider(providerId: string): Promise<IProvider> {
    const provider = await this.database.collection(COLLECTIONS.PROVIDERS).doc(providerId).get();
    return provider.data() as IProvider;
  }

  async getAllProviders(): Promise<IProvider[]> {
    const providersDocs = await this.database.collection(COLLECTIONS.PROVIDERS).get();

    return providersDocs.docs.map(
      providerDoc => ({ ...providerDoc.data(), uid: providerDoc.id } as IProvider),
    );
  }
}
