import React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import BackButton from './Buttons/BackButton';
import theme from '../styles/theme';
import { font } from '../styles';

export const SingleResourceHeader = ({
  singular,
  isCreate,
}: {
  singular: string;
  isCreate?: boolean;
}) => (
  <Box
    pt="52px"
    pl="24px"
    pb="20"
    display="flex"
    alignItems="center"
    style={{ backgroundColor: theme.palette.common.white }}
  >
    <BackButton style={{ padding: 0 }}>
      <IconButton aria-label="go back">
        <ArrowBack color="primary" style={{ border: '2px solid #00CC66', borderRadius: '100%' }} />
      </IconButton>
    </BackButton>
    <Typography variant="h5" component="h3" style={{ fontWeight: font.weight.bold }}>
      {(isCreate ? 'New ' : 'Edit ') + singular}
    </Typography>
  </Box>
);
