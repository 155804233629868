import React, { useEffect } from 'react';
import { useNotify } from 'react-admin';
import { Field } from 'react-final-form';
import usePlacesAutocomplete, { getGeocode, Suggestion } from 'use-places-autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMaps } from '../contexts/MapsContext';
import { useLocationUpdate } from '../utils/hooks';
import theme from '../styles/theme';
import { usaCoordinates } from '../Map/mapUtils';
import { deserializeGeocode } from '../utils/deserializeGeocode';

export const useStyles = makeStyles(() => ({
  inputRoot: {
    paddingTop: '0 !important',
  },
}));
export const milesToMeters = (miles: number) => miles * 1609;

const requestOptions = {
  location: {
    lat: () => parseInt(usaCoordinates.lat),
    lng: () => parseInt(usaCoordinates.long),
  } as google.maps.LatLng,
  radius: milesToMeters(500),
};

export interface FormFieldProps {
  source: string;
}

export const AddressField: React.FC<FormFieldProps> = ({ source }) => {
  const { isLoading, loadError } = useMaps();

  if (loadError) return <>An error occured when loading places autocomplete</>;
  if (isLoading) return <>Loading places autocomplete...</>;

  return <AddressFieldBase source={source} />;
};

export const AddressFieldBase: React.FC<FormFieldProps> = ({ source }) => {
  const classes = useStyles();
  const updateLocationFields = useLocationUpdate();
  const notify = useNotify();
  const { address, setAddress, setCoordinates, coordinates } = useMaps();

  const {
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions,
  });

  useEffect(() => {
    if (!!address.value) {
      updateLocationFields({
        address: address.value,
        type: address.type,
        state: address.state,
        city: address.city,
        ...coordinates,
      });
    }
  }, [address, updateLocationFields, coordinates]);

  return (
    <Field name={source}>
      {({ input, meta: { touched, error } }) => {
        const handleChange = async (_event: React.ChangeEvent<{}>, newValue: Suggestion) => {
          if (!newValue) {
            input.onChange('');
            setCoordinates(usaCoordinates);
            setAddress({ value: '', type: 'continent' });
            return;
          }
          input.onChange(newValue.description);

          try {
            const results = await getGeocode({
              address: newValue.description,
            });
            if (!results) return;

            const { city, state, latLongCoordinates } = await deserializeGeocode(results);

            updateLocationFields({
              address: newValue.description,
              type: address.type,
              state,
              city,
              ...latLongCoordinates,
            });
            setCoordinates(latLongCoordinates);
            setAddress({
              value: newValue.description,
              type: newValue.types[0],
              state,
              city,
            });
          } catch (error) {
            notify('ra.message.error', 'error');
            console.error(error);
          }
        };

        return (
          <Autocomplete
            {...input}
            getOptionLabel={option => (option?.description ? option.description : option)}
            getOptionSelected={option => (option?.description ? option.description : option)}
            filterOptions={option => option}
            options={status === 'OK' ? data : []}
            onChange={handleChange}
            includeInputInList
            autoHighlight
            onInputChange={(e: any) => setValue(e?.target?.value)}
            noOptionsText={value ? 'No options' : 'Start typing...'}
            classes={{ inputRoot: classes.inputRoot }}
            style={{ marginTop: theme.spacing(1) }}
            renderInput={params => (
              <TextField
                {...params}
                label="Address"
                variant="filled"
                helperText={touched && error ? error : <>&#8203;</>}
              />
            )}
          />
        );
      }}
    </Field>
  );
};
