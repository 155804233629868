import React from 'react';
import { providerTypes } from '../Providers/ProviderUtils';
import whiteCarIcon from '../assets/whiteCarIcon.png';
import greenCarIcon from '../assets/greenCarIcon.png';
import redCarIcon from '../assets/redCarIcon.png';
import { Marker } from '@react-google-maps/api';

export const ProviderMarker: React.FC<{
  location: any;
  onClick: () => void;
}> = ({ location, onClick }) => {
  const icon = location.live ? getOnlineCarIcon(location.type) : redCarIcon;

  return (
    <Marker
      position={location}
      icon={{
        url: icon,
        anchor: new google.maps.Point(22, 22),
        scaledSize: new google.maps.Size(45, 45),
      }}
      onClick={onClick}
    />
  );
};

const getOnlineCarIcon = (providerType: number) =>
  providerType === providerTypes[0].id ? whiteCarIcon : greenCarIcon;
