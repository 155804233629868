import { Font, StyleSheet } from '@react-pdf/renderer';
import { colors, font } from '../../styles';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Me5Q.ttf' },
    { src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9vAw.ttf', fontWeight: 500 },
    { src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlvAw.ttf', fontWeight: 700 },
  ],
});

export const styles = StyleSheet.create({
  page: {
    padding: 50,
    fontFamily: 'Roboto',
    fontSize: font.size.small,
    lineHeight: 1.5,
    color: colors.shark,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: colors.geyser,
  },
  logo: {
    width: 65,
    height: 20,
    marginBottom: 16,
  },
  wrapper: {
    marginBottom: 12,
    paddingBottom: 8,
    borderBottomWidth: 1,
    borderBottomColor: colors.geyser,
  },
  lastWrapper: {
    borderBottomWidth: 0,
  },
  column50: {
    width: '50%',
  },
  column25: {
    width: '25%',
    marginBottom: 8,
  },
  column100: {
    width: '100%',
    marginBottom: 8,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: font.size.tiny,
    fontWeight: font.weight.semiBold,
    color: colors.green,
    lineHeight: 1.5,
  },
  subtitle: {
    fontSize: font.size.tiny,
    color: colors.rollingStone,
  },
  smallText: {
    fontSize: font.size.small,
  },
  regularText: {
    fontSize: font.size.regular,
  },
  hugeText: {
    fontSize: font.size.extraLarge,
    fontWeight: font.weight.medium,
    color: colors.black,
  },
  largeText: {
    fontSize: font.size.semiLarge,
  },
  boldText: {
    fontWeight: font.weight.semiBold,
  },
  semiBoldText: {
    fontWeight: font.weight.medium,
  },
  tinyText: {
    fontSize: font.size.tiny,
  },
  rowWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  costWrapper: {
    fontSize: font.size.small,
    marginBottom: 12,
  },
  costBox: {
    flex: 2,
  },
  costDescription: {
    color: colors.rollingStone,
  },
  costDetails: {
    flex: 1,
    color: colors.rollingStone,
    textAlign: 'right',
  },
  costTotal: {
    flex: 1,
    textAlign: 'right',
  },
  commentWrapper: {
    flexDirection: 'row',
    fontSize: font.size.small,
    marginBottom: 8,
  },
  commentTextWrapper: {
    flexGrow: 1,
  },
  commentText: {
    color: colors.rollingStone,
  },
});
