import React from 'react';
import { Box } from '@material-ui/core';
import { Heading } from './Heading';
import { useStyles } from '../ServiceAreas/ServiceAreaList/styles';
import { CreateResourceButton } from './Buttons/CreateResourceButton';
import { ListViewProps } from '../interfaces';

export const ListHeader = (props: ListViewProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column">
      <Box
        pt={6}
        px={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.wrapper}
      >
        <Heading>{props.options.label}</Heading>
        <Box display="flex">
          {props.hasCreate && (
            <CreateResourceButton basePath={props.basePath} singular={props.options.singular} />
          )}
          {props.additionalButtons && props.additionalButtons}
        </Box>
      </Box>
      <Box minHeight="52px" className={classes.tabs}>
        {props.children}
      </Box>
    </Box>
  );
};
