import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

interface LoaderProps {
  text?: string;
}

export const Loader = ({ text = 'Loading' }: LoaderProps) => (
  <Box display="flex" alignItems="center" justifyContent="center" height="100%" width="100%">
    <CircularProgress size={20} />
    <Box ml={1}>{text}</Box>
  </Box>
);
