import React from 'react';
import { Box } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { BillIcon, PayRateIcon, StarIcon, SteeringWheelIcon } from '../../../components/Icons';
import { CurrencyInput } from '../../../components/Inputs/CurrencyInput';
import theme from '../../../styles/theme';
import { SelectInput } from '../../../components/Inputs/SelectInput';
import { providerTypes } from '../../ProviderUtils';
import { InfoBox } from '../../../components/InfoBox';

export const FormFields = () => {
  const { values } = useFormState();

  return (
    <Box display="flex" p={3}>
      <Box display="flex" flexDirection="column">
        <ProviderCard values={values} />
        <BasePayCard values={values} />
        <BonusPayCard values={values} />
      </Box>
      <InfoBox
        title="Paystubs"
        icon={<BillIcon />}
        style={{ flexGrow: 1, marginLeft: theme.spacing(2) }}
      />
    </Box>
  );
};

const ProviderCard = ({ values }: { values: { type?: string } }) => (
  <InfoBox title="Provider" icon={<SteeringWheelIcon />}>
    <Box px={2}>
      <SelectInput
        label="Provider type"
        value={values.type}
        source="type"
        choices={providerTypes}
        defaultValue={0}
      />
    </Box>
  </InfoBox>
);

const BasePayCard = ({ values }: { values: { payRates?: { base: number; callout: number } } }) => (
  <InfoBox title="Base pay" icon={<PayRateIcon />}>
    <Box px={2}>
      <CurrencyInput source="payRates.base" label="Per hour" value={values.payRates?.base} />
      <CurrencyInput
        source="payRates.callout"
        label="Travel (callout)"
        value={values.payRates?.callout}
      />
    </Box>
  </InfoBox>
);

const BonusPayCard = ({
  values,
}: {
  values: {
    payRates?: { afterHour: number; starsBonus: number; proPlusBonus: number };
  };
}) => (
  <InfoBox title="Bonus pay" icon={<StarIcon />}>
    <Box px={2}>
      <CurrencyInput
        source="payRates.afterHour"
        label="Afterhour bonus (per hour)"
        value={values.payRates?.afterHour}
      />
      <CurrencyInput
        source="payRates.starsBonus"
        label="5-star bonus (per hour)"
        value={values.payRates?.starsBonus}
      />
      <CurrencyInput
        source="payRates.proPlusBonus"
        label="Pro+ bonus (per hour)"
        value={values.payRates?.proPlusBonus}
      />
    </Box>
  </InfoBox>
);
