import React from 'react';
import classnames from 'classnames';
import { StarIcon } from '../Icons';
import { Statistic } from './Statistic';
import { useStyles } from './styles';

export const StarRating = ({ value, ...props }: { value: number; className?: any }) => {
  const classes = useStyles();
  const rating = value || 0;

  return (
    <Statistic
      startIcon={
        <StarIcon
          className={classnames(
            classes.icon,
            { [classes.lowRating]: rating < 4.0 },
            { [classes.mediumRating]: rating >= 4.0 && rating < 4.5 },
            { [classes.highRating]: rating >= 4.5 },
          )}
        />
      }
      label={rating.toFixed(2)}
      {...props}
    />
  );
};
