import React from 'react';
import { Box } from '@material-ui/core';
import { BackToButton } from '../components/Buttons/BackToButton';
import { Heading } from '../components/Heading';
import theme from '../styles/theme';

export const FormHeader = ({
  onClick,
  title,
  buttonText,
}: {
  onClick: () => void;
  title: string;
  buttonText: string;
}) => (
  <Box
    display="flex"
    flexDirection="column"
    p={3}
    style={{ background: theme.palette.common.white }}
  >
    <BackToButton onClick={onClick} text={buttonText} />
    <Heading>{title}</Heading>
  </Box>
);
