import React from 'react';
import { TextInput as RATextInput } from 'react-admin';
import { required } from '../../helpers/validationHelpers';
import { InputWithLabel } from '../InputWithLabel';

interface ITextInput {
  source?: string;
  label?: string;
  value?: string;
  placeholder?: string;
  validation?: boolean;
  style?: any;
  rowsMax?: number;
  multiline?: boolean;
  className?: any;
  record?: any;
  type?: string;
  disabled?: boolean;
  onChange?: any;
}

export const TextInput = ({
  source = '',
  label,
  value,
  placeholder,
  validation = true,
  style,
  rowsMax,
  multiline,
  className,
  type,
  record,
  disabled = false,
  ...props
}: ITextInput) => (
  <InputWithLabel
    label={label}
    value={value}
    validation={validation}
    style={style}
    className={className}
    type={type}
  >
    <RATextInput
      source={source}
      validate={validation && required}
      label={placeholder || ''}
      rowsMax={rowsMax}
      multiline={multiline}
      record={record}
      autoComplete="off"
      type={type}
      disabled={disabled}
      {...props}
    />
  </InputWithLabel>
);
