import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme';
import { BORDER_RADIUS, colors } from '../../styles';

export const useDefaultDrawerStyles = makeStyles(() => ({
  root: {
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    minHeight: '100vh',
    backgroundColor: colors.catskillWhite,
  },
  drawerPaper: {
    position: 'fixed',
    minWidth: '80px',
    '-webkit-transition': 'all 0.5s ease',
    backgroundColor: theme.palette.primary.dark,
    zIndex: 3,
  },
}));

export const useOpenDrawerStyles = makeStyles(() => ({
  drawerPaper: {
    position: 'fixed',
    top: 0,
    minWidth: '280px',
    '-webkit-transition': 'all 0.5s ease',
    backgroundColor: theme.palette.primary.dark,
    zIndex: 3,
  },
}));

export const useLayoutStyles = makeStyles(() => ({
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    minHeight: '100vh',
  },
  contentWithSidebar: {
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    minHeight: '100vh',
    background: colors.catskillWhite,
    '& div.MuiPaper-rounded': {
      borderRadius: BORDER_RADIUS,
    },
    '& div.MuiPaper-elevation1': {
      boxShadow: '0 0 black',
    },
    '& div.MuiPaper-root': {
      background: colors.catskillWhite,
    },
  },
  sidebarOpen: {
    backgroundColor: theme.palette.primary.dark,
    width: '280px',
    '-webkit-transition': 'all 0.5s ease',
  },
  sidebarClosed: {
    width: '80px',
  },
  sidebarItems: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100vh',
  },
  toggleButtonOpened: {
    marginLeft: '40px',
    marginTop: '60px',
    marginBottom: '60px',
    display: 'flex',
  },
  toggleButtonClosed: {
    margin: 'auto',
    marginTop: '60px',
    marginBottom: '60px',
    display: 'flex',
  },
  logo: {
    marginRight: '5%',
  },
  logout: {
    display: 'inline',
  },
  pagination: {
    '& .MuiTablePagination-toolbar': {
      paddingRight: 24,
      background: colors.catskillWhite,
      '& .MuiTablePagination-spacer': {
        order: 3,
      },
      '& div:nth-child(5)': {
        order: 4,
      },
      '& .MuiButton-textPrimary': {
        color: colors.grey,
      },
      '& .next-page, & .previous-page': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: BORDER_RADIUS,
        minWidth: 32,
        height: 32,
        '& > span': {
          fontSize: 0,
        },
      },
    },
  },
}));
