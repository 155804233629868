import React, { createContext, useCallback, useEffect, useState } from 'react';
import { findServiceArea, parseToLatLngLiteral, useServiceAreas } from '../utils';
import { useDatabase } from '../DatabaseProvider';
import { IProvider } from '../Providers/Provider.interface';
import { IProviderLocation } from '../Map/Map.interface';
import * as _ from 'lodash';
import { IServiceArea } from '../../../types';
import { formatDateWithTime } from '../helpers/timeHelpers';

export interface ILocationHashMap {
  [providerId: string]: IProviderLocation;
}

export interface IProvidersLocationsContext {
  locations: ILocationHashMap;
  isLoadingLocations: boolean;
  getProvidersFromArea: (serviceArea: IServiceArea) => IProviderLocation[];
}

export const ProvidersLocationsContext = createContext<IProvidersLocationsContext | null>(null);

export const ProvidersLocationsContextProvider: React.FC = ({ children }) => {
  const [locations, setLocations] = useState<ILocationHashMap>({});
  const [isLoadingLocations, setLoadingLocations] = useState(true);
  const { database } = useDatabase();
  const { serviceAreas, isLoadingAreas } = useServiceAreas();
  const isLoading = isLoadingAreas || isLoadingLocations;

  const updateProviderMarker = useCallback((providerId: string, lastLocation: ILocation) => {
    setLocations(prevLocations => ({
      ...prevLocations,
      [providerId]: {
        ...prevLocations[providerId],
        ...parseToLatLngLiteral(lastLocation),
        ...lastLocation,
      },
    }));
  }, []);

  const setProviderMarker = useCallback((provider: IProvider, lastLocation: ILocation) => {
    setLocations(prevLocations => ({
      ...prevLocations,
      [provider.uid]: {
        ...provider,
        ...parseToLatLngLiteral(lastLocation),
        ...lastLocation,
      },
    }));
  }, []);

  useEffect(() => {
    const listeners: Array<() => void> = [];
    const subscribeToProviderStatusChanges = () => {
      const unsubscribeFromProvidersListener = database
        .collection('providers')
        .where('disabled', '==', false)
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(async change => {
            const provider = change.doc.data() as IProvider;
            try {
              const lastLocationRef = await database
                .collection('locations')
                .where('providerId', '==', provider.uid)
                .orderBy('createdate', 'desc')
                .limit(1)
                .get();

              const lastLocation = lastLocationRef.docs[0]?.data() as ILocation;
              if (lastLocation) setProviderMarker(provider, lastLocation);
            } catch (error) {
              console.error(error);
            } finally {
              setLoadingLocations(false);
            }
          });
        });

      setLoadingLocations(false);
      listeners.push(unsubscribeFromProvidersListener);
    };

    const subscribeToLocationUpdates = () => {
      const now = formatDateWithTime(new Date());

      const unsubscribeFromLocationsListener = database
        .collection('locations')
        .where('createdate', '>=', now)
        .orderBy('createdate', 'desc')
        .limit(1)
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            if (change.type !== 'added') return;
            if (change.doc.metadata.hasPendingWrites) return;

            const location = change.doc.data() as ILocation;
            updateProviderMarker(location.providerId, location);
          });
        });

      listeners.push(unsubscribeFromLocationsListener);
    };

    subscribeToProviderStatusChanges();
    subscribeToLocationUpdates();

    return () => {
      listeners.forEach(unsubscribeFunc => unsubscribeFunc());
    };
  }, [database, setProviderMarker, updateProviderMarker]);

  const getProvidersFromArea = (serviceArea: IServiceArea) =>
    _.filter(locations, (provider: IProviderLocation) => {
      const providerServiceArea = findServiceArea(
        { lat: provider.lat, long: provider.lng },
        serviceAreas,
      );
      return providerServiceArea && serviceArea
        ? _.isEqual(providerServiceArea.epicenter, serviceArea.epicenter)
        : false;
    });

  return (
    <ProvidersLocationsContext.Provider
      value={{
        locations,
        isLoadingLocations: isLoading,
        getProvidersFromArea,
      }}
    >
      {children}
    </ProvidersLocationsContext.Provider>
  );
};

export const useProvidersLocations = () => {
  const context = React.useContext(ProvidersLocationsContext);
  if (!context) throw new Error('useProvidersLocations must be used within a ProvidersLocations.');

  return context;
};
