import { useCallback, useRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];

export const useLoadMap = (): {
  isMapLoading: boolean;
  mapLoadError?: Error;
  onMapLoad: (map: google.maps.Map) => void;
} => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  return {
    isMapLoading: !isLoaded,
    mapLoadError: loadError,
    onMapLoad,
  };
};
