export const calcDistanceInMiles = (
  origin: { lat: number; long: number },
  destination: { lat: number; long: number },
) => {
  const EARTH_RADIUS_IN_MILES = 3958.8;
  const radianLat = degreesToRadians(destination.lat - origin.lat);
  const radianLong = degreesToRadians(destination.long - origin.long);

  const a =
    Math.sin(radianLat / 2) * Math.sin(radianLat / 2) +
    Math.cos(degreesToRadians(origin.lat)) *
      Math.cos(degreesToRadians(destination.lat)) *
      Math.sin(radianLong / 2) *
      Math.sin(radianLong / 2);
  const angularDistanceInRadians = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return EARTH_RADIUS_IN_MILES * angularDistanceInRadians;

  function degreesToRadians(deg: number) {
    return deg * (Math.PI / 180);
  }
};
