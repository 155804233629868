export const colors = {
  jade: '#00B55B',
  green: '#00CC66',
  tara: '#00cc661a',
  blue: '#2196f3',
  grey: '#B2B8C4',
  lightGrey: '#ffffff85',
  black: '#000',
  navy: '#3f51b5',
  catskillWhite: '#F2F4F8',
  red: '#D42027',
  orange: '#E7772C',
  texasRose: '#FEC44F',
  frostedMint: '#E9FFF4',
  geyser: '#DCE1E7',
  bittersweet: '#FF6960',
  bittersweetLight: '#FF696019',
  rollingStone: '#747B83',
  white: '#FFFFFF',
  shark: '#23282E',
  paleSky: '#6B7280',
  bostonBlue: '#3182BD',
  mossGreen: '#A1D99B',
  lightGreen: '#00CC6619',
  zest: '#E7772C',
  sunshade: '#FF9F2E',
  funGreen: '#008c46',
  whiteIce: '#e5faf0',
  sparklingLightGreen: '#00CC66B3',
  fadingLightGreen: '#00CC6699',
};

export const font = {
  size: {
    tiny: 8,
    small: 10,
    regular: 12,
    default: 14,
    medium: 16,
    semiLarge: 18,
    large: 20,
    extraLarge: 32,
    huge: 40,
  },

  family: {
    primary: 'Roboto, sans-serif',
  },

  weight: {
    light: 400,
    medium: 500,
    bold: 600,
    semiBold: 700,
    extraBold: 900,
  },
};

export const BORDER_RADIUS = 0;
