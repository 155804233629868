export const blobToBase64 = (blob: Blob): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      const base64String = reader.result as string;
      var base64 = base64String.split(',')[1];
      resolve(base64);
    };
  });
};
