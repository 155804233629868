import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
// @ts-ignore
import { Notification, Sidebar, setSidebarVisibility } from 'react-admin';
import classnames from 'classnames';
import { ILayoutProps } from './ILayout.interface';
import { useDefaultDrawerStyles, useLayoutStyles, useOpenDrawerStyles } from './styles';
import { ToggleMenuButton } from '../Buttons/ToggleMenuButton';
import Menu from '../Menu';
import theme from '../../styles/theme';
import { Logo } from '../Icons';

export const Layout = (props: ILayoutProps) => {
  const layoutClasses = useLayoutStyles();
  const openDrawerClasses = useOpenDrawerStyles();
  const defaultClasses = useDefaultDrawerStyles();
  const dispatch = useDispatch();
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <div className={defaultClasses.root}>
        <div className={layoutClasses.appFrame}>
          <main className={layoutClasses.contentWithSidebar}>
            <Sidebar
              classes={open ? openDrawerClasses : defaultClasses}
              className={classnames(layoutClasses.sidebarOpen, {
                [layoutClasses.sidebarClosed]: !open,
              })}
            >
              <div className={layoutClasses.sidebarItems}>
                <div
                  className={
                    open ? layoutClasses.toggleButtonOpened : layoutClasses.toggleButtonClosed
                  }
                >
                  {open && <Logo className={layoutClasses.logo} />}
                  <ToggleMenuButton />
                </div>
                <Menu />
              </div>
            </Sidebar>
            <div className={classnames(layoutClasses.content, layoutClasses.pagination)}>
              {props.children}
            </div>
          </main>
          <Notification />
        </div>
      </div>
    </ThemeProvider>
  );
};
