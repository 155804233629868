import React from 'react';
import { Admin, Resource } from 'react-admin';

import { JobCreate } from './Jobs/JobCreate';
import { JobEdit } from './Jobs/JobEdit';
import { ProviderList } from './Providers/ProviderList';
import { ServiceCreate } from './Services/ServiceCreate';
import { ServiceEdit } from './Services/ServiceEdit';
import { ServiceList } from './Services/ServiceList';
import { ProviderEdit } from './Providers/ProviderEdit';
import { Map } from './Map';
import { useDatabase } from './DatabaseProvider';
import { COLLECTIONS } from './utils';
import { Layout } from './components/Layout';
import { LoginPage } from './components/LoginPage';
import {
  JobsIcon,
  MapIcon,
  ProvidersIcon,
  ServicesIcon,
  ServiceAreaIcon,
  CompanyIcon,
  CardIcon,
} from './components/Icons';
import { ServiceAreaList } from './ServiceAreas/ServiceAreaList';
import { ServiceAreaCreate } from './ServiceAreas/ServiceAreaCreate';
import { ServiceAreaEdit } from './ServiceAreas/ServiceAreaEdit';
import theme from './styles/theme';
import { ProviderCreate } from './Providers/ProviderCreate';
import { CompanyListPage } from './Companies/pages/CompanyListPage';
import { CreateCompanyPage } from './Companies/pages/CreateCompanyPage';
import { EditCompanyPage } from './Companies/pages/EditCompanyPage';
import { CardTransactionListPage } from './CardTransactions/pages/CardTransactionListPage';
import { JobListPage } from './Jobs/JobList/page/JobListPage';

export function App() {
  const { dataProvider, authProvider } = useDatabase();

  return (
    <Admin
      layout={Layout}
      loginPage={LoginPage}
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={theme}
    >
      <Resource name={COLLECTIONS.LOCATIONS} list={Map} options={{ label: 'Map' }} icon={MapIcon} />
      <Resource
        name={COLLECTIONS.JOBS}
        list={JobListPage}
        edit={JobEdit}
        create={JobCreate}
        options={{ label: 'Jobs', singular: 'Job' }}
        icon={JobsIcon}
      />
      <Resource
        name={COLLECTIONS.PROVIDERS}
        list={ProviderList}
        edit={ProviderEdit}
        create={ProviderCreate}
        options={{ label: 'Providers', singular: 'Provider' }}
        icon={ProvidersIcon}
      />
      <Resource
        name={COLLECTIONS.SERVICES}
        list={ServiceList}
        edit={ServiceEdit}
        create={ServiceCreate}
        options={{ label: 'Services', singular: 'Service' }}
        icon={ServicesIcon}
      />
      <Resource
        name={COLLECTIONS.SERVICE_AREAS}
        list={ServiceAreaList}
        create={ServiceAreaCreate}
        edit={ServiceAreaEdit}
        options={{ label: 'Service Areas', singular: 'Service Area' }}
        icon={ServiceAreaIcon}
      />
      <Resource
        name={COLLECTIONS.COMPANIES}
        list={CompanyListPage}
        create={CreateCompanyPage}
        edit={EditCompanyPage}
        options={{ label: 'Companies', singular: 'Company' }}
        icon={CompanyIcon}
      />
      <Resource
        name={COLLECTIONS.CARD_TRANSACTIONS}
        list={CardTransactionListPage}
        options={{ label: 'Card Transactions', singular: 'Card Transaction' }}
        icon={CardIcon}
      />
      <Resource name={COLLECTIONS.ACTIVITIES} />
    </Admin>
  );
}
