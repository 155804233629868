import React from 'react';
import {
  CircularProgress,
  Button as MaterialButton,
  ButtonProps as MaterialButtonProps,
} from '@material-ui/core';
// @ts-ignore

export interface ButtonProps extends MaterialButtonProps {
  isProcessing?: boolean;
}

export const Button = ({ isProcessing, children, ...props }: ButtonProps) => {
  return (
    <MaterialButton {...props}>
      {isProcessing ? <CircularProgress size={20} color="inherit" /> : children}
    </MaterialButton>
  );
};
