import { InputWithLabel } from '../InputWithLabel';
import { TextInput as RATextInput } from 'react-admin';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme';
import { required } from '../../helpers/validationHelpers';

interface IMultilineTextInput {
  source?: string;
  label?: string;
  placeholder: string;
  validation?: boolean;
  value?: string;
  className?: any;
  onChange?: any;
}

export const MultilineTextInput = ({
  source = '',
  label,
  placeholder,
  validation = true,
  value,
  className,
  ...props
}: IMultilineTextInput) => {
  const classes = useStyles();

  return (
    <InputWithLabel label={label} validation={validation} value={value} className={className}>
      <RATextInput
        source={source}
        label={placeholder}
        validate={validation && required}
        className={classes.root}
        multiline
        rowsMax={3}
        validation={validation}
        {...props}
      />
    </InputWithLabel>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    '& div.MuiInputBase-root': {
      paddingTop: theme.spacing(1),
      alignItems: 'flex-start',
    },
  },
}));
