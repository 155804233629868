import React from 'react';
import classnames from 'classnames';
import { StreakIcon } from '../Icons';
import { Statistic } from './Statistic';
import { useStyles } from './styles';

export const StreakRating = ({ value, ...props }: { value: number; className?: any }) => {
  const classes = useStyles();
  const rate = value || 0;

  return (
    <Statistic
      startIcon={
        <StreakIcon
          className={classnames(
            { [classes.lowStreak]: rate < 3 },
            { [classes.mediumStreak]: rate >= 3 && rate < 6 },
            { [classes.highStreak]: rate >= 6 },
          )}
        />
      }
      label={rate}
      {...props}
    />
  );
};
