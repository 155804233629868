import React from 'react';
import { InputWithLabel } from './InputWithLabel';
import { getProviderFullName } from '../helpers/providerHelpers';
import { IProvider } from '../Providers/Provider.interface';
import { UserAvatar } from './UserAvatar';

interface ITextInput {
  label?: string;
  value?: string;
  firstName: string;
  lastName: string;
}

export const AvatarField = ({ label, firstName, lastName }: ITextInput) => (
  <InputWithLabel label={label} validation={false}>
    <UserAvatar
      status="offline"
      width={96}
      name={getProviderFullName({
        firstName: firstName,
        lastName: lastName,
      } as IProvider)}
    />
  </InputWithLabel>
);
