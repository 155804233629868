import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { IProvider } from '../../Provider.interface';
import { InfoBox } from '../../../components/InfoBox';
import { ActivityIcon } from '../../../components/Icons';
import theme from '../../../styles/theme';
import { JobsRepository } from '../../../Jobs/JobsRepository';
import { getProviderBalance } from '../../../helpers/providerHelpers';
import { IJob, IPayout } from '../../../Jobs/IJob';
import { PayoutsRepository } from '../../../Jobs/PayoutsRepository';
import { currencyFormat } from '../../../helpers/formatHelpers';
import { formatDate, getDateFromFirestoreTimestamp } from '../../../helpers/timeHelpers';
import { useStyles } from './styles';

export const PayoutsCard = ({ provider }: { provider: IProvider }) => {
  const payoutsRepository = PayoutsRepository.getRepository();
  const [payouts, setPayouts] = React.useState<IPayout[]>([]);

  useEffect(() => {
    const getPayouts = async () => {
      const payouts = await payoutsRepository.getProviderPayouts(provider.uid);
      setPayouts(payouts);
    };

    void getPayouts();
  }, [payoutsRepository, provider.uid]);

  return (
    <InfoBox
      title="Payouts"
      icon={<ActivityIcon />}
      style={{ flexGrow: 1, marginLeft: theme.spacing(2) }}
    >
      <PayoutsHeader provider={provider} payouts={payouts} />
      <Box height="650px" overflow="scroll">
        {payouts.map(payout => (
          <PayoutItem payout={payout} />
        ))}
      </Box>
    </InfoBox>
  );
};

const PayoutsHeader = ({ provider, payouts }: { provider: IProvider; payouts: IPayout[] }) => {
  const classes = useStyles();
  const jobsRepository = JobsRepository.getRepository();
  const [jobs, setJobs] = React.useState<IJob[]>([]);
  const balance = getProviderBalance(provider, jobs, payouts);

  useEffect(() => {
    const getJobs = async () => {
      const jobs = await jobsRepository.getProviderCompletedApprovedJobs(provider.uid);
      setJobs(jobs);
    };

    void getJobs();
  }, [jobsRepository, provider.uid]);

  return (
    <Box className={classes.payoutsWrapper}>
      <Typography className={classes.approval}>
        {provider.onboardingCompleted
          ? 'Provider approved for payouts.'
          : 'Provider not approved for payouts.'}
      </Typography>
      <Typography>Balance</Typography>
      <Typography className={classes.balance}>{currencyFormat(balance)}</Typography>
    </Box>
  );
};

const PayoutItem = ({ payout }: { payout: IPayout }) => {
  const classes = useStyles();

  return (
    <Box className={classes.payoutItem}>
      <Typography>{formatDate(getDateFromFirestoreTimestamp(payout.createdate))}</Typography>
      <Typography>{payout.status}</Typography>
      <Typography>{currencyFormat(payout.amount)}</Typography>
    </Box>
  );
};
