import { IsoString } from '../../shared/common/IsoString';

export enum CardTransactionStatus {
  CAPTURE = 'capture',
  REFUND = 'refund',
}

export interface ICardTransactionDto {
  cardTransactionId: string;
  cardLast4: string;
  providerFullName: string | null;
  description: string | null;
  amountInDollars: number;
  status: CardTransactionStatus;
  createdate: IsoString;
  jobId?: string;
}
