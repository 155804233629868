import { makeStyles } from '@material-ui/core/styles';
import { font } from '../../../styles';
import { separatorBorder } from '../../../styles/commonStyles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  statusDot: {
    marginRight: spacing(1),
  },
  notifications: {
    '& th': {
      paddingTop: 0,
      paddingBottom: spacing(0.25),
      height: 'auto',
      color: palette.secondary.light,
      fontWeight: font.weight.light,
    },
    '& td': {
      height: '40px',
    },
  },
  firstColumn: {
    paddingLeft: `${spacing(3)}px !important`,
  },
  lastColumn: {
    paddingRight: `${spacing(3)}px !important`,
  },
  createDate: {
    color: palette.primary.dark,
    paddingRight: spacing(1),
  },
  providerItem: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: spacing(3),
    paddingLeft: spacing(3),
    paddingTop: spacing(3),
    paddingBottom: spacing(2),
    borderBottom: separatorBorder,
    '& > div': {
      marginRight: spacing(1.5),
      paddingRight: spacing(1.5),
      borderRight: separatorBorder,
    },
  },
  notifyButton: {
    color: palette.primary.main,
    fontWeight: font.weight.bold,
    lineHeight: 1,
    maxWidth: 160,
  },
  stopNotifyingButton: {
    color: palette.error.main,
    fontWeight: font.weight.bold,
    lineHeight: 1,
    maxWidth: 160,
  },
  removeProviderButton: {
    color: palette.error.main,
    fontWeight: font.weight.bold,
    lineHeight: 1,
  },
  canceledRequest: {
    textDecoration: 'line-through',
    color: palette.error.main,
  },
}));
