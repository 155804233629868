// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { supressConsoleWarnings } from './utils';
import { DatabaseProvider } from './DatabaseProvider';
import { DatePickersUtilsProvider } from './DatePickersUtilsProvider';
import './index.css';
import './shared/components/Table/Table.css';
import * as Sentry from '@sentry/react';

supressConsoleWarnings();

ReactDOM.render(
  <React.StrictMode>
    <DatabaseProvider>
      <DatePickersUtilsProvider>
        <Sentry.ErrorBoundary>
          <App />
        </Sentry.ErrorBoundary>
      </DatePickersUtilsProvider>
    </DatabaseProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
