import React from 'react';
import { Box } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { JobStatus } from '../../JobUtils';
import { currencyFormat } from '../../../helpers/formatHelpers';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../styles/theme';
import { font } from '../../../styles';

interface BalanceFieldProps {
  status: JobStatus;
  balance: number;
  price: number;
}

export const BalanceField = (props: BalanceFieldProps) => {
  const classes = useStyles();
  const isCompleted = props.status === JobStatus.COMPLETED;
  return (
    <Box className={classes.balance}>
      {props.balance > 0 && isCompleted && <MonetizationOnIcon />}
      {currencyFormat(props.price || 0)}
    </Box>
  );
};

export const useStyles = makeStyles(() => ({
  balance: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: font.weight.bold,
    justifyContent: 'flex-end',
    '& svg': {
      color: theme.palette.error.main,
      height: 18,
    },
  },
}));
