import { InputWithLabel } from '../InputWithLabel';
import { SelectInput as RASelectInput } from 'react-admin';
import React from 'react';
import { required } from '../../helpers/validationHelpers';

interface ISelectInput {
  label: string;
  value?: any;
  source: string;
  choices: any;
  optionValue?: string;
  defaultValue?: any;
  optionText?: any;
  validation?: boolean;
  className?: any;
  placeholder?: string;
  onChange?: any;
}

export const SelectInput = ({
  label,
  value,
  source,
  choices,
  optionValue,
  defaultValue,
  optionText,
  validation = true,
  className,
  placeholder,
  onChange,
}: ISelectInput) => (
  <InputWithLabel label={label} value={value} validation={validation} className={className}>
    <RASelectInput
      source={source}
      choices={choices}
      optionValue={optionValue}
      defaultValue={defaultValue}
      optionText={optionText}
      label={placeholder}
      onChange={onChange}
      validate={validation && required}
    />
  </InputWithLabel>
);
