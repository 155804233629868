import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import classnames from 'classnames';
import { Modal } from '../../../components/Modal';
import { EditButton } from './EditButton';
import { DeleteButton } from './DeleteButton';
import { useStyles } from './styles';
import { AddButton } from './AddButton';

interface JobElementProps {
  className?: string;
}

interface EditableListProps {
  data: any;
  className?: string;
  createForm: any;
  editForm: any;
  deleteModal: any;
  onDelete: any;
  row: any;
  addItemButtonLabel: string;
  isReadOnly?: boolean;
  allowDelete?: boolean;
  allowAdd?: boolean;
}

export const EditableList: React.FC<EditableListProps> = ({
  data,
  createForm: CreateForm,
  editForm: EditForm,
  row: Row,
  onDelete,
  deleteModal,
  addItemButtonLabel,
  isReadOnly,
  allowDelete = true,
  allowAdd = true,
}) => {
  const [isCreateMode, setCreateMode] = React.useState<boolean>(false);
  const [editedItemIndex, setEditedItemIndex] = React.useState<number | null>(null);

  const showEditItemActions = !(isCreateMode || isReadOnly);
  const showAddItemAction = !(editedItemIndex || isCreateMode || isReadOnly);

  const onEditAction = (index: number) => {
    setEditedItemIndex(index);
  };

  const onCreateAction = () => {
    setEditedItemIndex(null);
    setCreateMode(true);
  };

  const onDeleteConfirmation = async (index: number) => {
    await onDelete(index);
  };

  const isEditMode = (index: number) => editedItemIndex === index;

  return (
    <>
      {data.map((item: any, index: number) =>
        isEditMode(index) ? (
          <EditForm
            onCancelClick={() => setEditedItemIndex(null)}
            data={item}
            id={index}
            key={index}
          />
        ) : (
          <JobDetailWithActions
            showActions={showEditItemActions}
            onDeleteConfirmation={() => onDeleteConfirmation(index)}
            onEditAction={() => onEditAction(index)}
            deleteModal={deleteModal(item)}
            key={index}
            deletable={item.modifiable !== undefined ? item.modifiable : allowDelete}
          >
            <Row item={item} />
          </JobDetailWithActions>
        ),
      )}
      {isCreateMode && <CreateForm onCancelClick={() => setCreateMode(false)} />}
      {showAddItemAction && allowAdd && (
        <AddButton onClick={onCreateAction}>{addItemButtonLabel}</AddButton>
      )}
    </>
  );
};

interface JobItemWithActions extends JobElementProps {
  className?: string;
  showActions: boolean;
  onEditAction: () => void;
  onDeleteConfirmation: () => Promise<void>;
  deleteModal: any;
  deletable?: boolean;
}

export const JobDetailWithActions: React.FC<JobItemWithActions> = ({
  children,
  showActions,
  onEditAction,
  onDeleteConfirmation,
  deleteModal,
  deletable = true,
}) => {
  const classes = useStyles();
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const handleDeleteConfirm = async () => {
    await onDeleteConfirmation();
    setDeleteModalVisible(false);
  };

  return (
    <Box className={classes.wrapperWithActions}>
      {children}
      {showActions && (
        <Box className={classes.actions}>
          <EditButton onClick={onEditAction} />
          {deletable && <DeleteButton onClick={() => setDeleteModalVisible(true)} />}
        </Box>
      )}
      <Modal
        open={isDeleteModalVisible}
        title={deleteModal.title}
        confirmText={deleteModal.confirmText}
        handleClose={() => setDeleteModalVisible(false)}
        handleConfirm={handleDeleteConfirm}
      >
        {deleteModal.content}
      </Modal>
    </Box>
  );
};

interface JobDetailsDescriptionProps extends JobElementProps {
  title: any;
  description?: any;
}

export const JobDetailsDescription: React.FC<JobDetailsDescriptionProps> = ({
  title,
  description,
  className,
}) => {
  const classes = useStyles();
  return (
    <JobItemTextWrapper className={classnames(className && className)}>
      <Typography variant="body2" component="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body2" className={classnames(classes.description, className)}>
        {description}
      </Typography>
    </JobItemTextWrapper>
  );
};

export const JobItemHighlightedText: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      component="h5"
      className={classnames(classes.highlighted, className)}
    >
      {children}
    </Typography>
  );
};

export const JobItemTextWrapper: React.FC<JobElementProps> = ({ children, className }) => {
  const classes = useStyles();
  return <Box className={classnames(classes.textWrapper, className)}>{children}</Box>;
};
