import React from 'react';
import { Box } from '@material-ui/core';
import { CheckboxInput } from '../../../components/Inputs/CheckboxInput';
import { TextInput } from '../../../components/Inputs/TextInput';
import { InlineFieldsWrapper } from '../../../components/InlineFieldsWrapper';
import { PhoneNumberInput } from '../../../components/Inputs/PhoneNumberInput';
import { IContacts } from '../../../interfaces';
import { ContactLabel } from './index';
import { useStyles } from './styles';

export const DispatcherSection = ({ values }: { values: IContacts }) => {
  const isDriverEmpty = !(values.driver?.firstName || values.driver?.phoneNumber);
  const classes = useStyles();

  return (
    <Box className={classes.section}>
      <ContactLabel text="Dispatcher" />
      <InlineFieldsWrapper>
        <TextInput
          source="dispatcher.firstName"
          label="First name"
          value={values.dispatcher?.firstName}
          validation={isDriverEmpty}
        />
        <TextInput source="dispatcher.lastName" label="Last name" validation={false} />
      </InlineFieldsWrapper>
      <InlineFieldsWrapper>
        <PhoneNumberInput
          source="dispatcher.phoneNumber"
          value={values.dispatcher?.phoneNumber}
          isRequired={isDriverEmpty}
        />
        <TextInput source="dispatcher.email" label="E-mail address" validation={false} />
      </InlineFieldsWrapper>
      <CheckboxInput
        source="dispatcher.noTextMessages"
        label="Dispatcher's device doesn't accept text messages."
        validation={false}
      />
    </Box>
  );
};
