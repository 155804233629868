import { Box, Typography } from '@material-ui/core';
import classnames from 'classnames';
import React from 'react';
import { Button } from '../../../components/Buttons';
import { Loader } from '../../../components/Loader';
import { blobToBase64 } from '../../../components/Pdf/blobToBase64';
import { useDatabase } from '../../../DatabaseProvider';
import { useButtonStyles } from '../../../styles/commonStyles';
import './PhotosTab.css';
import { Delete } from '@material-ui/icons';
import { Modal } from '../../../components/Modal';
import { useNotify } from 'react-admin';
import imageCompression from 'browser-image-compression';
import { TEN_SECONDS_IN_MILISECONDS } from '../../../shared/constants/time';

const REFRESH_PHOTOS_INTERVAL = TEN_SECONDS_IN_MILISECONDS;

interface IJobPhotoDto {
  id: string;
  downloadUrl: string;
  uploadedByUserId: string;
}

interface IAddJobPhotoDto {
  base64: string;
  contentType: string;
  originalName: string;
}

interface PhotosTabProps {
  jobId: string;
}
export const PhotosTab = (props: PhotosTabProps) => {
  const { functions } = useDatabase();
  const notify = useNotify();

  //state
  const [arePhotosLoading, setArePhotosLoading] = React.useState<boolean>(false);
  const [jobPhotos, setJobPhotos] = React.useState<IJobPhotoDto[]>([]);

  const [files, setFiles] = React.useState<FileList | null>(null);
  const [arePhotosUploading, setArePhotosUploading] = React.useState<boolean>(false);

  const [isModalOpen, _toggleModalOpen] = React.useState(false);
  const [isModalProcessing, _toggleIsModalProcessing] = React.useState(false);
  const [photoIdToDelete, setPhotoIdToDelete] = React.useState<string | null>(null);
  const openModal = () => _toggleModalOpen(true);
  const closeModal = () => _toggleModalOpen(false);
  const startModalProcessing = () => _toggleIsModalProcessing(true);
  const stopModalProcessing = () => _toggleIsModalProcessing(false);
  //state

  const fetchJobPhotos = React.useCallback(async () => {
    const result = await functions.httpsCallable('getJobPhotos')({ jobId: props.jobId });
    const jobPhotos: IJobPhotoDto[] = result.data.data;
    setJobPhotos(jobPhotos);
  }, [props.jobId, functions]);

  React.useEffect(() => {
    setArePhotosLoading(true);
    fetchJobPhotos().finally(() => {
      setArePhotosLoading(false);
    });
  }, [fetchJobPhotos]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      fetchJobPhotos();
    }, REFRESH_PHOTOS_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchJobPhotos]);

  //handlers
  const handleIntensionToUpload = async () => {
    if (!files?.length) {
      alert('Please select photos to upload');
      return;
    }
    try {
      setArePhotosUploading(true);
      const filesCount = files.length;
      const filesToAdd: IAddJobPhotoDto[] = [];
      for (let i = 0; i < filesCount; i++) {
        const file = await imageCompression(files[i], {
          useWebWorker: true,
          maxWidthOrHeight: 1920,
          maxSizeMB: 3,
        });
        const base64 = await blobToBase64(file);
        filesToAdd.push({ base64, contentType: file.type, originalName: file.name });
      }
      const result = await functions.httpsCallable('addJobPhotos')({
        jobId: props.jobId,
        photos: filesToAdd,
      });
      const addedJobPhotos: IJobPhotoDto[] | undefined = result.data.data;
      if (!addedJobPhotos) {
        return;
      }
      setJobPhotos(jobPhotos.concat(addedJobPhotos));
      setFiles(null);
    } catch (error) {
      notify('Payload Too Large. Please select smaller files', 'error');
      setFiles(null);
    } finally {
      setArePhotosUploading(false);
    }
  };

  const handleIntensionToDeletePhoto = (photoIdToDelete: string) => {
    setPhotoIdToDelete(photoIdToDelete);
    openModal();
  };

  const handlePhotoDeleteConfirmation = async () => {
    if (!photoIdToDelete) {
      return;
    }
    try {
      startModalProcessing();
      await functions.httpsCallable('deleteJobPhoto')({
        id: photoIdToDelete,
      });
      setJobPhotos(jobPhotos.filter(photo => photo.id !== photoIdToDelete));
      setPhotoIdToDelete(null);
      closeModal();
    } catch (error) {
      console.error(error);
    } finally {
      stopModalProcessing();
    }
  };
  //handlers

  return (
    <Box py={3} px={3}>
      <JobPhotoList
        photos={jobPhotos}
        arePhotosLoading={arePhotosLoading}
        onPhotoDelete={handleIntensionToDeletePhoto}
      />

      <JobPhotosUploader
        arePhotosUploading={arePhotosUploading}
        files={files}
        onChange={files => setFiles(files)}
        onIntensionToUpload={handleIntensionToUpload}
      />

      <Modal
        title="Delete photo"
        open={isModalOpen}
        isProcessing={isModalProcessing}
        handleClose={closeModal}
        handleConfirm={handlePhotoDeleteConfirmation}
        confirmText="Yes"
      >
        You are going to delete the photo. Are you sure you want to delete this photo?
      </Modal>
    </Box>
  );
};

interface JobPhotoListProps {
  photos: IJobPhotoDto[];
  arePhotosLoading: boolean;
  onPhotoDelete: (photoIdToDelete: string) => void;
}
const JobPhotoList = (props: JobPhotoListProps) => {
  const handlePhotoOpen = (downloadUrl: string) => {
    window.open(downloadUrl, '_blank');
  };

  const arePhotosLoading = props.arePhotosLoading;
  const isPhotoListEmpty = !props.photos.length && !arePhotosLoading;

  const renderContent = () => {
    if (arePhotosLoading) {
      return (
        <Box margin="auto">
          <Loader text="Loading photos..." />
        </Box>
      );
    }

    if (isPhotoListEmpty) {
      return (
        <Box margin="auto">
          <Typography variant="body1">No photos found.</Typography>
        </Box>
      );
    }

    return props.photos.map(photo => (
      <div className="job-photo" key={photo.id}>
        <div
          className="job-photo__img"
          style={{ backgroundImage: `url("${photo.downloadUrl}")` }}
          onClick={() => handlePhotoOpen(photo.downloadUrl)}
        />
        <Delete className="job-photo__delete-btn" onClick={() => props.onPhotoDelete(photo.id)} />
      </div>
    ));
  };

  return <Box className="photos-tab">{renderContent()}</Box>;
};

interface JobPhotosUploaderProps {
  arePhotosUploading: boolean;
  files: FileList | null;
  onChange: (files: FileList | null) => void;
  onIntensionToUpload: () => void;
}
const JobPhotosUploader = (props: JobPhotosUploaderProps) => {
  const buttonClasses = useButtonStyles();
  const isUploadButtonActive = !!props.files?.length;

  return (
    <Box display="flex" flexDirection="column">
      <Box pt={2} pb={3}>
        <input
          type="file"
          multiple
          value={props.files ? undefined : ''}
          onChange={e => props.onChange(e.target.files)}
          accept="image/*"
        />
      </Box>

      <Button
        className={classnames(buttonClasses.defaultButton)}
        disabled={!isUploadButtonActive}
        onClick={props.onIntensionToUpload}
        isProcessing={props.arePhotosUploading}
      >
        Upload
      </Button>
    </Box>
  );
};
