import React from 'react';
import classnames from 'classnames';
import { Box, Button } from '@material-ui/core';
import { CancelButton } from '../../../components/Buttons/CancelButton';
import { useButtonStyles } from '../../../styles/commonStyles';
import theme from '../../../styles/theme';
import { useStyles } from './styles';

interface ItemFormProps {
  onCancelClick: () => void;
  isValid: boolean;
}

export const ItemForm: React.FC<ItemFormProps> = ({ onCancelClick, isValid, children }) => {
  const buttonClasses = useButtonStyles();
  const classes = useStyles();

  return (
    <Box className={classes.formWrapper}>
      {children}
      <Button className={buttonClasses.defaultButton} disabled={!isValid} type="submit">
        Save
      </Button>
      <CancelButton
        onClick={onCancelClick}
        className={classnames(buttonClasses.defaultButton, buttonClasses.reverseButton)}
        style={{ marginLeft: theme.spacing(1) }}
      />
    </Box>
  );
};
