/* eslint-disable */

export interface IDocument<T = any> {
  id: string;
  ref: IDocumentRef<T>;
  data: () => Omit<T, 'id'>;
  exists: boolean;
}

export interface IDocumentRef<T = any> {
  get: () => Promise<IDocument<T>>;
  update: (data: Partial<T>) => Promise<any>;
  set: (data: Partial<T>, options: { merge: boolean }) => Promise<any>;
}

export function extractDataFromRef(ref: any) {
  return ref.docs.map((doc: IDocument) => doc.data());
}
