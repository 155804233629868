import React from 'react';
// @ts-ignore
import { SaveButton } from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import { useButtonStyles } from '../../styles/commonStyles';

export const SaveResourceButton = (props: any) => {
  const classes = useButtonStyles();

  return (
    <SaveButton
      {...props}
      redirect={props.customRedirect || props.redirect}
      className={classes.defaultButton}
      icon={<CheckIcon style={{ width: '14px' }} />}
      label={props.isCreatePage ? 'Create' : 'Save changes'}
      disabled={props.invalid}
      onSuccess={props.onSuccess}
    />
  );
};
