import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import classnames from 'classnames';
import { CheckIcon, QuestionIcon } from '../components/Icons';
import theme from '../styles/theme';
import { font } from '../styles';

export const useStyles = makeStyles(({ palette }) => ({
  chartTitle: {
    color: palette.primary.main,
  },
  chartIcon: {
    width: '14px',
    height: '14px',
    marginRight: '5px',
    color: palette.primary.main,
  },
  questionIcon: {
    width: '12px',
    marginLeft: '4px',
  },
  paragraph: {
    color: palette.secondary.light,
  },
  chart: {
    marginBottom: theme.spacing(1),
    borderRadius: '100%',
    boxShadow: `inset 0px 0px 0px 3px ${palette.primary.light}`,
  },
  checkIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '16px',
    height: '16px',
  },
  chartLabel: {
    position: 'absolute',
    top: '16%',
    left: '50%',
    width: '16px',
    height: '16px',
    transform: 'translate(-50%, 0)',
    fontWeight: font.weight.extraBold,
    fontSize: font.size.medium,
  },
  chartUnit: {
    color: palette.secondary.light,
    fontWeight: font.weight.light,
    fontSize: font.size.regular,
    '& svg': {
      width: '12px',
      height: '12px',
    },
  },
  disabledTitle: {
    color: palette.common.black,
  },
  disabledIcon: {
    color: palette.primary.light,
  },
}));

export const ChartBox = (props: any) => {
  const classes = useStyles();
  const { icon: Icon, title, subtitle, isDone, value, unit, label } = props;
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box position="relative">
        <CircularProgress
          variant="static"
          value={value > 100 ? 100 : value}
          size={56}
          className={classes.chart}
          thickness={2}
        />

        <Box
          className={classes.chartLabel}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <span>{label || 0}</span>

          <span className={classes.chartUnit}>{unit}</span>
        </Box>
        {isDone && <CheckIcon className={classes.checkIcon} />}
      </Box>

      <Box display="flex" alignItems="center" mb="4px">
        <Icon
          className={classnames(classes.chartIcon, {
            [classes.disabledIcon]: !isDone,
          })}
        />

        <Box
          className={classnames(classes.chartTitle, {
            [classes.disabledTitle]: !isDone,
          })}
        >
          {title}
        </Box>
      </Box>

      <Box display="flex" alignItems="center" className={classes.paragraph} justifyContent="center">
        <Typography variant="caption" component="span">
          {subtitle}
        </Typography>

        <QuestionIcon className={classes.questionIcon} />
      </Box>
    </Box>
  );
};
