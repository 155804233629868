import { calcDistanceInMiles, parseToNumberCoordinates } from '.';
import { ICoordinates, IServiceArea } from '../Jobs/IJob';

export function findMatchingServiceArea(
  { lat, long }: ICoordinates,
  serviceAreas: IServiceArea[],
): { serviceArea: number; isOutsideServiceArea: boolean } {
  const origin = parseToNumberCoordinates({ lat, long });
  const distances = serviceAreas.map(({ epicenter }) => {
    const direction = parseToNumberCoordinates(epicenter);
    return calcDistanceInMiles(origin, direction);
  });
  const shortestDistance = Math.min(...distances);

  return {
    serviceArea: distances.indexOf(shortestDistance),
    isOutsideServiceArea: shortestDistance > 100,
  };
}

export const findServiceArea = (
  coordinates: { lat: number; long: number },
  serviceAreaList: IServiceArea[],
) => {
  return serviceAreaList.find(({ epicenter }) => {
    if (!epicenter) return false;
    const direction = parseToNumberCoordinates(epicenter);
    const distanceInMiles = calcDistanceInMiles(coordinates, direction);
    return distanceInMiles <= 100;
  });
};
