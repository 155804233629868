import React from 'react';
import { Edit } from '@material-ui/icons';
import classnames from 'classnames';
import { Button, ButtonProps } from '../../../components/Buttons';
import { useButtonStyles } from '../../../styles/commonStyles';
import { useStyles } from './styles';

export const EditButton: React.FC<ButtonProps> = props => {
  const buttonClasses = useButtonStyles();
  const classes = useStyles();

  return (
    <Button className={classnames(buttonClasses.reverseButton, classes.actionButton)} {...props}>
      <Edit />
    </Button>
  );
};
