import React from 'react';
import classnames from 'classnames';
import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers';
import { useStyles } from './styles';
import { DATE_TIME_FORMAT } from '../../helpers/timeHelpers';

interface IDateAndTimePickerProps extends KeyboardDateTimePickerProps {
  className?: string;
  format?: string;
}

export const DateTimePicker: React.FC<IDateAndTimePickerProps> = ({
  className,
  format,
  ...props
}) => {
  const classes = useStyles();

  return (
    <KeyboardDateTimePicker
      format={format || DATE_TIME_FORMAT}
      inputVariant="outlined"
      variant="inline"
      className={classnames(classes.dateTimeInput, className)}
      autoOk
      {...props}
    />
  );
};
