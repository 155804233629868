import * as moment from 'moment';
import * as firebase from 'firebase';

export interface ReactAdminTimestamp {
  _seconds: number;
  _nanoseconds: number;
}

export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';
export const FIVE_MINUTES_IN_SECONDS = 300;
export const FIVE_MINUTES_IN_MILLIS = 300000;

export function convertNumToTime(number: number) {
  const hour = Math.floor(number);
  let decPart = number - hour;

  decPart = Math.round(decPart * 60) / 60;

  let minute = Math.floor(decPart * 60) + '';

  if (minute.length < 2) {
    minute = '0' + minute;
  }

  return hour + ':' + minute;
}

export function timeAgo(timestamp: Date, currentTime: Date = new Date()) {
  return moment.default(timestamp).from(currentTime);
}

export function convertSecondsToTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedHours = hours ? `${hours} hr ` : '';
  const formattedMinutes = `${minutes || 0} min`;

  return `${formattedHours}${formattedMinutes}`;
}

export function formatDateWithToday(date: Date | ReactAdminTimestamp) {
  let dateToFormat = date;
  if (!(dateToFormat instanceof Date))
    dateToFormat = new Date(dateToFormat._seconds * 1000 + dateToFormat._nanoseconds / 1000000);
  if (moment.default(dateToFormat).isSame(moment.default(), 'day')) return 'Today';

  return formatDate(dateToFormat);
}

export function getDateFromFirestoreTimestamp(timestamp: any) {
  return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
}

export function getDateFromRawTimestamp(timestamp: any) {
  return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
}

export function getDateFromNumber(timestamp: number) {
  return new Date(timestamp);
}

export function formatDate(date: Date) {
  return moment.default(date).format('MMM D, YYYY');
}

export function formatDateWithFullMonth(date: Date) {
  return moment.default(date).format('MMMM D, YYYY');
}

export function formatDateWithTime(date: Date, format = 'YYYY-MM-DD HH:mm:ss') {
  return moment.default(date).format(format);
}

export function formatTime(date: Date) {
  return moment.default(date).format('hh:mm A');
}

export function getDurationInSeconds(startTime: Date, end: Date) {
  const duration = moment.duration(moment.default(end).diff(moment.default(startTime)));
  return duration.asSeconds();
}

export const getFirebaseTimestampFromDate = (date: Date) =>
  firebase.firestore.Timestamp.fromDate(date);

export const getParsedDuration = (durationInSeconds: number) => {
  let hours = Math.floor(durationInSeconds / (60 * 60));
  let minutes = Math.ceil((durationInSeconds / 60) % 60);

  switch (true) {
    case minutes === 0 && hours >= 0:
      minutes = 0;
      break;
    case minutes < 15:
      minutes = 15;
      break;
    case minutes < 30:
      minutes = 30;
      break;
    case minutes < 45:
      minutes = 45;
      break;
    default:
      minutes = 0;
      hours++;
  }

  let formatted = '';
  if (hours) formatted += `${hours}h`;
  if (minutes) formatted += `${minutes}m`;
  const inHours = hours + minutes / 60;

  return {
    formatted,
    inHours,
  };
};

export const getParsedMinutesAndSeconds = (seconds: number): string => {
  const extractedMinutes = Math.trunc(seconds / 60);
  const remainingSeconds = Math.trunc(seconds % 60);

  if (!seconds || seconds < 0) return '0s';

  let formatted = '';
  if (extractedMinutes) formatted += `${extractedMinutes}m `;
  if (remainingSeconds) formatted += `${remainingSeconds}s`;
  return formatted;
};
