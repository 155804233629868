import React from 'react';
import { useField } from 'react-final-form';
import { Box, FormControlLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';
import { Field } from 'react-final-form';
import classnames from 'classnames';
import { checked } from '../../helpers/validationHelpers';

export const CheckboxInput = ({
  source,
  label,
  validation = true,
}: {
  source: string;
  label: string;
  validation?: boolean;
}) => {
  const classes = useStyles();
  const { input } = useField(source);

  return (
    <Box>
      <FormControlLabel
        className={classes.label}
        label={
          <Typography variant="body2" style={{ paddingLeft: 0 }}>
            {label}
          </Typography>
        }
        control={
          <Field
            name={source}
            component="input"
            type="checkbox"
            validate={(validation && checked) || undefined}
            className={classnames(classes.root, {
              [classes.checked]: input.value,
            })}
            defaultValue={false}
          />
        }
      />
    </Box>
  );
};

export const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    outline: 'none !important',
    border: `1px solid ${colors.grey}`,
    minWidth: 16,
    minHeight: 16,
    'input:hover ~ &': {
      border: `1px solid ${palette.secondary.light}`,
    },
    marginRight: spacing(1),
    marginLeft: spacing(1.5),
    '-webkit-appearance': 'none',
  },
  checked: {
    border: 0,
    '&:before': {
      display: 'block',
      background: palette.primary.main,
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  label: {
    color: palette.secondary.light,
    height: '18px',
    alignItems: 'end',
    marginBottom: spacing(2),
  },
}));
