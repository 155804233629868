import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IPersonalInfoItem } from '../../Provider.interface';
import { defaultBorder } from '../../../styles/commonStyles';
import { font, BORDER_RADIUS } from '../../../styles';

export const PersonalInfoItem = (props: IPersonalInfoItem) => {
  const classes = useStyles();

  return (
    <Box p={2} pb="14px" display="flex" justifyContent="space-between" borderBottom={defaultBorder}>
      <Box>
        <Typography variant="caption" component="p" className={classes.title}>
          {props.title}
        </Typography>
        <Typography variant="subtitle2" component="p" className={classes.text}>
          {props.text}
        </Typography>
      </Box>
      <Button
        variant="outlined"
        size="large"
        className={classes.button}
        startIcon={props.icon}
        href={props.href}
        target="_blank"
      >
        <Typography variant="button" component="span" className={classes.buttonText}>
          {props.buttonText}
        </Typography>
      </Button>
    </Box>
  );
};

export const useStyles = makeStyles(theme => ({
  button: {
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: BORDER_RADIUS,
    padding: 0,
    width: '103px',
    height: '40px',
    alignSelf: 'flex-end',
  },
  buttonText: {
    fontWeight: font.weight.bold,
  },
  title: {
    color: theme.palette.secondary.light,
    paddingBottom: theme.spacing(1),
  },
  text: {
    fontWeight: font.weight.bold,
    minWidth: '50%',
  },
}));
