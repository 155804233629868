import React from 'react';
import classnames from 'classnames';
import CheckIcon from '@material-ui/icons/Check';
import { Box, Typography, makeStyles } from '@material-ui/core';
import theme from '../styles/theme';
import { colors, font } from '../styles';

interface IInputWithLabel {
  label?: string;
  value?: any;
  validation?: boolean;
  children: any;
  style?: any;
  className?: any;
  type?: string;
}

export const InputWithLabel = ({
  label,
  validation = true,
  value,
  children,
  style,
  className,
  type,
}: IInputWithLabel) => {
  const classes = useStyles();
  const wrapperClassNames = classnames(className, type && type === 'hidden' ? classes.hidden : '');
  const hasValue = !(value === undefined || value === null);

  return (
    <Box display="flex" flexDirection="column" pb={2} style={style} className={wrapperClassNames}>
      {label && (
        <Typography variant="subtitle2" className={classes.label}>
          <span>{label}</span>
          <Box>
            {hasValue && (
              <CheckIcon className={classes.icon} style={{ height: '10px', width: '10px' }} />
            )}
            {validation && (
              <span
                className={classnames(classes.required, {
                  [classes.filled]: hasValue,
                })}
              >
                required
              </span>
            )}
          </Box>
        </Typography>
      )}
      {children}
    </Box>
  );
};

export const useStyles = makeStyles(() => ({
  label: {
    fontWeight: font.weight.medium,
    lineHeight: '12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  required: {
    color: theme.palette.primary.main,
  },
  filled: {
    color: colors.grey,
  },
  icon: {
    stroke: theme.palette.primary.main,
    strokeWidth: 2,
    paddingRight: theme.spacing(1),
    height: '10px',
    width: '10px',
  },
  hidden: {
    display: 'none',
  },
}));
