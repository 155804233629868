import { makeStyles, Theme } from '@material-ui/core/styles';
import { BORDER_RADIUS, colors } from '../../../styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    input: {
      paddingBottom: theme.spacing(3),
      '& label.MuiFormLabel-root.Mui-error': {
        color: theme.palette.primary.dark,
      },
      '& fieldset': {
        border: `1px solid ${theme.palette.primary.light} !important`,
        borderRadius: BORDER_RADIUS,
      },
      '&:hover fieldset': {
        border: `1px solid ${colors.grey} !important`,
        borderRadius: BORDER_RADIUS,
      },
      '& .Mui-focused fieldset': {
        border: `1px solid ${theme.palette.primary.main} !important`,
        borderRadius: BORDER_RADIUS,
      },
      '& .Mui-error svg': {
        color: theme.palette.error.main,
      },
      '& .Mui-error fieldset': {
        border: `1px solid ${theme.palette.error.main} !important`,
        borderRadius: BORDER_RADIUS,
        color: theme.palette.error.main,
      },
      '& p': {
        margin: '8px 0 0 0',
        fontFamily: ' Roboto, sans-serif',
      },
      '& div.MuiInputBase-root': {
        height: '40px',
        width: '400px',
        padding: '12px',
        '& div.MuiOutlinedInput-root': {
          borderRadius: BORDER_RADIUS,
          border: `1px solid ${theme.palette.primary.light}`,
          height: '40px',
          width: '400px',
          padding: '12px',
        },
      },
      '& input': {
        padding: '12px',
        color: theme.palette.primary.dark,
        fontFamily: 'Roboto, sans-serif',
      },
    },
    button: {
      width: '400px',
      height: '40px',
      background: theme.palette.primary.main,
      border: `2px solid ${colors.jade}`,
      boxSizing: 'border-box',
      color: theme.palette.common.white,
      margin: '8px 16px 8px 16px',
      borderRadius: BORDER_RADIUS,
      '&:hover': {
        color: theme.palette.primary.main,
        background: 'white',
        border: `2px solid ${theme.palette.primary.main}`,
      },
      '&:hover svg': {
        color: theme.palette.primary.main,
      },
    },
    buttonContainer: {
      background: colors.catskillWhite,
    },
    icon: {
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
    },
  }),
  { name: 'RaLoginForm' },
);
