import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import { Box } from '@material-ui/core';
import { BottomToolbar } from '../../components/BottomToolbar';
import { useFormStyles } from '../../styles/commonStyles';
import { CompanyCard } from '../components/CompanyCard';
import { CompanyRatesCard } from '../components/CompanyRatesCard';
import { LaborCard } from '../components/LaborCard';
import { SingleResourceHeader } from '../../components/SingleResourceHeader';

export const CreateCompanyPage = (props: any) => {
  const classes = useFormStyles();

  return (
    <Create {...props} className={classes.root}>
      <SimpleForm
        toolbar={<BottomToolbar {...props} customRedirect="list" />}
        className={classes.form}
      >
        <Box display="flex" flexDirection="column">
          <SingleResourceHeader singular={props.options.singular} isCreate={true} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            pt={3}
            margin="0 auto"
            width="45%"
          >
            <CompanyCard />
            <LaborCard />
            <CompanyRatesCard />
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};
