import { useState, useEffect, useCallback } from 'react';
import { useDatabase } from '../../DatabaseProvider';
import { useInterval } from '../../utils/hooks/useInterval';
import { FIVE_MINUTES_IN_MILLIS } from '../../helpers/timeHelpers';

export interface IGetBalanceDTO {
  jobId: string;
}

export const useBalance = (jobId: string, subscribed?: boolean) => {
  const database = useDatabase();

  const [isLoading, setIsLoading] = useState(true);
  const [balance, setBalance] = useState<number>(0);

  const getBalance = useCallback(async () => {
    try {
      const fn = database.functions.httpsCallable('getBalance');
      const dto: IGetBalanceDTO = {
        jobId: jobId,
      };
      const result = await fn(dto);

      setBalance(result.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [database.functions, jobId]);

  useEffect(() => {
    void getBalance();
  }, [getBalance]);

  useEffect(() => {
    let firstRun = true;
    const unsubscribe = database.database
      .collection('jobs')
      .doc(jobId)
      .onSnapshot({ includeMetadataChanges: true }, async doc => {
        if (doc.metadata.hasPendingWrites || firstRun) {
          firstRun = false;
          return;
        }
        await getBalance();
      });
    return () => {
      unsubscribe();
    };
  }, [database.database, getBalance, jobId]);

  useEffect(() => {
    let firstRun = true;
    const unsubscribe = database.database
      .collection('payments')
      .where('jobId', '==', jobId)
      .onSnapshot({ includeMetadataChanges: true }, async doc => {
        if (doc.metadata.hasPendingWrites || firstRun) {
          firstRun = false;
          return;
        }
        await getBalance();
      });
    return () => {
      unsubscribe();
    };
  }, [database.database, getBalance, jobId]);

  useInterval(() => {
    if (subscribed) void getBalance();
  }, FIVE_MINUTES_IN_MILLIS);

  return { balance, setBalance, isLoading };
};
