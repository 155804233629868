import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  dateTimeInput: {
    '& > .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0',
    },
  },
  wrapper: {
    marginBottom: spacing(1),
  },
  inputWrapper: {
    display: 'flex',
    marginBottom: spacing(1),
  },
  separator: {
    lineHeight: '40px',
    margin: `0 ${spacing(1)}px`,
  },
}));
