import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { font } from '../styles';

const useStyles = makeStyles({
  heading: {
    fontWeight: font.weight.bold,
    lineHeight: '40px',
    fontSize: font.size.extraLarge,
  },
});

export const Heading = (props: any) => {
  const classes = useStyles();

  return (
    <Typography variant="h4" component="h3" className={classes.heading} style={props.style}>
      {props.children}
    </Typography>
  );
};
