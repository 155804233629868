import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { Modal } from '../../../components/Modal';
import { InputWithLabel } from '../../../components/InputWithLabel';
import theme from '../../../styles/theme';
import { useStyles } from './styles';
import { EmailValidator } from '../../../shared/EmailValidator';
import { Autocomplete } from '../../../components/Autocomplete/Autocomplete';
import { useDatabase } from '../../../DatabaseProvider';
import { generateInvoicePdfFile } from '../../../components/Pdf/generateInvoicePdfFile';
import { blobToBase64 } from '../../../components/Pdf/blobToBase64';
import { useNotify } from 'react-admin';
import { formatDate } from '../../../helpers/timeHelpers';
import { IsoString } from '../../../shared/common/IsoString';
import { Loader } from '../../../components/Loader';
import { IFormattedInvoiceData } from '../../../utils/InvoiceDataFormatter';

interface IInvoiceEmailHistoryItemDto {
  sentAt: IsoString | null;
  to: string;
  subject: string;
  attachmentUrl: string;
}

interface ISendInvoiceDto {
  jobId: string;
  to: string;
  subject: string;
  body: string;
  base64: string;
}

interface SendInvoiceButtonProps {
  jobId: string;
  jobNumber: string;
  predefinedEmails: string[];
  invoiceData: IFormattedInvoiceData;
}

export const SendInvoiceButton = (props: SendInvoiceButtonProps) => {
  const classes = useStyles();
  const { functions } = useDatabase();
  const notify = useNotify();
  const [invoiceEmailsHistoryItems, setInvoiceEmailsHistoryItems] = React.useState<
    IInvoiceEmailHistoryItemDto[]
  >([]);
  const [invoiceEmailsHistoryLoading, setInvoiceEmailsHistoryLoading] = React.useState<boolean>(
    false,
  );

  const fetchInvoiceEmailsHistory = React.useCallback(async () => {
    setInvoiceEmailsHistoryLoading(true);
    const getInvoiceEmailsHistory = functions.httpsCallable('getInvoiceEmailsHistory');
    const result = await getInvoiceEmailsHistory({ jobId: props.jobId });
    const invoiceEmailsHistory: IInvoiceEmailHistoryItemDto[] = result.data?.data;
    setInvoiceEmailsHistoryItems(invoiceEmailsHistory);
    setInvoiceEmailsHistoryLoading(false);
  }, [functions, props.jobId]);

  useEffect(() => {
    fetchInvoiceEmailsHistory();
  }, [fetchInvoiceEmailsHistory]);

  const [isModalOpen, _toggleModalOpen] = React.useState(false);
  const [isModalProcessing, _toggleIsModalProcessing] = React.useState(false);

  const [to, setTo] = React.useState<string>('');
  const isEmailValid = !!to && EmailValidator.isEmailValid(to);

  const [subject, setSubject] = React.useState<string>(
    `Invoice for job #${props.jobNumber} — Truckup`,
  );
  const isSubjectValid = !!subject;
  const [body, setBody] = React.useState<string>(
    `Invoice for job #${props.jobNumber}. \n\nThank you for choosing Truckup.`,
  );
  const isBodyValid = !!body;

  const openModal = () => _toggleModalOpen(true);
  const closeModal = () => _toggleModalOpen(false);
  const startModalProcessing = () => _toggleIsModalProcessing(true);
  const stopModalProcessing = () => _toggleIsModalProcessing(false);

  const handleSubmit = React.useCallback(async () => {
    try {
      startModalProcessing();
      const sendInvoiceEmail = functions.httpsCallable('sendInvoiceEmail');

      const invoiceFile = await generateInvoicePdfFile(props.invoiceData);
      const invoiceFileInBase64 = await blobToBase64(invoiceFile);
      const dto: ISendInvoiceDto = {
        jobId: props.jobId,
        to,
        subject,
        body,
        base64: invoiceFileInBase64,
      };
      await sendInvoiceEmail(dto);
      closeModal();
      await fetchInvoiceEmailsHistory();
    } catch (error) {
      notify('ra.message.error', 'error');
      console.error(error);
    } finally {
      stopModalProcessing();
    }
  }, [
    props.invoiceData,
    functions,
    to,
    subject,
    body,
    fetchInvoiceEmailsHistory,
    notify,
    props.jobId,
  ]);

  const isFormValid = isEmailValid && isSubjectValid && isBodyValid;
  return (
    <>
      <PrimaryButton onClick={openModal}>Send Invoice</PrimaryButton>

      <Modal
        title="Send Invoice"
        open={isModalOpen}
        disabled={!isFormValid}
        isProcessing={isModalProcessing}
        handleClose={closeModal}
        handleConfirm={handleSubmit}
        confirmText="Send"
      >
        <InputWithLabel label="To:" value={to}>
          <Autocomplete
            value={to ? { id: to, name: to } : null}
            options={props.predefinedEmails.map(x => ({ id: x, name: x }))}
            onChange={value => setTo(value || '')}
            onIntensionToAddNewOption={setTo}
            placeholder="example@email.com"
            error={!isEmailValid}
          />
        </InputWithLabel>
        <InputWithLabel label="Subject:" value={to}>
          <TextField
            value={subject}
            onChange={props => setSubject(props.target.value)}
            variant="filled"
            InputProps={{ margin: 'dense' }}
            style={{ marginTop: theme.spacing(1) }}
            error={!isSubjectValid}
          />
        </InputWithLabel>
        <InputWithLabel label="Body:" value={to}>
          <TextField
            value={body}
            onChange={props => setBody(props.target.value)}
            variant="filled"
            multiline
            rows={4}
            error={!isBodyValid}
            className={classes.multilineTextField}
          />
        </InputWithLabel>
        <InvoiceEmailsHistory
          items={invoiceEmailsHistoryItems}
          isLoading={invoiceEmailsHistoryLoading}
        />
      </Modal>
    </>
  );
};

interface InvoiceEmailsHistoryProps {
  items: IInvoiceEmailHistoryItemDto[];
  isLoading: boolean;
}

const InvoiceEmailsHistory = (props: InvoiceEmailsHistoryProps) => {
  if (props.isLoading) return <Loader text="Loading..." />;
  return (
    <TableContainer style={{ maxHeight: 300 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="none">Date</TableCell>
            <TableCell padding="none">To</TableCell>
            <TableCell padding="none">Subject</TableCell>
            <TableCell padding="none" align="center">
              Attachments
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((invoiceEmail, i) => (
            <TableRow key={i}>
              <TableCell>
                {invoiceEmail.sentAt ? formatDate(new Date(invoiceEmail.sentAt)) : null}
              </TableCell>
              <TableCell>{invoiceEmail.to}</TableCell>
              <TableCell style={{ whiteSpace: 'normal' }}>{invoiceEmail.subject}</TableCell>
              <TableCell align="center">
                <a href={invoiceEmail.attachmentUrl} target="_blank" rel="noopener noreferrer">
                  open
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
