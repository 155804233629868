export const parseToLatLngLiteral = ({
  latitude,
  longitude,
}: {
  latitude: string;
  longitude: string;
}) => {
  return {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
  };
};

export const parseToLatLong = ({ lat, lng }: { lat: number | string; lng: number | string }) => ({
  lat,
  long: lng,
});

export const parseToLatLngNumbers = ({ lat, long }: { lat: string; long: string }) => ({
  lat: parseFloat(lat),
  lng: parseFloat(long),
});

export const parseToNumberCoordinates = ({ lat, long }: { lat: string; long: string }) => ({
  lat: parseFloat(lat),
  long: parseFloat(long),
});
