import React from 'react';
import { SimpleForm } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { FormFields } from './FormFields';
import { BottomToolbar } from '../BottomToolbar';
import { useFormStyles } from '../../styles/commonStyles';
import classnames from 'classnames';
import theme from '../../styles/theme';
import { sanitizeWithKeys } from '../../helpers/formHelpers';
import { IJobCompany } from '../IJob';

interface VehicleTabProps {
  isJobLocked: boolean;
  jobCompany: IJobCompany | null;
}

export const VehicleTab = (props: any & VehicleTabProps) => {
  const classes = useStyles();
  const basicClasses = useFormStyles();
  const formClasses = classnames(basicClasses.form, classes.form);
  const sanitizeBeforeSubmit = (data: any) => {
    return sanitizeWithKeys(data, ['location', 'serviceArea', 'service', 'vehicle']);
  };

  return (
    <props.formType
      {...props}
      className={basicClasses.root}
      undoable={false}
      transform={sanitizeBeforeSubmit}
    >
      <SimpleForm
        toolbar={<BottomToolbar {...props} disabled={props.isJobLocked} />}
        className={formClasses}
      >
        <FormFields jobCompany={props.jobCompany} />
      </SimpleForm>
    </props.formType>
  );
};

export const useStyles = makeStyles(() => ({
  form: {
    backgroundColor: theme.palette.common.white,
  },
}));
