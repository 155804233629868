import React from 'react';
import { Page, View, Document, Image } from '@react-pdf/renderer';
import { styles } from './styles';
import logo from '../../assets/logo.png';

export const PdfLayout = ({ children }: { children: any }) => (
  <Document>
    <Page size="A4" style={styles.page} wrap>
      <View style={styles.header} fixed>
        <Image src={logo} style={styles.logo} />
      </View>
      {children}
    </Page>
  </Document>
);
