import { makeStyles } from '@material-ui/core/styles';
import { colors, font, BORDER_RADIUS } from './index';

export const useButtonStyles = makeStyles(({ spacing, palette }) => ({
  defaultButton: {
    fontSize: font.size.default,
    padding: `0 ${spacing(2)}px 0 ${spacing(2)}px`,
    fontWeight: font.weight.semiBold,
    height: '40px',
    background: palette.primary.main,
    boxShadow: 'none',
    letterSpacing: '0.05em',
    color: palette.common.white,
    borderRadius: BORDER_RADIUS,
    '&:hover': {
      boxShadow: 'none',
      background: colors.jade,
    },
    '&.Mui-disabled': {
      background: colors.catskillWhite,
      color: colors.grey,
    },
  },
  reverseButton: {
    background: palette.common.white,
    border: `1px solid ${palette.primary.main}`,
    color: palette.primary.main,
    '&:hover': {
      background: colors.tara,
    },
    '&.Mui-disabled': {
      border: 'none',
    },
    '& svg': {
      width: '14px',
    },
  },
  removeListItem: {
    color: colors.bittersweet,
    marginLeft: spacing(1.5),
    minWidth: '40px',
    height: '40px',
    '&:hover': {
      backgroundColor: colors.bittersweetLight,
      borderRadius: BORDER_RADIUS,
    },
  },
}));

export const defaultBorder = `1px solid ${colors.catskillWhite}`;
export const boldDefaultBorder = `2px solid ${colors.catskillWhite}`;
export const separatorBorder = `1px solid ${colors.geyser}`;
export const extraBoldBorder = `3px solid ${colors.geyser}`;

export const INPUT_BORDER_WIDTH = 1;

export const calendarStyles = {
  button: {
    default: {
      border: 'none',
      color: colors.shark,
      borderRadius: BORDER_RADIUS,
    },
    highlighted: {
      background: colors.whiteIce,
    },
    active: {
      border: `2px solid ${colors.jade}`,
      background: colors.green,
      color: colors.white,
    },
  },
};

export const useFormStyles = makeStyles({
  root: {
    '& .ra-input': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    '& .ra-input > div:first-child': {
      flex: '1 0 auto',
      height: 0,
      overflowY: 'auto',
      width: '100%',
    },
    '&.create-page': {
      minHeight: 'auto',
      flexGrow: 1,
      '& > div': {
        minHeight: '100%',
        marginTop: 0,
      },
    },
    '&.edit-page': {
      minHeight: 'auto',
      flexGrow: 1,
      '& > div': {
        minHeight: '100%',
        marginTop: 0,
      },
    },
    '& form > div:first-child': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 auto',
      paddingTop: 0,
    },
  },
  form: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

export const useTabStyles = makeStyles(({ spacing, palette }) => ({
  italic: {
    fontStyle: 'italic',
  },
  listWrapper: {
    overflow: 'scroll',
    paddingTop: spacing(1.5),
  },
  boldBlackText: {
    fontWeight: font.weight.bold,
    color: palette.common.black,
  },
  boldText: {
    fontWeight: font.weight.bold,
  },
  date: {
    color: palette.secondary.light,
  },
  dateWithTime: {
    width: '60%',
    textAlign: 'right',
  },
  semiBoldText: {
    fontWeight: font.weight.semiBold,
  },
}));
