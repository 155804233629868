export function percentFormat(value: number): string {
  return `${value}%`;
}

export function currencyFormat(value: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
}

export function formatPhoneNumber(phoneNumberString: string): string | undefined {
  if (!phoneNumberString) return;
  const match = phoneNumberString.match(/^\+1(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString;
}

export const parsePhoneNumber = (number: string) => {
  if (number.match(/^(\+1)/)) return number;

  return `+1${number.replace(/\D+/g, '')}`;
};
