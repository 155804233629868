import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    actions: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: 20,
    },
    button: {},
    currentPageButton: {},
    hellip: { padding: '1.2em' },
  }),
  { name: 'RaPaginationActions' },
);
