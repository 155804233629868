import React from 'react';
import { Field, withTypes } from 'react-final-form';
import { ItemForm } from '../components/ItemForm';
import { MultilineTextInput } from '../../../components/Inputs/MultilineTextInput';
import { IComment } from '../../../../../types/src';
import { useStyles } from './styles';

const { Form } = withTypes<IComment>();

const NEW_COMMENT = {
  text: '',
};

export const CommentForm = ({
  data,
  handleSubmit,
  onCancelClick,
  id,
}: {
  data?: IComment;
  handleSubmit: any;
  onCancelClick: any;
  id: number;
}) => {
  const initialValues = data || NEW_COMMENT;
  const classes = useStyles();

  const onSubmit = (values: IComment) => {
    handleSubmit(values, id);
    onCancelClick();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, valid, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <ItemForm onCancelClick={onCancelClick} isValid={!pristine && valid}>
            <Field name="text">
              {({ input, ...rest }) => (
                <MultilineTextInput
                  {...input}
                  {...rest}
                  className={classes.textInput}
                  placeholder="Leave a comment"
                  onChange={(e: React.ChangeEvent<{ value: string }>) =>
                    input.onChange(e.target.value)
                  }
                  value={values.text}
                />
              )}
            </Field>
          </ItemForm>
        </form>
      )}
    />
  );
};
