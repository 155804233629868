import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  dot: {
    display: 'inline-block',
    width: 6,
    height: 6,
    backgroundColor: palette.primary.main,
    borderRadius: '100%',
    border: `2px solid ${palette.common.white}`,
  },
  redDot: {
    backgroundColor: palette.error.main,
  },
  greenDot: {
    backgroundColor: palette.primary.main,
  },
  onJob: {
    backgroundColor: palette.common.white,
    border: `2px solid ${palette.primary.main}`,
  },
}));
