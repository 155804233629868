import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';
import { calendarStyles, separatorBorder } from '../../styles/commonStyles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  select: {
    minWidth: 266,
  },
  selectIcon: {
    marginRight: spacing(1),
  },
  calendarWrapper: {
    position: 'absolute',
    right: 0,
    top: 46,
    zIndex: 3,
    backgroundColor: palette.common.white,
    border: `2px solid ${palette.primary.light}`,
    boxShadow: '0px 12px 32px rgba(26, 26, 26, 0.1)',
  },
  calendar: {
    minHeight: 420,
    paddingTop: spacing(2),
    '& div.MuiPaper-root': {
      backgroundColor: `${palette.common.white} !important`,
      boxShadow: 'none',
    },
    '& button[class*="materialui-daterange-picker-makeStyles-button"]': {
      borderRadius: calendarStyles.button.default.borderRadius,
      color: calendarStyles.button.default.color,
    },
    '& button[class*=" materialui-daterange-picker-makeStyles-outlined"]': {
      border: calendarStyles.button.default.border,
    },
    '& p[class*="materialui-daterange-picker-makeStyles-contrast"]': {
      color: calendarStyles.button.active.color,
    },
    '& div[class*="materialui-daterange-picker-makeStyles-highlighted"]': {
      background: calendarStyles.button.highlighted.background,
    },
    '& button[class^="materialui-daterange-picker-makeStyles-filled"], button[class*=" materialui-daterange-picker-makeStyles-filled"]': {
      background: calendarStyles.button.active.background,
      border: calendarStyles.button.active.border,
    },
    '& ul[class^="materialui-daterange-picker-MuiList"]': {
      paddingTop: 0,
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
    '& .MuiListItem-button': {
      minWidth: 256,
      marginBottom: spacing(1),
      border: separatorBorder,
    },
  },
  calendarToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `${spacing(2)}px ${spacing(3)}px`,
    background: colors.catskillWhite,
  },
  cancelButton: {
    marginRight: spacing(2),
  },
}));
