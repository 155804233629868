import React from 'react';
import { Typography, makeStyles, Badge } from '@material-ui/core';
import theme from '../styles/theme';
import { font } from '../styles';
import { colors } from '../styles/index';
import classnames from 'classnames';

interface TabLabelProps {
  text: string;
  total?: number;
  alertBadge?: any;
  isTotalBadgeRed?: boolean;
}

export const TabLabel = ({ text, total, alertBadge, isTotalBadgeRed = false }: TabLabelProps) => {
  const classes = useStyles();

  return (
    <Typography component="h6" variant="subtitle2" className={classes.tabLabel}>
      {text}
      {alertBadge && <Badge className={classes.tabLabelBadge} />}
      {typeof total === 'number' && total > 0 && (
        <span
          id="totalBadge"
          className={classnames({
            [classes.tabLabelTotalBadge]: true,
            [classes.tabLabelTotalBadgeRed]: isTotalBadgeRed,
          })}
        >
          {total}
        </span>
      )}
    </Typography>
  );
};

const useStyles = makeStyles(() => ({
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  tabLabelTotalBadge: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.light,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    lineHeight: '16px',
    marginLeft: theme.spacing(1),
    fontSize: font.size.regular,
  },
  tabLabelTotalBadgeRed: {
    backgroundColor: colors.bittersweet + ' !important',
    color: colors.white + ' !important',
  },
  tabLabelWrapper: {
    position: 'relative',
  },
  tabLabelBadge: {
    alignSelf: 'flex-start',
    width: 10,
    height: 10,
    borderRadius: '100%',
    backgroundColor: `${theme.palette.error.main} !important`,
  },
}));
