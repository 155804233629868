import React from 'react';
// @ts-ignore
import { useGetList } from 'react-admin';
import { Datagrid, FunctionField, List, TextField } from 'react-admin';
import { Tabs, Tab } from '@material-ui/core';
import { EmptyState } from '../../components/EmptyState';
import { ListWrapper } from '../../components/ListWrapper';
import { ListHeader } from '../../components/ListHeader';
import { TabLabel } from '../../components/TabLabel';
import { currencyFormat } from '../../helpers/formatHelpers';
import { useStyles } from './styles';
import { RowsPerPage } from '../../shared/common/RowsPerPage';

export const ServiceAreaList: React.FC = (props: any) => {
  const classes = useStyles();
  const totals = useGetTotals() as any;
  const [filter, setFilter] = React.useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  // @ts-ignore
  const handleTabChange = (event, newValue) => {
    setFilter(newValue === 0 ? true : false);
    setTabValue(newValue);
  };

  return (
    <>
      <List
        actions={null}
        empty={<EmptyState />}
        {...props}
        filterDefaultValues={{ active: filter }}
        perPage={RowsPerPage.TWENTY_FIVE}
      >
        <>
          <ListHeader {...props} style={{ marginBottom: 0 }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label={<TabLabel text="Active" total={totals.active} />} value={0} />
              <Tab label={<TabLabel text="Inactive" total={totals.inactive} />} value={1} />
            </Tabs>
          </ListHeader>
          <ListWrapper>
            <Datagrid rowClick="edit">
              <TextField source="name" headerClassName={classes.nameHeader} />
              <TextField source="state" headerClassName={classes.state} />
              <FunctionField
                label="callout"
                source="callout"
                headerClassName={classes.alignment}
                cellClassName={classes.alignment}
                render={({ callout }: { callout: number }) => currencyFormat(callout)}
              />
              <FunctionField
                label="rate per hour"
                source="base"
                headerClassName={classes.alignment}
                cellClassName={classes.alignment}
                render={({ base }: { base: number }) => currencyFormat(base)}
              />
              <FunctionField
                label="after hour add"
                source="afterHour"
                headerClassName={classes.alignment}
                cellClassName={classes.alignment}
                render={({ afterHour }: { afterHour: number }) => currencyFormat(afterHour)}
              />
            </Datagrid>
          </ListWrapper>
        </>
      </List>
    </>
  );
};

const useGetTotals = () => {
  const { total: totalActive } = useGetList(
    'serviceAreas',
    { perPage: 1, page: 1 },
    { field: 'id', order: 'ASC' },
    { active: true },
  );
  const { total: totalInactive } = useGetList(
    'serviceAreas',
    { perPage: 1, page: 1 },
    { field: 'id', order: 'ASC' },
    { active: false },
  );

  return {
    active: totalActive,
    inactive: totalInactive,
  };
};
