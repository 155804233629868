import { makeStyles } from '@material-ui/core/styles';
import { colors, font } from '../../styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  accordion: {
    boxShadow: 'none',
    margin: 0,
    '&:before': {
      background: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  summary: {
    paddingLeft: spacing(2.5),
    background: colors.catskillWhite,
    color: palette.secondary.light,
    fontWeight: font.weight.bold,
    textTransform: 'uppercase',
    fontSize: font.size.regular,
    lineHeight: '16px',
  },
  details: {
    flexDirection: 'column',
    padding: 0,
  },
}));
