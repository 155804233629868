import * as _ from 'lodash';
import React, { useState } from 'react';
import { useFormState } from 'react-final-form';
import { Box, Typography } from '@material-ui/core';
import { HelpOutline, ReportRounded } from '@material-ui/icons';
import { HelperText } from '../HelperText';
import { useServices } from '../../utils/hooks/useServices';
import { useServiceUpdate } from '../../utils/hooks/useServiceUpdate';
import { SelectInput } from '../../components/Inputs/SelectInput';
import { MultilineTextInput } from '../../components/Inputs/MultilineTextInput';
import { defaultBorder } from '../../styles/commonStyles';
import theme from '../../styles/theme';
import { ClockIcon, PayRateIcon } from '../../components/Icons';
import { colors } from '../../styles';
import { CheckboxInput } from '../../components/Inputs/CheckboxInput';
import { IQuestion } from '../../interfaces';
import { InlineFieldsWrapper } from '../../components/InlineFieldsWrapper';
import { TextInput } from '../../components/Inputs/TextInput';
import { IService } from '../../../../types';
import { useStyles } from './style';

interface ServiceSectionProps {
  companyOverridesServiceRates: boolean;
  companyMinHoursRate: number;
}

export const ServiceSection = (props: ServiceSectionProps) => {
  const { services } = useServices();
  const { values } = useFormState();
  const classes = useStyles();
  const [selectedService, setSelectedService] = useState(values.service);
  const updateHiddenServiceFields = useServiceUpdate();
  return (
    <Box display="flex" flexDirection="column" pt={3} px={3}>
      <SelectInput
        value={values.service?.id}
        source="service.id"
        label="Service type"
        choices={services}
        placeholder="Please select..."
        onChange={(change: any) => {
          const selectedService = services.find(
            (service: IService) => service.id === change.target.value,
          );
          setSelectedService(selectedService);
          updateHiddenServiceFields(selectedService as IService);
        }}
      />
      <MultilineTextInput
        source="service.issue"
        value={values.service?.issue}
        label="What problem is the vehicle having?"
        placeholder="Enter description of the vehicle malfunction that will be helpful for the job provider..."
        className={classes.serviceIssue}
      />
      {selectedService && (
        <RateTypeInfo
          service={selectedService}
          companyOverridesServiceRates={props.companyOverridesServiceRates}
          companyMinHoursRate={props.companyMinHoursRate}
        />
      )}
      {selectedService?.disclaimer && <ServiceDisclaimer />}
      {selectedService?.questions && <Questions questions={selectedService?.questions} />}
    </Box>
  );
};

interface RateTypeInfoProps {
  service: IService;
  companyOverridesServiceRates: boolean;
  companyMinHoursRate: number;
}

const RateTypeInfo = ({
  service,
  companyOverridesServiceRates,
  companyMinHoursRate,
}: RateTypeInfoProps) => {
  const classes = useStyles();

  const minHoursRate = companyOverridesServiceRates ? companyMinHoursRate : service.minHours;
  const helperText =
    companyOverridesServiceRates && service.hourly
      ? 'Based on the pricing setup for this company.'
      : 'Based on the pricing setup for this service.';
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      border={defaultBorder}
      p={1.5}
      boxSizing="border-box"
    >
      <Box display="flex" alignItems="center" style={{ color: theme.palette.primary.main }}>
        {service.hourly ? <ClockIcon /> : <PayRateIcon />}
        <Typography variant="overline" className={classes.rateType}>
          {service.hourly ? `${minHoursRate} hour minimum` : `$${service.flatRate} flat rate`}
        </Typography>
      </Box>
      <HelperText icon={HelpOutline} text={helperText} />
    </Box>
  );
};

const ServiceDisclaimer = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      border={defaultBorder}
      p={1.5}
      mt={1}
      boxSizing="border-box"
    >
      <Box style={{ color: colors.zest }} display="flex" alignItems="center" pb={1.5}>
        <ReportRounded
          style={{
            width: '14px',
            height: '14px',
            marginRight: theme.spacing(1.25),
          }}
        />
        <Typography variant="overline" className={classes.disclaimerTitle}>
          service disclaimer
        </Typography>
      </Box>
      <Typography variant="subtitle2" className={classes.disclaimerText}>
        Tires cannot be returned once purchased. If the incorrect tire size was specified you will
        be billed for the tire.
      </Typography>
      <CheckboxInput
        source="service.disclaimerChecked"
        label="Customer understands disclaimer and accepts terms & services of selected service."
      />
    </Box>
  );
};

const Questions = ({ questions }: { questions: IQuestion[] }) => {
  const classes = useStyles();

  return (
    <InlineFieldsWrapper wrap="wrap-reverse" className={classes.questionsContainer}>
      {questions.map((question: IQuestion, index: number) => (
        <Box key={`question${index}`}>
          <TextInput
            label={question.text}
            placeholder="Answer goes here..."
            source={parseQuestionToSource(question)}
            validation={false}
          />
        </Box>
      ))}
    </InlineFieldsWrapper>
  );
};

function parseQuestionToSource(question: IQuestion) {
  return `service.answers.${_.camelCase(question.text)}`;
}
