import React from 'react';
import MaskedInput from 'react-text-mask';
import { TextInput as RATextInput } from 'react-admin';
import { InputWithLabel } from '../InputWithLabel';
import {
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PLACEHOLDER,
  validatePhoneNumber,
} from '../../helpers/validationHelpers';

interface IPhoneNumberInput {
  source: string;
  isRequired?: boolean;
  value?: string;
  disabled?: boolean;
}

export const PhoneNumberInput = ({ source, value, isRequired, disabled }: IPhoneNumberInput) => {
  return (
    <InputWithLabel label="Phone number" value={value} validation={isRequired}>
      <RATextInput
        source={source}
        value={value}
        disabled={disabled}
        validate={!disabled && isRequired && validatePhoneNumber}
        autoComplete="off"
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
        label={PHONE_NUMBER_PLACEHOLDER}
      />
    </InputWithLabel>
  );
};

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskCustom = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={PHONE_NUMBER_MASK}
    />
  );
};
