import React from 'react';
import { useRedirect } from 'react-admin';
import { Box, IconButton, Chip, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IProvider } from '../Provider.interface';
import { providerTypes } from '../ProviderUtils';
import { UserAvatar } from '../../components/UserAvatar';
import {
  getProviderFullName,
  getProviderStatus,
  isProPlusAchieved,
  ProBadge,
} from '../../helpers/providerHelpers';
import theme from '../../styles/theme';
import { useStyles } from './DetailsTab/styles';
import { font } from '../../styles';

export const Header = ({ provider }: { provider: IProvider }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  return (
    <Box pt="52px" pl={3} pb="20" display="flex" alignItems="center" className={classes.wrapper}>
      <IconButton aria-label="go back" onClick={() => redirect('/providers')}>
        <ArrowBackIcon
          color="primary"
          style={{ border: '2px solid #00CC66', borderRadius: '100%' }}
        />
      </IconButton>
      <Box display="flex" px={1} className={classes.avatarWrapper} alignItems="center">
        <UserAvatar
          name={getProviderFullName(provider)}
          width={24}
          status={getProviderStatus(provider.live, provider.onJob)}
        />
        <Box mx={1}>
          <Typography variant="h5" style={{ fontWeight: font.weight.bold }}>
            {getProviderFullName(provider)}
          </Typography>
        </Box>
        {<ProBadge isProviderProPlus={isProPlusAchieved(provider)} />}
        {typeof provider.type === 'number' && (
          <Box borderLeft={`1px solid ${theme.palette.primary.light}`} pl={1} ml={1}>
            <Chip
              label={providerTypes[provider.type].name}
              style={{
                background: providerTypes[provider.type].color,
                height: '20px',
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
