import React from 'react';
import { useFormState } from 'react-final-form';
import { Box, makeStyles } from '@material-ui/core';
import { MailOutlineRounded, Person, RoomOutlined, Info, Business } from '@material-ui/icons';
import { InfoBox } from '../components/InfoBox';
import { AvatarField } from '../components/AvatarField';
import { SwitchInput } from '../components/SwitchInput';
import { InlineFieldsWrapper } from '../components/InlineFieldsWrapper';
import { PhoneNumberInput } from '../components/Inputs/PhoneNumberInput';
import { TextInput } from '../components/Inputs/TextInput';
import { HelperText } from '../Jobs/HelperText';
import { formatPhoneNumber } from '../helpers/formatHelpers';

export const FormFields = () => {
  return (
    <Box display="flex" flexDirection="column" pt={3} alignItems="center">
      <PersonalInfoCard />
      <CompanyCard />
      <AddressCard />
      <ContactCard />
    </Box>
  );
};

const PersonalInfoCard = () => {
  const { values } = useFormState();
  const classes = useStyles();

  return (
    <InfoBox title="Personal data" icon={<Person className={classes.icon} />} width="45%">
      <Box px={2}>
        <AvatarField
          label="Profile picture"
          firstName={values.firstName}
          lastName={values.lastName}
        />
        <SwitchInput
          isSwitchedOn={values.disabled}
          source="disabled"
          labelOn="Blocked"
          labelOff="Unblocked"
          className={classes.switch}
        />
        <InlineFieldsWrapper>
          <TextInput source="firstName" label="First name" value={values.firstName} />
          <TextInput source="lastName" label="Last name" value={values.lastName} />
        </InlineFieldsWrapper>
      </Box>
    </InfoBox>
  );
};

const CompanyCard = () => {
  const { values } = useFormState();
  const classes = useStyles();

  return (
    <InfoBox icon={<Business className={classes.icon} />} title="Company" width="45%">
      <Box px={2}>
        <TextInput source="company" label="Company" value={values.company} validation={false} />
      </Box>
    </InfoBox>
  );
};

const AddressCard = () => {
  const { values } = useFormState();
  const classes = useStyles();

  return (
    <InfoBox icon={<RoomOutlined className={classes.icon} />} title="Address" width="45%">
      <Box px={2}>
        <TextInput source="address" label="Address" value={values.address} />
        <InlineFieldsWrapper flexBasis={30}>
          <TextInput source="city" label="City" value={values.city} />
          <TextInput source="state" label="State" value={values.state} />
          <TextInput source="zip" label="Zip code" value={values.zip} />
        </InlineFieldsWrapper>
      </Box>
    </InfoBox>
  );
};

const ContactCard = () => {
  const { values, errors } = useFormState();
  const classes = useStyles();

  return (
    <InfoBox title="Contact" icon={<MailOutlineRounded className={classes.icon} />} width="45%">
      <Box px={2}>
        <TextInput source="email" label="E-mail address" value={values.email} />
        <PhoneNumberInput
          source="phoneNumber"
          value={formatPhoneNumber(values.phoneNumber)}
          isRequired
        />
        {errors.phoneNumber === 'taken' && (
          <HelperText icon={Info} text="Phone number is taken" className={classes.error} />
        )}
      </Box>
    </InfoBox>
  );
};

export const useStyles = makeStyles(({ spacing, palette }) => ({
  switch: {
    border: 0,
    paddingLeft: spacing(1.5),
    justifyContent: 'center',
  },
  icon: {
    height: '16px',
    width: '16px',
  },
  error: {
    color: palette.error.main,
    paddingBottom: spacing(2),
    marginTop: spacing(-1.5),
  },
}));
