import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, createMuiTheme, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';
import { LogoWithSlogan } from '../Icons';
import { Notification } from '../Layout/Notification';
import { font } from '../../styles';
import theme from '../../styles/theme';
import { useStyles } from './styles';
import { LoginForm } from './LoginForm/index';
import { LoginProps } from './ILoginPage.interface';

export const LoginPage: React.FunctionComponent<LoginProps> = props => {
  const {
    title,
    classes: classesOverride,
    className,
    children,
    staticContext,
    backgroundImage,
    ...rest
  } = props;
  const classes = useStyles(props);
  const muiTheme = useMemo(() => createMuiTheme(theme), []);
  const checkAuth = useCheckAuth();
  const history = useHistory();
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        history.push('/');
      })
      .catch(() => {});
  }, [checkAuth, history]);

  return (
    <ThemeProvider theme={muiTheme}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="100vh"
        className={classes.root}
        {...rest}
      >
        <Card className={classes.card}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="237px"
            borderRight="0px"
            border={`1px solid ${theme.palette.secondary.light}`}
          >
            <LogoWithSlogan />
          </Box>
          <Box display="flex" flexDirection="column" className={classes.content}>
            <Box
              display="flex"
              flexDirection="column"
              margin={3}
              borderBottom={`1px solid ${theme.palette.primary.light}`}
            >
              <Typography variant="h5">
                <Box p={1} display="flex" justifyContent="center" fontWeight={font.weight.bold}>
                  Welcome
                  <span style={{ color: theme.palette.primary.main }}>!</span>
                </Box>
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                <Box pb={1} display="flex" justifyContent="center">
                  Sign in to Admin Panel to continue
                </Box>
              </Typography>
            </Box>
            <Box display="flex" height="100%">
              {children}
            </Box>
          </Box>
        </Card>
        <Notification />
      </Box>
    </ThemeProvider>
  );
};

LoginPage.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  staticContext: PropTypes.object,
};

LoginPage.defaultProps = {
  theme: theme,
  children: <LoginForm />,
};
