import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { IJob, IRequest } from '../../IJob';
import { RequestStatus } from '../../JobUtils';
import { IProviderLocation } from '../../../Map/Map.interface';
import { FullNameField } from '../../../components/FullNameField';
import { AcceptRating } from '../../../components/Ratings/AcceptRating';
import { Accordion } from '../../../components/Accordion';
import { Modal } from '../../../components/Modal';
import { Button } from '../../../components/Buttons';
import {
  getProviderFullName,
  getProviderStatus,
  isProPlusAchieved,
} from '../../../helpers/providerHelpers';
import { useDatabase } from '../../../DatabaseProvider';
import { getLastRequestForProvider } from '../../../helpers/jobHelpers';
import { RequestStatusChip } from '../../../components/RequestStatusChip';
import { useStyles } from './styles';
import { IFetchTravelInfoDTO } from '../../../DatabaseProvider/geolocation/models/IFetchTravelInfo.dto';
import { TravelField } from './TravelField';
import { StopNotifyingButton } from '../components/StopNotifyingButton';

interface ProviderListProps {
  header: any;
  providers: IProviderLocation[];
  job: IJob;
  requests: IRequest[];
  isJobLocked?: boolean;
  showTravelColumn?: boolean;
}

export const ProviderList = ({
  header,
  providers,
  job,
  requests,
  isJobLocked,
  showTravelColumn = false,
}: ProviderListProps) => {
  return (
    <ProviderAccordion header={header} count={providers.length} defaultExpanded={true}>
      {providers.map((provider: IProviderLocation) => (
        <ProviderListItem
          provider={provider}
          key={provider.uid}
          job={job}
          requests={requests}
          isJobLocked={isJobLocked}
          showTravelColumn={showTravelColumn}
        />
      ))}
    </ProviderAccordion>
  );
};

interface ProviderListItemProps {
  provider: IProviderLocation;
  job: IJob;
  requests: IRequest[];
  isJobLocked?: boolean;
  showTravelColumn: boolean;
}

const ProviderListItem = ({
  provider,
  job,
  requests,
  isJobLocked,
  showTravelColumn,
}: ProviderListItemProps) => {
  const [travelInfo, setTravelInfo] = useState<IFetchTravelInfoDTO | null>(null);
  const { geolocation } = useDatabase();
  const classes = useStyles();
  const lastRequest = getLastRequestForProvider(requests, provider.uid);
  const lastRequestStatus = lastRequest?.status;
  const isOnThisJob = job.providerId === provider.uid;

  useEffect(() => {
    geolocation
      .fetchTravelInfo(
        { lat: provider.lat, long: provider.lng },
        { lat: +job.location.lat, long: +job.location.long },
      )
      .then(result => {
        setTravelInfo(result);
      });
  }, [geolocation, provider, job]);

  return (
    <Box className={classes.providerItem}>
      <FullNameField
        providerFullName={getProviderFullName(provider)}
        providerStatus={getProviderStatus(provider.live, provider.onJob)}
        isProviderProPlus={isProPlusAchieved(provider)}
      />
      <AcceptRating value={provider.acceptRate} />
      {lastRequestStatus !== undefined && (
        <Box>
          <RequestStatusChip status={lastRequestStatus} />
        </Box>
      )}
      {travelInfo && showTravelColumn && (
        <TravelField
          distanceInFeet={travelInfo.distanceInFeet}
          durationInMinutes={travelInfo.durationInMinutes}
          addBreakLine={true}
        />
      )}
      {isOnThisJob && !isJobLocked && <RemoveProviderButton job={job} provider={provider} />}
      {hasNoProvider(job) && !isNotifying(lastRequestStatus) && travelInfo && (
        <NotifyButton jobId={job.id} providerId={provider.uid} travel={travelInfo} />
      )}
      {isNotifying(lastRequestStatus) && lastRequest && (
        <StopNotifyingButton requestId={lastRequest.id} />
      )}
    </Box>
  );
};

const RemoveProviderButton = ({ job, provider }: { provider: IProviderLocation; job: IJob }) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [isProcessing, setProcessing] = React.useState(false);
  const providerName = getProviderFullName(provider);
  const database = useDatabase();

  const removeProvider = async () => {
    setProcessing(true);
    const fn = database.functions.httpsCallable('unassignJob');
    try {
      await fn({ jobId: job.id });
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        size="medium"
        className={classes.removeProviderButton}
        onClick={() => setModalOpen(true)}
      >
        Remove
      </Button>
      <Modal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleConfirm={removeProvider}
        title="Removing provider"
        isProcessing={isProcessing}
      >
        You are going to remove provider {providerName} from this job. The provider and all hours
        worked for this provider will be deleted.
      </Modal>
    </>
  );
};

interface NotifyButtonProps {
  jobId: string;
  providerId: string;
  travel: { distanceInFeet: number; durationInMinutes: number };
}

interface INotifyProviderDTO {
  jobId: string;
  providerId: string;
  distanceInFeet: number;
  durationInMinutes: number;
}

const NotifyButton = (props: NotifyButtonProps) => {
  const classes = useStyles();
  const database = useDatabase();

  const [isNotifying, setIsNotifying] = useState(false);
  const [isProcessing, setProcessing] = useState(false);

  const notifyProvider = async () => {
    setProcessing(true);
    const fn = database.functions.httpsCallable('notifyHandler');
    try {
      setIsNotifying(true);
      const dto: INotifyProviderDTO = {
        jobId: props.jobId,
        providerId: props.providerId,
        distanceInFeet: props.travel.distanceInFeet,
        durationInMinutes: props.travel.durationInMinutes,
      };
      await fn(dto);
    } catch (e) {
      console.error(e);
    } finally {
      setIsNotifying(false);
      setProcessing(false);
    }
  };

  return (
    <Button
      size="medium"
      className={classes.notifyButton}
      onClick={notifyProvider}
      disabled={isNotifying}
      isProcessing={isProcessing}
    >
      Notify
    </Button>
  );
};

export interface ProvidersAccordionProps {
  count: number | string;
  header: any;
  defaultExpanded?: boolean;
}

const ProviderAccordion: FunctionComponent<ProvidersAccordionProps> = ({
  header,
  count,
  children,
  ...props
}) => {
  return (
    <Accordion
      summary={
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          {header}
          <span>{count}</span>
        </Box>
      }
      {...props}
    >
      {children}
    </Accordion>
  );
};

const hasNoProvider = (job: IJob) => {
  return !job.providerId;
};

const isNotifying = (lastRequestStatus: RequestStatus | undefined) => {
  return lastRequestStatus === RequestStatus.NOTIFYING;
};
