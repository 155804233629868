import * as _ from 'lodash';
import { IJob, IRequest, IContactPersonBase } from '../Jobs/IJob';

interface IJobRepaired extends IJob {
  repairPeriodsDuration: number;
}

interface IJobsGrouped {
  [key: string]: any[];
}

interface IAverageRepairDurationPerService {
  [key: string]: number;
}

export function lastJobs(jobs: any[], limit: number) {
  return _.sortBy(jobs, 'createdate').slice(-limit);
}

export function getServicesAverageDuration(jobs: any): IAverageRepairDurationPerService {
  const result = {};
  const jobsGroupedByService = groupJobsByService(jobs);

  Object.entries(jobsGroupedByService).map(
    ([serviceId, jobsGrouped]) =>
      // @ts-ignore
      (result[serviceId] = _.meanBy(
        lastJobs(jobsGrouped, 100),
        ({ repairPeriodsDuration }: IJobRepaired) => repairPeriodsDuration,
      )),
  );

  return result;
}

export function groupJobsByService(jobs: IJob[]): IJobsGrouped {
  return _.groupBy(filterRepairedJobs(jobs), 'service.id');
}

export function filterRepairedJobs(jobs: IJob[]) {
  return _.filter(
    jobs,
    ({ repairStartTime, repairEndTime }: IJob) => !!repairStartTime && !!repairEndTime,
  );
}

export function groupJobsByServiceArea(jobs: IJob[]): IJobsGrouped {
  return _.groupBy(jobs, ({ serviceArea }: { serviceArea: { name: string } }) => serviceArea.name);
}

export function groupJobsByProvider(jobs: IJob[]): IJobsGrouped {
  return _.groupBy(jobs, 'providerId');
}

export function getContactFullName(contact: IContactPersonBase | undefined) {
  return contact?.firstName || contact?.lastName
    ? `${contact.firstName || ''} ${contact.lastName || ''}`
    : '';
}

export function cameFromCreate(history: { location: { state?: { prevLocation: string } } }) {
  return history.location.state?.prevLocation === 'create';
}

export function parseJobNumber(jobNumber: number) {
  return _.padStart(`${jobNumber}`, 6, '0');
}

export function getSortedRequestsForProvider(requests: IRequest[], providerId: string) {
  return _.sortBy(
    requests.filter((request: IRequest) => request.providerId === providerId),
    'createdate',
  );
}

export function getLastRequestForProvider(
  requests: IRequest[],
  providerId: string,
): IRequest | undefined {
  return getSortedRequestsForProvider(requests, providerId).slice(-1).pop();
}

export function priceWithMarkup(price: number, markup: number) {
  return price + price * (markup / 100);
}
