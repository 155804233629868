import React from 'react';
import { useFormState } from 'react-final-form';
import { Box } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { HelperText } from '../../HelperText';
import { defaultBorder } from '../../../styles/commonStyles';
import { DriverSection } from './DriverSection';
import { CompanySection } from './CompanySection';
import { DispatcherSection } from './DispatcherSection';

export const FormFields = () => {
  const { values } = useFormState();

  return (
    <Box display="flex" flexDirection="column">
      <CompanySection />
      <Box display="flex" mx={3} py={1.5} pl={0.5} border={defaultBorder}>
        <HelperText
          icon={Info}
          text="Either dispatcher’s or driver’s name & phone are required to proceed."
        />
      </Box>
      <DispatcherSection values={values} />
      <DriverSection values={values} />
    </Box>
  );
};
