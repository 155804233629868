import { TablePagination as MUITablePagination } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { PaginationActions } from '../PaginationActions/PaginationActions';
import { IPaginationSettings } from '../../shared/common/IPaginationSettings';
import { rowsPerPageOptions } from '../../shared/common/RowsPerPage';

interface TablePaginationProps {
  count: number;
  paginationSettings: IPaginationSettings;
  onPaginationSettingsChange: (paginationSettings: IPaginationSettings) => void;
}

export const TablePagination = ({
  count,
  paginationSettings,
  onPaginationSettingsChange,
}: TablePaginationProps) => {
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onPaginationSettingsChange({
      page: newPage + 1,
      rowsPerPage: paginationSettings.rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onPaginationSettingsChange({
      page: 1,
      rowsPerPage: +event.target.value,
    });
  };

  return (
    <MUITablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={paginationSettings.rowsPerPage}
      page={paginationSettings.page - 1}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={PaginationActions}
    />
  );
};
