import * as React from 'react';
// @ts-ignore
import { MenuItemLink, getResources, useLogout } from 'react-admin';
// @ts-ignore
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { Badge } from '@material-ui/core';
import DefaultIcon from '@material-ui/icons/ViewList';

import classnames from 'classnames';
import { useIconStyles, useItemsStyles, useMenuStyles } from './styles';
import { IMenuProps, IResource } from './IMenu.interface';
import { Profile } from '../Icons';

const Menu = ({ resources }: IMenuProps) => {
  const classes = useMenuStyles();
  const itemsClasses = useItemsStyles();
  const iconClasses = useIconStyles();
  const logout = useLogout();

  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);

  return (
    <div className={classes.menuList}>
      {resources
        .filter(resource => !(resource.name === 'activities'))
        .map((resource: IResource) => {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                open ? (resource.options && resource.options.label) || resource.name : ''
              }
              leftIcon={leftIcon(resource, iconClasses)}
              onClick={() => {}}
              classes={itemsClasses}
              className={classnames({ [classes.icon]: !open })}
            />
          );
        })}
      <div className={classes.logoutContainer}>
        <MenuItemLink
          to="/"
          primaryText={open ? 'Logout' : ''}
          leftIcon={<Profile className={iconClasses.root} />}
          onClick={() => logout()}
          className={classnames(classes.logout, itemsClasses.root, {
            [classes.icon]: !open,
          })}
        />
      </div>
    </div>
  );
};

const leftIcon = (resource: IResource, iconClasses: any, countBadge?: number) => {
  if (!resource.icon) return <DefaultIcon />;

  return countBadge ? (
    <Badge badgeContent={countBadge?.toString() || '0'} color="primary">
      <resource.icon className={iconClasses.root} />
    </Badge>
  ) : (
    <resource.icon className={iconClasses.root} />
  );
};

const mapStateToProps = (state: typeof Menu) => ({
  resources: getResources(state),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(Menu));
