import React from 'react';
import { NumberInput as RANumberInput } from 'react-admin';
import { InputWithLabel } from '../InputWithLabel';
import { greaterThanOrEqualZero, required, typeOfNumber } from '../../helpers/validationHelpers';

interface INumberInput {
  source?: string;
  label?: string;
  value?: number;
  placeholder?: string;
  validation?: boolean;
  type?: string;
  initialValue?: number;
  inputProps?: any;
  className?: string;
  onChange?: any;
  disabled?: boolean;
  allowNegative?: boolean;
}

export const NumberInput = ({
  source = '',
  label,
  value,
  placeholder,
  validation = true,
  type,
  className,
  disabled,
  allowNegative,
  ...props
}: INumberInput) => (
  <InputWithLabel
    label={label}
    value={value}
    validation={validation}
    type={type}
    className={className}
  >
    <RANumberInput
      source={source}
      label={placeholder}
      placeholder={placeholder}
      validate={validation && [required, typeOfNumber, allowNegative || greaterThanOrEqualZero]}
      autoComplete="off"
      type={type}
      disabled={disabled}
      {...props}
    />
  </InputWithLabel>
);
