import React, { useMemo } from 'react';
import { Edit, SimpleForm } from 'react-admin';
import { Box } from '@material-ui/core';
import { FormFields } from '../FormFields';
import { FormHeader } from '../FormHeader';
import { IProvider } from '../Provider.interface';
import { useFormStyles } from '../../styles/commonStyles';
import { BottomToolbar } from '../../components/BottomToolbar';
import { parsePhoneNumber } from '../../helpers/formatHelpers';
import { useProviders } from '../../utils/hooks/useProviders';
import { validatePhoneNumberUniqueness } from '../../helpers/validationHelpers';

export const ProfileEdit = (props: any) => {
  const classes = useFormStyles();
  const { providers } = useProviders();

  const providersPhoneNumbers = useMemo(
    () =>
      providers
        .map(provider => provider.phoneNumber)
        .filter(number => number !== props.provider.phoneNumber),
    [providers, props.provider.phoneNumber],
  );

  const transformBeforeSubmit = (data: IProvider) => ({
    ...data,
    phoneNumber: data.phoneNumber && parsePhoneNumber(data.phoneNumber),
  });

  return (
    <Edit {...props} className={classes.root} undoable={false} transform={transformBeforeSubmit}>
      <SimpleForm
        validate={(values: IProvider) =>
          validatePhoneNumberUniqueness(providersPhoneNumbers, values.phoneNumber)
        }
        className={classes.form}
        toolbar={<BottomToolbar onSuccess={() => props.setIsProfileEditView(false)} />}
      >
        <Box display="flex" flexDirection="column">
          <FormHeader
            title="Edit profile Information"
            buttonText="Back to provider's profile"
            onClick={() => props.setIsProfileEditView(false)}
          />
          <FormFields />
        </Box>
      </SimpleForm>
    </Edit>
  );
};
