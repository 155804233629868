import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { Loader } from '../../components/Loader';
import { useDatabase } from '../../DatabaseProvider';
import { formatDateWithTime } from '../../helpers/timeHelpers';
import { IsoString } from '../../shared/common/IsoString';

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

interface DocumentsTabProps {
  providerId: string | undefined;
}

interface IAgreementDTO {
  title: string;
  revisionNo: number;
  publishedAt: IsoString;
  ipAddress: string;
  acceptedAt: IsoString;
  system: string;
  agreementId: string;
}

export const DocumentsTab = (props: DocumentsTabProps) => {
  const classes = useStyles();
  const { functions } = useDatabase();
  const [agreements, setAgreements] = React.useState<IAgreementDTO[]>([]);
  const [isLoading, setLoading] = React.useState(false);

  const fetchAgreements = React.useCallback(async () => {
    if (!props.providerId) return;
    setLoading(true);

    const result = await functions.httpsCallable('getAgreementsHistory')({
      providerId: props.providerId,
    });
    const agreements: IAgreementDTO[] = result.data?.data;

    if (!agreements?.length) {
      setLoading(false);
      return;
    }

    setAgreements(agreements);
    setLoading(false);
  }, [props.providerId, functions]);

  React.useEffect(() => {
    fetchAgreements();
  }, [props.providerId, fetchAgreements]);

  if (isLoading) return <Loader text="Loading documents..." />;

  return (
    <Box display="flex" p={3}>
      <Box display="flex" px={3} className={classes.wrapper}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="none">Document</TableCell>
                <TableCell padding="none">Version</TableCell>
                <TableCell padding="none">Published At</TableCell>
                <TableCell padding="none">IP Address</TableCell>
                <TableCell padding="none">Timestamp</TableCell>
                <TableCell padding="none">System Name + System Version</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agreements.map(x => (
                <TableRow key={x.agreementId}>
                  <TableCell>{x.title}</TableCell>
                  <TableCell>{x.revisionNo}</TableCell>
                  <TableCell>{formatDateWithTime(new Date(x.publishedAt))}</TableCell>
                  <TableCell>{x.ipAddress}</TableCell>
                  <TableCell>{formatDateWithTime(new Date(x.acceptedAt))}</TableCell>
                  <TableCell>{x.system}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
