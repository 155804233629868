import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './styles';
import { PdfLayout } from './PdfLayout';
import { IFormattedInvoiceData, IInvoiceDetail } from '../../utils/InvoiceDataFormatter';
import { IInvoiceCommentDto } from '../../Jobs/dtos/IInvoiceData.dto';

export const JobInvoicePdf = ({ data }: { data: IFormattedInvoiceData }) => {
  return (
    <PdfLayout>
      <View style={{ ...styles.wrapper, ...styles.row }}>
        <View style={styles.column50}>
          <Text style={styles.title}>PRICE</Text>
          <Text style={styles.subtitle}>Total amount due</Text>
          <Text style={styles.hugeText}>{data.formattedTotalCost}</Text>
        </View>

        <View style={styles.column50}>
          <Text style={styles.title}>Price summary</Text>
          <View style={styles.row}>
            <Text style={styles.smallText}>Labor</Text>
            <Text style={styles.smallText}>{data.totalLaborCost}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.smallText}>Parts</Text>
            <Text style={styles.smallText}>{data.totalPartsCost}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.smallText}>Call-out (travel)</Text>
            <Text style={styles.smallText}>{data.travelCost}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.smallText}>Fuel</Text>
            <Text style={styles.smallText}>{data.fuelCost}</Text>
          </View>
        </View>
      </View>

      <View style={{ ...styles.wrapper, ...styles.row }}>
        <View style={styles.column50}>
          <Text style={styles.title}>Provider</Text>
          <Text style={styles.largeText}>{data.providerName}</Text>
        </View>

        <View style={{ ...styles.row, ...styles.column50 }}>
          <DetailItem style={styles.column50} label="Booked on">
            {data.bookedOn}
          </DetailItem>
          <DetailItem style={styles.column50} label="Job number" boldText>
            {data.formattedJobNumber}
          </DetailItem>
        </View>
      </View>

      <View style={styles.wrapper}>
        <Text style={styles.title}>Job details</Text>

        <View style={styles.rowWrap}>
          <DetailItem label="Company" style={styles.column25}>
            {data.companyName}
          </DetailItem>
          <DetailItem label="Dispatcher" style={styles.column25}>
            {data.dispatcherName}
          </DetailItem>
          <DetailItem label="Driver" style={styles.column25}>
            {data.driverName}
          </DetailItem>
          <DetailItem label="Reference number" style={styles.column25}>
            {data.referenceNumber}
          </DetailItem>
          <DetailItem label="Vehicle" style={styles.column25}>
            {data.vehicleDescription}
          </DetailItem>
          <DetailItem label="VIN / Serial number" style={styles.column25}>
            {data.vehicle?.vin}
          </DetailItem>
          <DetailItem label="Unit number" style={styles.column25}>
            {data.vehicle?.unit}
          </DetailItem>
          <DetailItem label="Vehicle type" style={styles.column25}>
            {data.vehicle?.kind}
          </DetailItem>
          <DetailItem label="Vehicle location" style={styles.column100} boldText>
            {data.locationAddress}
          </DetailItem>
          <DetailItem label="Customer notes" style={styles.column100} tinyText>
            {data.service?.issue}
          </DetailItem>
        </View>
      </View>

      <View style={styles.wrapper}>
        <Text style={styles.title}>Labor</Text>
        <CostItem
          label={data.labor.name}
          description={data.labor.description}
          details={data.labor.calculations}
          total={data.labor.cost}
        />

        {data.afterHours && (
          <CostItem
            label={data.afterHours.name}
            description={data.afterHours.description}
            details={data.afterHours.calculations}
            total={data.afterHours.cost}
          />
        )}

        <Text style={styles.title}>Parts</Text>
        {data.formattedParts ? (
          data.formattedParts.map((part: IInvoiceDetail) => (
            <CostItem
              label={part.name}
              description={part.description}
              details={part.calculations}
              total={part.cost}
            />
          ))
        ) : (
          <Text> - </Text>
        )}
      </View>

      <View style={{ ...styles.wrapper, ...styles.lastWrapper }}>
        <Text style={styles.title}>Comments</Text>

        {data.formattedComments ? (
          data.formattedComments.map((comment: IInvoiceCommentDto) => (
            <Comment date={comment.createdate}>{comment.text}</Comment>
          ))
        ) : (
          <Text> - </Text>
        )}
      </View>
    </PdfLayout>
  );
};

const DetailItem = ({
  label,
  children,
  style,
  boldText,
  tinyText,
}: {
  label: string;
  children?: string;
  style?: object;
  boldText?: boolean;
  tinyText?: boolean;
}) => (
  <View style={style}>
    <Text style={styles.subtitle}>{label}</Text>
    <Text
      style={{
        ...styles.regularText,
        ...(boldText && styles.boldText),
        ...(tinyText && styles.tinyText),
      }}
    >
      {children ? children : '-'}
    </Text>
  </View>
);

const CostItem = ({
  label,
  description,
  details,
  total,
}: {
  label: string;
  description: string;
  details: string;
  total: string;
}) => (
  <View style={{ ...styles.costWrapper, ...styles.row }}>
    <View style={styles.costBox}>
      <Text style={styles.semiBoldText}>{label}</Text>
      <Text style={styles.costDescription}>{description || '-'}</Text>
    </View>
    <Text style={styles.costDetails}>{details}</Text>
    <Text style={styles.costTotal}>{total}</Text>
  </View>
);

const Comment = ({ date, children }: { date: string; children: string }) => (
  <View style={styles.commentWrapper}>
    <Text style={styles.semiBoldText}>{`${date} | `}</Text>
    <View style={styles.commentTextWrapper}>
      <Text style={styles.commentText}>{children}</Text>
    </View>
  </View>
);
