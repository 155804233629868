import React from 'react';
import { useFormState } from 'react-final-form';
import { Box } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { HelperText } from '../HelperText';
import { locationDetailsOptions } from '../JobUtils';
import { InputWithLabel } from '../../components/InputWithLabel';
import { AddressField } from '../AddressField';
import { SelectInput } from '../../components/Inputs/SelectInput';
import { MultilineTextInput } from '../../components/Inputs/MultilineTextInput';
import { useStyles } from './style';

export const LocationSection = () => {
  const { values } = useFormState();
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" pt={3} px={3} className={classes.locationSection}>
      <InputWithLabel label="Where the vehicle is located?" value={values.location?.address}>
        <AddressField source="location.address" />
      </InputWithLabel>
      <HelperText
        icon={HelpOutline}
        text="If address is unknown, enter nearby location—city or road—then drag & drop map pin to a more precise location."
      />
      <Box className={classes.locationDetailsWrapper}>
        <SelectInput
          label="Location details"
          source="location.details"
          choices={locationDetailsOptions}
          optionValue="name"
          placeholder=""
        />
        <MultilineTextInput
          source="location.notes"
          placeholder="Helpful info to locate & identify the vehicle."
          validation={false}
          className={classes.locationNotes}
        />
      </Box>
    </Box>
  );
};
