export enum COLLECTIONS {
  ACTIVITIES = 'activities',
  JOBS = 'jobs',
  PROVIDERS = 'providers',
  SERVICES = 'services',
  SERVICE_AREAS = 'serviceAreas',
  JOB_COUNTERS = 'counters/jobs',
  LOCATIONS = 'locations',
  REQUESTS = 'requests',
  PAYMENTS = 'payments',
  PAYOUTS = 'payouts',
  COMPANIES = 'companies',
  CARD_TRANSACTIONS = 'cardTransactions',
}
