import React from 'react';
import { Box } from '@material-ui/core';
import theme from '../styles/theme';

export const ProviderInfoItem = (props: any) => (
  <Box
    px={2}
    borderRight={`1px solid ${theme.palette.primary.light}`}
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    style={props.style}
    className={props.className}
  >
    {props.children}
  </Box>
);
