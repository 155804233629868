import React, { FunctionComponent } from 'react';
import {
  Accordion as MaterialAccordion,
  AccordionDetails,
  AccordionSummary,
  AccordionProps,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from './styles';

export interface IAccordionProps extends AccordionProps {
  summary: any;
  children: any;
}

export const Accordion: FunctionComponent<IAccordionProps> = ({ summary, children, ...props }) => {
  const classes = useStyles();
  return (
    <MaterialAccordion className={classes.accordion} {...props}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
        {summary}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>{children}</AccordionDetails>
    </MaterialAccordion>
  );
};
