import { makeStyles } from '@material-ui/core/styles';
import { boldDefaultBorder, separatorBorder } from '../../styles/commonStyles';
import { colors, font, BORDER_RADIUS } from '../../styles';

const animationDuration = '0.4';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  drawer: {
    '& div.MuiPaper-root': {
      background: `${palette.common.white} !important`,
      boxShadow: 'rgb(0 0 0 / 30%) -4px 0px 5px -2px',
    },
  },
  drawerContent: {
    width: 600,
  },
  drawerTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: font.weight.medium,
    '& > svg': {
      width: 24,
      height: 24,
      marginRight: spacing(2),
    },
  },
  subtitle: {
    fontWeight: font.weight.medium,
    paddingLeft: spacing(3),
    paddingBottom: spacing(1),
  },
  drawerTrigger: {
    position: 'fixed',
    right: 0,
    top: 'calc(50% - 32px)',
    display: 'flex',
    height: 64,
    alignItems: 'center',
    padding: spacing(2.5),
    fontSize: font.size.regular,
    fontWeight: font.weight.semiBold,
    color: palette.common.black,
    backgroundColor: palette.common.white,
    border: boldDefaultBorder,
    boxShadow: '0px 0px 8px rgba(35, 40, 46, 0.1)',
    borderRadius: BORDER_RADIUS,
    transition: `all ${animationDuration}s`,
    '&:hover': {
      backgroundColor: palette.common.white,
      '& > span > svg': {
        display: 'block',
        width: 'auto',
        transform: 'translateX(0)',
      },
      '& > span > div': {
        display: 'flex',
        width: 'auto',
        transform: 'translateX(0)',
      },
    },
  },
  drawerTriggerIcon: {
    width: 24,
    height: 24,
    paddingRight: spacing(1.5),
  },
  drawerTriggerContent: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: spacing(1.5),
    marginLeft: spacing(1),
    borderLeft: separatorBorder,
  },
  drawerTriggerArrowIcon: {
    width: 0,
    marginRight: spacing(1.5),
    transition: `all ${animationDuration}s`,
    transform: 'translateX(32px)',
  },
  drawerTriggerLabel: {
    display: 'none',
    width: 0,
    fontSize: font.size.default,
    fontWeight: font.weight.medium,
    textTransform: 'uppercase',
    transition: `all ${animationDuration}s`,
    whiteSpace: 'nowrap',
  },
  disabledNotifyButton: {
    padding: 0,
    color: colors.grey,
    fontSize: font.size.regular,
    pointerEvents: 'none',
  },
  headerWrapper: {
    paddingTop: spacing(6.5),
    boxShadow: '0px 0px 8px rgba(35, 40, 46, 0.1)',
  },
  statusDot: {
    marginRight: spacing(1),
  },
}));
