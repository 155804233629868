import { makeStyles } from '@material-ui/core/styles';
import { colors, font } from '../../../styles';
import { defaultBorder } from '../../../styles/commonStyles';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
  },
  avatarWrapper: {
    borderLeft: `1px solid ${theme.palette.primary.light}`,
  },
  name: {
    fontSize: font.size.semiLarge,
    fontWeight: font.weight.bold,
    textAlign: 'center',
    marginBottom: '4px',
  },
  paragraph: {
    color: theme.palette.secondary.light,
    textAlign: 'center',
    marginBottom: '14px',
  },
  chartsWrapper: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  icon: {
    height: '16px',
    width: '16px',
  },
  proPlusBadge: {
    color: ({ isProPlus }: { isProPlus?: boolean }) =>
      isProPlus ? theme.palette.primary.main : theme.palette.secondary.light,
    marginRight: '4px',
  },
  metricsTextContainer: {
    background: ({ isProPlus }: { isProPlus?: boolean }) =>
      isProPlus ? colors.frostedMint : colors.catskillWhite,
    color: 'black',
  },
  ratings: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: font.weight.bold,
    border: 0,
    flexGrow: 1,
  },
  payoutsWrapper: {
    display: 'flex',
    borderBottom: defaultBorder,
    padding: `0 ${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  approval: {
    color: theme.palette.primary.main,
    fontStyle: 'italic',
    flex: 1,
  },
  balance: {
    color: theme.palette.primary.main,
    fontWeight: font.weight.bold,
    fontSize: font.size.semiLarge,
    marginLeft: theme.spacing(4),
  },
  payoutItem: {
    display: 'flex',
    flexBasis: 1,
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
}));
