import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import { EditableList } from '../components/EditableList';
import { IComment, IJob } from '../../IJob';
import { JobsRepository } from '../../JobsRepository';
import { IProvider } from '../../../Providers/Provider.interface';
import { useDatabase } from '../../../DatabaseProvider';
import { CommentItem } from './CommentItem';
import { CommentForm } from './CommentForm';
import { useTabStyles } from '../../../styles/commonStyles';

export const CommentsTab = ({ job }: { providers: IProvider[]; job: IJob }) => {
  const tabClasses = useTabStyles();
  const { authProvider } = useDatabase();
  const [authorName, setAuthorName] = useState() as any;

  useEffect(() => {
    const getAuthorName = async () => {
      const author = (await authProvider.checkAuth()) as any;
      setAuthorName(author?.email);
    };
    void getAuthorName();
  });

  const comments = _.sortBy(job.comments, 'timestamp');

  const jobId = job.id;
  const jobsRepository = JobsRepository.getRepository();

  const createComment = async (formData: IComment) => {
    if (!formData) return;

    const editedComments = [
      ...comments,
      { ...formData, createdate: new Date().getTime(), authorName },
    ];

    await jobsRepository.updateJob(jobId, { comments: editedComments });
  };

  const updateComment = async (formData: IComment, id: number) => {
    if (!formData) return;

    const editedComments = comments.map((comment, index) => (index === id ? formData : comment));
    await jobsRepository.updateJob(jobId, { comments: editedComments });
  };

  const deleteComment = async (deletedCommentId: number) => {
    const editedComments = comments.filter((comment, index) => index !== deletedCommentId);
    await jobsRepository.updateJob(jobId, { comments: editedComments });
  };

  return (
    <Box className={tabClasses.listWrapper}>
      <EditableList
        data={comments}
        createForm={(props: { onCancelClick: () => void; id: number }) => (
          <CommentForm handleSubmit={createComment} {...props} />
        )}
        editForm={({
          data,
          ...props
        }: {
          onCancelClick: () => void;
          data: IComment;
          id: number;
        }) => <CommentForm handleSubmit={updateComment} data={data} {...props} />}
        onDelete={deleteComment}
        deleteModal={() => ({
          title: 'Delete comment?',
          confirmText: 'Delete',
          content: <Typography>Comment added be will be deleted</Typography>,
        })}
        row={({ item }: { item: IComment }) => {
          return <CommentItem item={item} />;
        }}
        addItemButtonLabel="Add comment"
        isReadOnly={false}
      />
    </Box>
  );
};
