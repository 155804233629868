import React, { FunctionComponent, useCallback, useState } from 'react';
import {
  DateRangePicker as MaterialDateRangePicker,
  DateRange,
  DefinedRange,
} from 'materialui-daterange-picker';
import { Box, Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select/Select';
import { allTimeRange, defaultDateRanges } from '../../helpers/getDefaultDateRanges';
import { CalendarIcon } from '../Icons';
import { SecondaryButton } from '../Buttons/SecondaryButton';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { useStyles } from './styles';

export type IDateRange = DefinedRange | DateRange;

export interface BetterDateRange {
  startDate: Date;
  endDate: Date;
}

interface IDateRangePicker {
  value?: BetterDateRange;
  onChange: (range: BetterDateRange) => void;
}

export const DateRangePicker: FunctionComponent<IDateRangePicker> = ({
  value = {
    startDate: allTimeRange.startDate as Date,
    endDate: allTimeRange.endDate as Date,
  },
  onChange,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState<BetterDateRange>(value);

  const onApplyClick = useCallback(() => {
    onChange(range);
    setOpen(false);
  }, [range, onChange]);

  const onCancelClick = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Box display="flex" position="relative">
      <Select
        value={range}
        onOpen={() => setOpen(true)}
        variant="outlined"
        className={classes.select}
        open={false}
        renderValue={(value: any) => {
          const startDate: Date | undefined = value?.startDate;
          const endDate: Date | undefined = value?.endDate;
          if (!startDate || !endDate) return null;

          const uniqueDateRangeId = startDate.getTime() + endDate.getTime();
          const dateRange = defaultDateRanges.find(
            x => x.startDate.getTime() + x.endDate.getTime() === uniqueDateRangeId,
          );
          return (
            <Box display="flex" alignItems="center">
              <CalendarIcon className={classes.selectIcon} />
              <Typography variant="subtitle2" style={{ fontWeight: 400, lineHeight: '32px' }}>
                {dateRange?.label || 'Custom range'}
              </Typography>
            </Box>
          );
        }}
      />
      {open && (
        <Box className={classes.calendarWrapper}>
          <MaterialDateRangePicker
            initialDateRange={value}
            open={open}
            toggle={() => {}}
            onChange={(range: DateRange) => {
              setRange({
                startDate: range.startDate as Date,
                endDate: range.endDate as Date,
              });
            }}
            wrapperClassName={classes.calendar}
            definedRanges={defaultDateRanges}
          />
          <Box className={classes.calendarToolbar}>
            <SecondaryButton onClick={onCancelClick} className={classes.cancelButton}>
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={onApplyClick}>Apply</PrimaryButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};
