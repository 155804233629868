import React from 'react';
import { Box } from '@material-ui/core';
import { InfoBox } from '../../components/InfoBox';
import { TextInput } from '../../components/Inputs/TextInput';
import { useFormState } from 'react-final-form';
import { SwitchInput } from '../../components/SwitchInput';
import { ICompany } from '../models/ICompany';
import { Business } from '@material-ui/icons';

export const CompanyCard = () => {
  const { values } = useFormState<ICompany>();
  return (
    <InfoBox title="Company" icon={<Business style={{ width: 16, height: 16 }} />} width="100%">
      <Box px={2}>
        <TextInput source="name" label="Name" value={values.name} />
        <SwitchInput
          label="Override Service Area Rates"
          source="rateOverride"
          labelOn="Yes"
          labelOff="No"
          isSwitchedOn={values.rateOverride}
        />
      </Box>
    </InfoBox>
  );
};
