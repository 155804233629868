import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';

const BackButton = ({ history: { goBack }, children, ...props }: any) => {
  return (
    <Button {...props} onClick={goBack} style={{ hover: {} }}>
      {children}
    </Button>
  );
};

export default withRouter(BackButton);
