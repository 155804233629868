import React, { FunctionComponent, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import {
  IconButton,
  InputAdornment,
  Button,
  CardActions,
  TextField,
  CircularProgress,
} from '@material-ui/core';

import { useStyles } from './styles';
import { LoginProps, FormData } from './ILoginForm.interface';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  InfoIcon,
  KeyIcon,
  PadlockIcon,
  UserIcon,
  VisibilityOffIcon,
  VisibilityOnIcon,
} from '../../Icons';
import theme from '../../../styles/theme';
import { font } from '../../../styles';

const Input = ({ meta: { touched, error }, input: inputProps, ...props }: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    variant="outlined"
    {...inputProps}
    {...props}
    fullWidth
  />
);

export const LoginForm: FunctionComponent<LoginProps> = props => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const validate = (values: FormData) => {
    const errors: any = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = (
        <span>
          <InfoIcon style={{ paddingRight: theme.spacing(1) }} />
          {translate('ra.validation.required')}
        </span>
      );
    }
    if (!values.password) {
      errors.password = (
        <span>
          <InfoIcon style={{ paddingRight: theme.spacing(1) }} />
          {translate('ra.validation.required')}
        </span>
      );
    }
    return errors;
  };

  const submit = (values: any) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : 'Entered password does not match. Double-check and try again.',
          'warning',
        );
      });
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form className={classes.formContainer} onSubmit={handleSubmit} noValidate>
          <Box display="flex" flexDirection="column" alignItems="center" flexGrow="1">
            <div>
              <Typography variant="button">
                <Box fontWeight={font.weight.bold} pb="6px">
                  Username
                </Box>
              </Typography>
              <Field
                autoFocus
                id="username"
                name="username"
                component={Input}
                disabled={loading}
                className={classes.input}
                placeholder="Username"
                InputProps={{
                  className: classes.input,
                  startAdornment: (
                    <InputAdornment position="start">
                      <UserIcon className={classes.icon} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <Typography variant="button">
                <Box fontWeight={font.weight.bold} pb="6px">
                  Password
                </Box>
              </Typography>
              <Field
                id="password"
                name="password"
                component={Input}
                type={showPassword ? 'text' : 'password'}
                disabled={loading}
                autoComplete="current-password"
                className={classes.input}
                placeholder="Password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PadlockIcon className={classes.icon} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Box>
          <CardActions className={classes.buttonContainer}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
              startIcon={<KeyIcon />}
            >
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              <Typography variant="subtitle1">
                <Box fontWeight={font.weight.semiBold}>{translate('ra.auth.sign_in')}</Box>
              </Typography>
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
};
