import React from 'react';
import { InfoWindow } from '@react-google-maps/api';
import { Box } from '@material-ui/core';
import { FullNameField } from '../components/FullNameField';
import theme from '../styles/theme';
import { ProviderStatusBadge } from '../components/ProviderStatusBadge';
import { StarRating } from '../components/Ratings/StarRating';
import { AcceptRating } from '../components/Ratings/AcceptRating';
import { StreakRating } from '../components/Ratings/StreakRating';
import { IProviderLocation } from './Map.interface';
import { makeStyles } from '@material-ui/core/styles';
import {
  getProviderFullName,
  getProviderStatus,
  isProPlusAchieved,
} from '../helpers/providerHelpers';

export const ProviderMarkerDetails: React.FC<{
  provider: IProviderLocation;
}> = ({ provider }) => {
  const classes = useStyles();

  return (
    <InfoWindow position={provider}>
      <Box display="flex" alignItems="center">
        <FullNameField
          providerFullName={getProviderFullName(provider)}
          providerStatus={getProviderStatus(provider.live, provider.onJob)}
          isProviderProPlus={isProPlusAchieved(provider)}
        />
        <Box px={1} borderRight={`1px solid ${theme.palette.primary.light}`}>
          <ProviderStatusBadge isBlocked={provider.disabled} />
        </Box>
        <StarRating className={classes.stats} value={provider.rating} />
        <AcceptRating className={classes.stats} value={provider.acceptRate} />
        <StreakRating className={classes.stats} value={provider.proDaysCount} />
      </Box>
    </InfoWindow>
  );
};

export const useStyles = makeStyles(() => ({
  stats: {
    padding: `0 ${theme.spacing(0.5)}px`,
  },
}));
