import React from 'react';
import { Field, withTypes } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import { Decorator } from 'final-form';
import { Box, Typography } from '@material-ui/core';
import { JobItemHighlightedText } from '../components/EditableList';
import { ItemForm } from '../components/ItemForm';
import {
  greaterThanOrEqualZero,
  greaterThanZero,
  required,
} from '../../../helpers/validationHelpers';
import { currencyFormat } from '../../../helpers/formatHelpers';
import { TextInput } from '../../../components/Inputs/TextInput';
import { CurrencyInput } from '../../../components/Inputs/CurrencyInput';
import { NumberInput } from '../../../components/Inputs/NumberInput';
import { useStyles } from './styles';
import { IPartValues } from './';

const { Form } = withTypes<IPartValues>();

const DEFAULT_MARKUP = 20;
const DEFAULT_COUNT = 1;

const NEW_PART = {
  name: undefined,
  description: '',
  cost: 0,
  price: undefined,
  markup: DEFAULT_MARKUP,
  quantity: DEFAULT_COUNT,
};

export const PartsForm = ({
  data,
  handleSubmit,
  onCancelClick,
  id,
}: {
  data?: IPartValues;
  handleSubmit: (formData: IPartValues, id: number) => Promise<void>;
  onCancelClick: () => void;
  id: number;
}) => {
  const classes = useStyles();
  const initialValues = data || NEW_PART;
  const onSubmit = (values: IPartValues) => {
    handleSubmit(values, id);
    onCancelClick();
  };

  const calculator = createDecorator(
    {
      field: 'price',
      updates: {
        cost: (value, allValues) => getTotalCost(allValues as IPartValues),
      },
    },
    {
      field: 'markup',
      updates: {
        cost: (value, allValues) => getTotalCost(allValues as IPartValues),
      },
    },
    {
      field: 'quantity',
      updates: {
        cost: (value, allValues) => getTotalCost(allValues as IPartValues),
      },
    },
  ) as Decorator<IPartValues>;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={[calculator]}
      render={({ handleSubmit, values, valid, errors, pristine }) => (
        <form onSubmit={handleSubmit}>
          <ItemForm onCancelClick={onCancelClick} isValid={!pristine && valid}>
            <Box className={classes.formContainer}>
              <Box className={classes.firstFormCol}>
                <Field name="name" validate={required}>
                  {({ input, ...rest }) => (
                    <TextInput
                      {...input}
                      {...rest}
                      placeholder="Name"
                      className={classes.inputWrapper}
                      onChange={(e: React.ChangeEvent<{ value: string }>) =>
                        input.onChange(e.target.value)
                      }
                      value={values.name}
                    />
                  )}
                </Field>

                <Field name="description">
                  {({ input, ...rest }) => (
                    <TextInput
                      {...input}
                      {...rest}
                      validation={false}
                      placeholder="Description"
                      className={classes.inputWrapper}
                      onChange={(e: React.ChangeEvent<{ value: string }>) =>
                        input.onChange(e.target.value)
                      }
                    />
                  )}
                </Field>
              </Box>
              <Box className={classes.secondFormCol}>
                <Field name="price" validate={greaterThanZero}>
                  {({ input, meta, ...rest }) => (
                    <CurrencyInput
                      {...input}
                      {...rest}
                      className={classes.inputWrapper}
                      onChange={(e: React.ChangeEvent<{ value: string }>) =>
                        input.onChange(e.target.value ? parseFloat(e.target.value) : undefined)
                      }
                      value={values.price}
                    />
                  )}
                </Field>
                <Box className={classes.flexRow}>
                  <Field name="quantity" validate={greaterThanZero}>
                    {({ input, ...rest }) => (
                      <NumberInput
                        {...input}
                        {...rest}
                        placeholder="Qty"
                        type="number"
                        inputProps={{ min: '1', step: '1' }}
                        className={classes.countInput}
                        onChange={(e: React.ChangeEvent<{ value: string }>) =>
                          input.onChange(e.target.value ? parseInt(e.target.value) : undefined)
                        }
                        value={values.quantity}
                      />
                    )}
                  </Field>
                  <Field name="markup" validate={greaterThanOrEqualZero}>
                    {({ input, ...rest }) => (
                      <NumberInput
                        {...input}
                        {...rest}
                        placeholder="0"
                        type="number"
                        inputProps={{ min: '0' }}
                        className={classes.inputWrapper}
                        onChange={(e: React.ChangeEvent<{ value: string }>) =>
                          input.onChange(e.target.value ? parseInt(e.target.value) : undefined)
                        }
                        value={values.markup}
                      />
                    )}
                  </Field>
                  <Field name="cost" type="hidden" component="input" readOnly />
                </Box>
              </Box>
            </Box>
            {!pristine &&
              Object.entries(errors).map(([key, value]: [string, any]) => (
                <Typography component="p" color="error" key={key} className={classes.errorMessage}>
                  {key} <span className={classes.lowercase}>{value}</span>
                </Typography>
              ))}

            <JobItemHighlightedText>{currencyFormat(values.cost)}</JobItemHighlightedText>
          </ItemForm>
        </form>
      )}
    />
  );
};

const getTotalCost = ({
  price,
  quantity,
  markup = 0,
}: {
  price?: number;
  quantity?: number;
  markup?: number;
}): number => {
  if (!price || !quantity) return 0;

  const totalPrice = price * quantity;
  return totalPrice + totalPrice * (markup / 100);
};
