import { colors } from '../styles';
import theme from '../styles/theme';
import { IServiceAreaBase } from './IJob';

export enum JobStatus {
  NOTIFYING = 0,
  EN_ROUTE = 1,
  COMPLETED = 2,
  CANCELED = 3,
  IN_PROGRESS = 4,
  UNASSIGNED = 5,
  QUEUED = 8,
  PAUSED = 10,
  MANUAL = 11,
}

export enum RequestStatus {
  NOTIFYING = 0,
  DECLINED = 1,
  NO_RESPONSE = 2,
  ACCEPTED = 3,
  LOST = 4,
  CANCELED = 5,
}

export enum JobTabs {
  VEHICLE = 0,
  CONTACTS = 1,
  PAYMENTS = 2,
  INVOICE = 3,
}

export enum JobDrawerTabs {
  NOTIFICATIONS = 0,
  LABOR = 1,
  PARTS = 2,
  COMMENTS = 3,
  PAYOUT = 4,
  PHOTOS = 5,
}

export enum PaymentMethod {
  CARD = 'card',
  EFS = 'EFS',
  CASH = 'cash',
  COMCHECK = 'comcheck',
  DISCOUNT = 'discount',
  OTHER = 'other',
}

// @TODO update statuses
export const statuses = [
  { id: JobStatus.NOTIFYING, name: 'notifying', color: colors.texasRose },
  { id: JobStatus.EN_ROUTE, name: 'en route', color: colors.bostonBlue },
  { id: JobStatus.COMPLETED, name: 'completed', color: colors.jade },
  { id: JobStatus.CANCELED, name: 'canceled', color: colors.red },
  { id: JobStatus.IN_PROGRESS, name: 'in progress', color: colors.mossGreen },
  { id: JobStatus.UNASSIGNED, name: 'unassigned', color: colors.rollingStone },
  { id: JobStatus.QUEUED, name: 'queued', color: colors.texasRose },
  { id: JobStatus.PAUSED, name: 'paused', color: colors.funGreen },
  { id: JobStatus.MANUAL, name: 'manual', color: colors.shark },
];

export const requestStatuses = [
  {
    id: RequestStatus.NOTIFYING,
    name: 'notifying',
    background: colors.texasRose,
    color: theme.palette.common.white,
  },
  {
    id: RequestStatus.DECLINED,
    name: 'declined',
    background: colors.bittersweet,
    color: theme.palette.common.white,
  },
  {
    id: RequestStatus.NO_RESPONSE,
    name: 'no response',
    background: colors.rollingStone,
    color: theme.palette.common.white,
  },
  {
    id: RequestStatus.ACCEPTED,
    name: 'accepted',
    background: colors.green,
    color: theme.palette.common.white,
  },
  {
    id: RequestStatus.LOST,
    name: 'lost',
    background: colors.sunshade,
    color: theme.palette.common.white,
  },
  {
    id: RequestStatus.CANCELED,
    name: 'canceled',
    background: theme.palette.common.white,
    color: theme.palette.error.main,
  },
];

export const trailerTypes = [
  { id: 0, name: 'none' },
  { id: 1, name: 'dry van' },
  { id: 2, name: 'reefer' },
  { id: 2, name: 'flat-bed' },
];

export const tirePosition = [
  { id: 0, name: 'drive' },
  { id: 1, name: 'steer' },
  { id: 0, name: 'trailer' },
];

export const tireCondition = [
  { id: 0, name: 'new' },
  { id: 1, name: 'used' },
  { id: 0, name: 'retread' },
];

export const tireDisposal = [
  { id: 0, name: 'dispose of tire' },
  { id: 1, name: 'leave with driver' },
];

export const locationDetailsOptions = [
  { name: 'Highway' },
  { name: 'Street' },
  { name: 'Parking lot' },
  { name: 'Driveway' },
  { name: 'Garage' },
  { name: 'On-ramp / Off-ramp' },
  { name: 'Rest stop' },
  { name: 'Weigh Station' },
];

export const vehicleTypes = [{ name: 'Truck' }, { name: 'Trailer' }, { name: 'Equipment' }];

export const paymentMethods = [
  { name: 'Cash', value: PaymentMethod.CASH },
  { name: 'Credit Card', value: PaymentMethod.CARD },
  { name: 'EFS Check', value: PaymentMethod.EFS },
  { name: 'Comchek', value: PaymentMethod.COMCHECK },
  { name: 'Discount', value: PaymentMethod.DISCOUNT },
  { name: 'Other', value: PaymentMethod.OTHER },
];

export const openedJobStatuses = [
  JobStatus.EN_ROUTE,
  JobStatus.IN_PROGRESS,
  JobStatus.NOTIFYING,
  JobStatus.PAUSED,
  JobStatus.QUEUED,
  JobStatus.UNASSIGNED,
  JobStatus.MANUAL,
];

export const closedJobStatuses = [JobStatus.CANCELED, JobStatus.COMPLETED];

export const allServiceAreasOption: IServiceAreaBase = {
  id: '6',
  name: 'All service areas',
  epicenter: {
    lat: '37.0902',
    long: '-95.7129',
  },
};

export enum ParticipantType {
  driver = 'Driver',
  dispatcher = 'Dispatcher',
  provider = 'Provider',
}

export enum PayType {
  CALLOUT = 'callout',
  HOURLY = 'hourly',
  AFTERHOUR_BONUS = 'afterhourBonus',
  RATING_BONUS = 'ratingBonus',
  PRO_PLUS_BONUS = 'proPlusBonus',
  CUSTOM = 'custom',
}

export enum PaymentStatus {
  SUCCEEDED = 'succeeded',
  CANCELED = 'canceled',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_ACTION = 'requires_action',
  REQUIRES_CAPTURE = 'requires_capture',
}

export enum PayoutMethod {
  STANDARD = 'standard',
  INSTANT = 'instant',
  CASH = 'cash',
}

export enum PayoutStatus {
  CREATED = 'created',
  PAID = 'paid',
  FAILED = 'failed',
  PENDING = 'pending',
  IN_TRANSIT = 'in_transit',
  CANCELED = 'canceled',
}
