import React from 'react';
import { Field, withTypes } from 'react-final-form';
import { Box, Typography } from '@material-ui/core';
import { ItemForm } from '../components/ItemForm';
import { TextInput } from '../../../components/Inputs/TextInput';
import { DateTimePicker } from '../../../components/DateTimePicker';
import {
  afterStartDate,
  beforeEndDate,
  composeValidators,
  required,
} from '../../../helpers/validationHelpers';
import { getPickerFormatDate } from '../../../DatePickersUtilsProvider';
import { ILaborValues } from './';
import { useStyles } from './styles';

const { Form } = withTypes<ILaborValues>();

const NEW_LABOR = {
  repairPeriodStartTime: getPickerFormatDate(new Date()),
  repairPeriodEndTime: getPickerFormatDate(new Date()),
};

export const LaborForm = ({
  data,
  providerFullName,
  handleSubmit,
  onCancelClick,
  id,
  inProgress,
}: {
  data?: ILaborValues;
  providerFullName: string;
  handleSubmit: any;
  onCancelClick: any;
  id: number;
  inProgress?: boolean;
}) => {
  const initialValues = data || NEW_LABOR;
  const classes = useStyles();

  const onSubmit = (values: ILaborValues) => {
    handleSubmit(values, id);
    onCancelClick();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, values, valid, errors, pristine }) => (
        <form onSubmit={handleSubmit}>
          <ItemForm onCancelClick={onCancelClick} isValid={!pristine && valid}>
            <Field name="provider">
              {({ input, ...rest }) => (
                <TextInput
                  {...input}
                  {...rest}
                  disabled={true}
                  className={classes.textInput}
                  placeholder={providerFullName}
                  validation={false}
                />
              )}
            </Field>

            <Box className={classes.wrapper}>
              <Box className={classes.inputWrapper}>
                <Field
                  name="repairPeriodStartTime"
                  validate={composeValidators(required, beforeEndDate(values.repairPeriodEndTime))}
                >
                  {({ input, ...rest }) => (
                    <DateTimePicker {...input} {...rest} maxDate={values.repairPeriodEndTime} />
                  )}
                </Field>
                <Typography component="span" className={classes.separator}>
                  to
                </Typography>
                <Field
                  name="repairPeriodEndTime"
                  disabled={inProgress}
                  validate={composeValidators(
                    required,
                    afterStartDate(values.repairPeriodStartTime),
                  )}
                >
                  {({ input, ...rest }) => (
                    <DateTimePicker {...input} {...rest} minDate={values.repairPeriodStartTime} />
                  )}
                </Field>
              </Box>
              {!pristine && (
                <Typography component="p" color="error">
                  {errors.repairPeriodStartTime || errors.repairPeriodEndTime}
                </Typography>
              )}
            </Box>
          </ItemForm>
        </form>
      )}
    />
  );
};
