import React from 'react';
import { Box, Typography } from '@material-ui/core';
// @ts-ignore
import { CreateButton, useListContext } from 'react-admin';
import { CreateIcon, EmptyStateIcon } from '../Icons';
import { font } from '../../styles';
import { useStyles } from './styles';

export const EmptyState = () => {
  const { basePath, resource } = useListContext();
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <EmptyStateIcon />
      <Typography variant="h5">
        <Box pt="15px" fontWeight={font.weight.bold}>
          No {resource} to be displayed yet.
        </Box>
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        <Box p="10px">Start by creating a new one by using a button below.</Box>
      </Typography>
      <Typography variant="subtitle1">
        <CreateButton
          label="CREATE"
          icon={<CreateIcon />}
          basePath={basePath}
          className={classes.createButton}
        />
      </Typography>
    </Box>
  );
};
