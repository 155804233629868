import { Box } from '@material-ui/core';
import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import { BottomToolbar } from '../../components/BottomToolbar';
import { JobsIcon } from '../../components/Icons';
import { InfoBox } from '../../components/InfoBox';
import { useFormStyles } from '../../styles/commonStyles';
import theme from '../../styles/theme';
import { CompanyCard } from '../components/CompanyCard';
import { LaborCard } from '../components/LaborCard';
import { CompanyRatesCard } from '../components/CompanyRatesCard';
import { SingleResourceHeader } from '../../components/SingleResourceHeader';
import { ICompany } from '../models/ICompany';
import { useFormState } from 'react-final-form';

export const EditCompanyPage = (props: any) => {
  const classes = useFormStyles();
  return (
    <Edit {...props} className={classes.root}>
      <SimpleForm toolbar={<BottomToolbar customRedirect="list" />} className={classes.form}>
        <Box display="flex" flexDirection="column">
          <SingleResourceHeader singular={props.options.singular} />
          <FormFields />
        </Box>
      </SimpleForm>
    </Edit>
  );
};

const FormFields = () => {
  const { values } = useFormState<ICompany>();
  const disallowedToChangeRates = values.rateOverride === false;
  return (
    <Box display="flex" p={3}>
      <Box display="flex" flexDirection="column" flexGrow={0.1}>
        <CompanyCard />
        <LaborCard controlsDisabled={disallowedToChangeRates} />
        <CompanyRatesCard controlsDisabled={disallowedToChangeRates} />
      </Box>
      <InfoBox
        title="Jobs"
        icon={<JobsIcon style={{ width: 16, height: 16 }} />}
        style={{ flexGrow: 1, marginLeft: theme.spacing(2) }}
      />
    </Box>
  );
};
