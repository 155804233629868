import React from 'react';
import { ArrayInput, FormDataConsumer, SimpleFormIterator } from 'react-admin';
import { Box, Button, Typography } from '@material-ui/core';
import { Add, DeleteOutline } from '@material-ui/icons';
import { boldDefaultBorder, useButtonStyles } from '../../styles/commonStyles';
import theme from '../../styles/theme';
import { CommonPartsIcon, ServiceQuestionIcon, SettingsIcon } from '../../components/Icons';
import { TextInput } from '../../components/Inputs/TextInput';
import { InfoBox } from '../../components/InfoBox';
import { FormParameterBox } from '../../components/FormParameterBox';
import { MultilineTextInput } from '../../components/Inputs/MultilineTextInput';
import { font } from '../../styles';
import { parseIdToIndex } from '../../helpers/formHelpers';
import { useStyles } from './styles';

export const CustomParametersCard = ({ values }: any) => {
  const classes = useStyles();

  return (
    <InfoBox
      icon={<SettingsIcon />}
      title="Custom parameters"
      style={{
        flexBasis: '100%',
        marginLeft: theme.spacing(2),
      }}
    >
      <CommonParts values={values} classes={classes} />
      <ServiceQuestions values={values} classes={classes} />
      <Disclaimer />
    </InfoBox>
  );
};

const CommonParts = ({ values, classes }: any) => (
  <FormParameterBox pt={1} title="Common parts">
    <ArrayInput source="commonParts" className={classes.iterator}>
      <SingleFieldIterator
        addButton={<AddListItemButton label="Add part" />}
        removeButton={<RemoveListItemButton />}
        label="Part name"
        placeholder="e.g. Tire"
        fieldSource="name"
      />
    </ArrayInput>
    {hasNoInputs('commonParts', values) && (
      <EmptyState plural="Common parts" singular="part" icon={CommonPartsIcon} />
    )}
  </FormParameterBox>
);

const ServiceQuestions = ({ values, classes }: any) => (
  <FormParameterBox title="Service questions">
    <ArrayInput source="questions" className={classes.iterator}>
      <SingleFieldIterator
        addButton={<AddListItemButton label="Add question" />}
        removeButton={<RemoveListItemButton />}
        label="Question"
        placeholder="e.g. What brand of tire do you prefer?"
        fieldSource="text"
      />
    </ArrayInput>
    {hasNoInputs('questions', values) && (
      <EmptyState plural="Service questions" singular="question" icon={ServiceQuestionIcon} />
    )}
  </FormParameterBox>
);

const Disclaimer = () => (
  <FormParameterBox title="Service disclaimer">
    <MultilineTextInput
      source="disclaimer"
      label="Disclaimer contents"
      placeholder="e.g. Tires cannot be returned once purchased."
      validation={false}
    />
  </FormParameterBox>
);

const AddListItemButton = (props: any) => {
  const classes = useButtonStyles();

  return (
    <Button {...props} className={classes.defaultButton}>
      <Add style={{ marginRight: theme.spacing(0.5) }} />
      {props.label}
    </Button>
  );
};

const RemoveListItemButton = (props: any) => {
  const classes = useButtonStyles();

  return (
    <Button {...props} className={classes.removeListItem}>
      <DeleteOutline />
    </Button>
  );
};

const SingleFieldIterator = (props: any) => {
  return (
    <SimpleFormIterator {...props}>
      <FormDataConsumer>
        {({ getSource, scopedFormData }: any) => {
          const source = getSource(props.fieldSource);

          return (
            <TextInput
              source={source}
              label={`${props.label} #${parseIdToIndex(source) + 1}`}
              record={scopedFormData}
              placeholder={props.placeholder}
              value={scopedFormData && scopedFormData[props.fieldSource]}
            />
          );
        }}
      </FormDataConsumer>
    </SimpleFormIterator>
  );
};

const EmptyState = (props: { plural: string; singular: string; icon: any }) => {
  return (
    <Box
      display="flex"
      py={5}
      alignItems="center"
      border={boldDefaultBorder}
      justifyContent="center"
      mt={-4}
      mb={2}
    >
      <props.icon style={{ padding: theme.spacing(2) }} />
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2" style={{ fontWeight: font.weight.semiBold }}>
          {`${props.plural} list will appear here.`}
        </Typography>
        <Typography variant="caption">
          {`Start adding its element by using "Add ${props.singular}" button above.`}
        </Typography>
      </Box>
    </Box>
  );
};

function hasNoInputs(source: string, values: any) {
  return !values.hasOwnProperty(source) || values[source].length === 0;
}
