import { IPickersDate } from '../DatePickersUtilsProvider';
import { formatPhoneNumber, parsePhoneNumber } from './formatHelpers';

export const required = (value: any) =>
  value === undefined || value === null ? 'Required' : undefined;

export const typeOfNumber = (value: any) =>
  typeof value !== 'number' ? 'Not a number' : undefined;

export const greaterThanOrEqualZero = (value: any) =>
  value < 0 ? 'Must be greater or equal zero' : undefined;

export const greaterThanZero = (value: any) =>
  value <= 0 ? 'Must be greater than zero' : undefined;

export const beforeEndDate = (endDate: IPickersDate | null) => (value: IPickersDate) =>
  value && endDate && value.toDate() > endDate.toDate()
    ? 'Start date should be before end date.'
    : undefined;

export const afterStartDate = (startDate: IPickersDate) => (value: IPickersDate | null) =>
  value && startDate && value.toDate() < startDate.toDate()
    ? 'Start date should be before end date.'
    : undefined;

export const composeValidators = (...validators: any[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const checked = (value: any) => !value;

export const PHONE_NUMBER_REGEX = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
export const PHONE_NUMBER_PLACEHOLDER = '(___) ___-____';
export const PHONE_NUMBER_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const validatePhoneNumber = (value: string) =>
  value && formatPhoneNumber(value)?.match(PHONE_NUMBER_REGEX) ? undefined : 'Required';

export const validatePhoneNumberUniqueness = (allPhoneNumbers: string[], phoneNumber: string) => {
  const parsedNumber = phoneNumber && parsePhoneNumber(phoneNumber);
  return allPhoneNumbers.includes(parsedNumber) ? { phoneNumber: 'taken' } : {};
};
