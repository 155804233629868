import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  commentText: {
    paddingLeft: spacing(4),
    paddingTop: spacing(1),
    color: palette.secondary.light,
  },
  avatar: {
    marginRight: spacing(1.5),
  },
  textInput: {
    marginBottom: spacing(1),
    paddingBottom: 0,
  },
  itemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));
