import { useForm } from 'react-final-form';
import { IServiceArea } from '../../Jobs/IJob';

export interface LocationFields {
  address: string;
  lat: number | string;
  long: number | string;
  type: string;
  city?: string;
  state?: string;
}

export function useLocationUpdate() {
  const form = useForm();

  return ({ address, lat, long, type, state, city }: LocationFields) => {
    form.batch(() => {
      form.change('location.address', address);
      form.change('location.lat', lat);
      form.change('location.long', long);
      form.change('location.type', type);
      form.change('location.state', state);
      form.change('location.city', city);
    });
  };
}

export function useServiceAreaUpdate() {
  const form = useForm();

  return ({ serviceArea }: { serviceArea: IServiceArea }) => {
    form.batch(() => {
      form.change('serviceArea', serviceArea);
    });
  };
}
