import React from 'react';
import { ListHeader } from '../../../components/ListHeader';
import {
  Box,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
} from '@material-ui/core';
import { TabLabel } from '../../../components/TabLabel';
import { convertSecondsToTime, formatDateWithToday } from '../../../helpers/timeHelpers';
import { IHeadCell, ListTableHead } from '../../../components/ListTableHead/ListTableHead';
import { SortOrder } from '../../../shared/common/SortOrder';
import { TablePagination } from '../../../components/TablePagination/TablePagination';
import { RowsPerPage } from '../../../shared/common/RowsPerPage';
import {
  BetterDateRange,
  DateRangePicker,
} from '../../../components/DateRangePicker/DateRangePicker';
import { Select } from '../../../shared/components/Select/Select';
import { useServiceAreas } from '../../../utils/hooks';
import { closedJobStatuses, JobStatus } from '../../JobUtils';
import JobStatusChip from '../../../components/Chips/JobStatusChip';
import { PendingReviewChip } from '../../../components/Chips/PendingReviewChip';
import { Statistic } from '../../../components/Ratings/Statistic';
import { StarIcon } from '../../../components/Icons';
import { colors } from '../../../styles';
import { BalanceField } from '../components/BalanceField';
import { parseJobNumber } from '../../../helpers/jobHelpers';
import { FullNameField } from '../../../components/FullNameField';
import { Loader } from '../../../components/Loader';
import { getProviderStatus } from '../../../helpers/providerHelpers';
import { useNotify } from 'react-admin';
import './JobListPage.css';
import { ListViewProps } from '../../../interfaces';
import { useQueryParams } from '../../hooks/useQueryParams';
import { TWENTY_SECONDS_IN_MILISECONDS } from '../../../shared/constants/time';
import { useDatabase } from '../../../DatabaseProvider';
import { JobListSortField } from '../models/JobListSortField';
import { JobListItemDto } from '../models/JobListItemDto';
import { JobKind } from '../models/JobKind';
import { GetJobListQuery } from '../models/GetJobListQuery';
import { GetJobListResponse } from '../models/GetJobListResponse';
import { JobListPageQueryProps } from '../models/JobListPageQueryProps';

export const headCells: IHeadCell<JobListSortField>[] = [
  { id: 'companyName', label: 'Company' },
  { id: 'driverName', label: 'Driver' },
  { id: 'status', label: 'Status' },
  { id: 'rating', label: 'Rating' },
  { id: 'providerName', label: 'Provider' },
  { id: 'serviceName', label: 'Service' },
  { id: 'serviceAreaName', label: 'Service Area' },
  { id: 'durationInSeconds', label: 'Duration', align: 'right' },
  { id: 'priceInDollars', label: 'Price', align: 'right' },
  { id: 'createdAt', label: 'Created', align: 'right' },
  { id: 'jobNumber', label: 'Job Number', align: 'right' },
];

const REFRESH_JOBS_INTERVAL = TWENTY_SECONDS_IN_MILISECONDS;

interface JobListPageProps extends ListViewProps {
  history: any;
}

export const JobListPage = (props: JobListPageProps) => {
  const { functions } = useDatabase();
  const notify = useNotify();
  const [jobs, setJobs] = React.useState<JobListItemDto[]>([]);
  const [totalJobCount, setTotalJobCount] = React.useState<number>(0);
  const [jobCountByKind, setJobCountByKind] = React.useState<Record<JobKind, number>>({
    open: 0,
    closed: 0,
    unpaid: 0,
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const { queryParams, setQueryParams } = useQueryParams<JobListPageQueryProps>({
    kind: JobKind.Open,
    page: '1',
    rowsPerPage: RowsPerPage.TWENTY_FIVE,
    sortOrder: SortOrder.DESC,
    sortField: 'jobNumber',
    startDate: null,
    endDate: null,
    serviceAreaId: null,
  });

  const kind = queryParams.kind;
  const page = +queryParams.page;
  const rowsPerPage = +queryParams.rowsPerPage;
  const sortOrder = queryParams.sortOrder;
  const sortField = queryParams.sortField;
  const startDateTimestamp = queryParams.startDate ? +queryParams.startDate : null;
  const endDateTimestamp = queryParams.endDate ? +queryParams.endDate : null;
  const serviceAreaId = queryParams.serviceAreaId;

  const fetchJobs = React.useCallback(async () => {
    if (!kind || !page || !rowsPerPage || !sortOrder || !sortField) {
      return;
    }
    const query: GetJobListQuery = {
      kind,
      serviceAreaId,
      startDate: startDateTimestamp ? new Date(startDateTimestamp).toISOString() : null,
      endDate: endDateTimestamp ? new Date(endDateTimestamp).toISOString() : null,
      sortField,
      sortOrder,
      pagination: { page, perPage: rowsPerPage },
    };

    const result = await functions.httpsCallable('getJobList')(query);
    const error = result.data.error;
    if (error) {
      notify(error.message, 'error');
      return;
    }
    const dto = result.data.data as GetJobListResponse;
    setJobs(dto.jobs);
    setJobCountByKind({
      open: dto.openJobCount,
      closed: dto.closedJobCount,
      unpaid: dto.unpaidJobCount,
    });
    setTotalJobCount(dto.total);
  }, [
    kind,
    page,
    rowsPerPage,
    sortOrder,
    sortField,
    startDateTimestamp,
    endDateTimestamp,
    serviceAreaId,
    notify,
    functions,
  ]);

  React.useEffect(() => {
    setIsLoading(true);
    fetchJobs().finally(() => {
      setIsLoading(false);
    });
  }, [fetchJobs]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      fetchJobs();
    }, REFRESH_JOBS_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchJobs]);

  const dateRange: BetterDateRange | undefined =
    startDateTimestamp && endDateTimestamp
      ? { startDate: new Date(startDateTimestamp), endDate: new Date(endDateTimestamp) }
      : undefined;

  const { serviceAreas } = useServiceAreas();

  return (
    <Box display="flex" flexDirection="column" minHeight="100%">
      <ListHeader
        {...props}
        additionalButtons={
          <>
            <Box ml={2}>
              <DateRangePicker
                value={dateRange}
                onChange={range => {
                  setQueryParams({
                    startDate: range.startDate.getTime().toString(),
                    endDate: range.endDate.getTime().toString(),
                    page: '1',
                  });
                }}
              />
            </Box>
            <Box ml={2} minWidth="266px">
              <Select
                value={serviceAreaId}
                emptyValueName="All service areas"
                options={serviceAreas.map(x => ({ id: x.id, name: x.name }))}
                onChange={serviceAreaId => {
                  setQueryParams({ serviceAreaId: serviceAreaId, page: '1' });
                }}
              />
            </Box>
          </>
        }
      >
        <Tabs value={kind} onChange={(e, tabId) => setQueryParams({ kind: tabId, page: '1' })}>
          <Tab label={<TabLabel text="Open" total={jobCountByKind.open} />} value={JobKind.Open} />
          <Tab
            label={<TabLabel text="Closed" total={jobCountByKind.closed} />}
            value={JobKind.Closed}
          />
          <Tab
            label={<TabLabel text="Unpaid" total={jobCountByKind.unpaid} isTotalBadgeRed />}
            value={JobKind.Unpaid}
          />
        </Tabs>
      </ListHeader>
      {isLoading ? (
        <Box flex={1}>
          <Loader text="Loading jobs..." />
        </Box>
      ) : (
        <>
          <Box mt={3} ml={3} mr={3} style={{ backgroundColor: '#ffffff' }}>
            <TableContainer>
              <Table className="table">
                <ListTableHead<JobListSortField>
                  sortSettings={{ order: sortOrder, orderBy: sortField }}
                  onSortSettingsChange={sortSettings => {
                    setQueryParams({
                      sortOrder: sortSettings.order,
                      sortField: sortSettings.orderBy,
                    });
                  }}
                  headCells={headCells}
                />
                <TableBody>
                  {jobs.map(x => (
                    <TableRow
                      key={x.id}
                      className="job-list-page__table-row"
                      onClick={() => props.history.push(`jobs/${x.id}`)}
                    >
                      <TableCell>{x.companyName}</TableCell>
                      <TableCell>{x.driverFullName}</TableCell>
                      <TableCell>
                        <JobStatusChip status={x.status} />
                        {x.pendingReview && closedJobStatuses.includes(x.status) && (
                          <PendingReviewChip />
                        )}
                      </TableCell>
                      <TableCell>
                        {x.rating ? (
                          <Statistic
                            label={x.rating}
                            endIcon={
                              <StarIcon
                                style={{ height: '11px', width: '12px', color: colors.texasRose }}
                              />
                            }
                          />
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {x.provider && (
                          <FullNameField
                            providerFullName={x.provider.fullName}
                            isProviderProPlus={x.provider.isProPlus}
                            providerStatus={getProviderStatus(x.provider.online, x.provider.onJob)}
                          />
                        )}
                      </TableCell>
                      <TableCell>{x.serviceName}</TableCell>
                      <TableCell>{x.serviceAreaName}</TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: x.status === JobStatus.IN_PROGRESS ? colors.jade : undefined,
                        }}
                      >
                        {convertSecondsToTime(x.durationInSeconds)}
                      </TableCell>
                      <TableCell>
                        <BalanceField
                          status={x.status}
                          balance={x.balanceInDollars}
                          price={x.priceInDollars}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {formatDateWithToday(new Date(x.createdAt))}
                      </TableCell>
                      <TableCell align="right">{parseJobNumber(x.jobNumber)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <TablePagination
            count={totalJobCount}
            paginationSettings={{ page, rowsPerPage }}
            onPaginationSettingsChange={paginationSettings =>
              setQueryParams({
                page: paginationSettings.page.toString(),
                rowsPerPage: paginationSettings.rowsPerPage,
              })
            }
          />
        </>
      )}
    </Box>
  );
};
