import React from 'react';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { UserAvatar } from '../../../components/UserAvatar';
import { JobDetailsDescription, JobItemHighlightedText } from '../components/EditableList';
import { IRepairPeriod, Timestamp } from '../../IJob';
import {
  convertSecondsToTime,
  formatDate,
  getDateFromFirestoreTimestamp,
  formatTime,
} from '../../../helpers/timeHelpers';
import { useTabStyles } from '../../../styles/commonStyles';
import { useStyles } from './styles';

export const LaborItem = ({
  providerName,
  item,
}: {
  providerName: string;
  item: IRepairPeriod;
}) => {
  const classes = useStyles();
  return (
    <>
      <UserAvatar name={providerName} status="offline" className={classes.avatar} width={20} />
      <JobDetailsDescription
        title={providerName}
        description={<LaborDescription data={item} withBoldedTime />}
      />
      <JobItemHighlightedText>
        {typeof item.durationInSeconds === 'number'
          ? convertSecondsToTime(item.durationInSeconds)
          : 'In Progress'}
      </JobItemHighlightedText>
    </>
  );
};

export const LaborDescription = ({
  data: { repairPeriodStartTime, repairPeriodEndTime },
  withBoldedTime,
}: {
  data: {
    repairPeriodStartTime: Timestamp;
    repairPeriodEndTime?: Timestamp;
  };
  withBoldedTime?: boolean;
}) => {
  const classes = useStyles();
  const tabClasses = useTabStyles();

  return (
    <Typography component="span" variant="body2" className={classes.laborDescription}>
      <span>{formatDate(getDateFromFirestoreTimestamp(repairPeriodStartTime))}</span>

      <span className={classnames(withBoldedTime && tabClasses.boldBlackText)}>
        {formatTime(getDateFromFirestoreTimestamp(repairPeriodStartTime))}
      </span>

      <span>to</span>

      <span className={classnames(withBoldedTime && tabClasses.boldBlackText)}>
        {repairPeriodEndTime
          ? formatTime(getDateFromFirestoreTimestamp(repairPeriodEndTime))
          : 'now'}
      </span>

      <span>
        {repairPeriodEndTime ? formatDate(getDateFromFirestoreTimestamp(repairPeriodEndTime)) : ''}
      </span>
    </Typography>
  );
};
