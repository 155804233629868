import React from 'react';
// @ts-ignore
import { Toolbar } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import theme from '../styles/theme';
import { SaveResourceButton } from './Buttons/SaveResourceButton';
import { CancelButton } from './Buttons/CancelButton';
import { useHistory } from 'react-router';
import BlockIcon from '@material-ui/icons/Block';

export const BottomToolbar = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box display="flex">
      <Toolbar {...props} className={classes.root}>
        <CancelButton
          onClick={() => history.push(`/${props.resource}`)}
          startIcon={<BlockIcon />}
        />
        <SaveResourceButton {...props} />
      </Toolbar>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    background: theme.palette.common.white,
    boxShadow: '0 0 8px rgba(35, 40, 46, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignSelf: 'flex-end',
    marginTop: 0,
  },
}));
