import React, { useState } from 'react';
// @ts-ignore
import { SaveButton, Toolbar, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import theme from '../styles/theme';
import { useButtonStyles } from '../styles/commonStyles';
import { ArrowBackOutlined, ArrowForwardOutlined } from '@material-ui/icons';
import { JobTabs } from './JobUtils';
import { IHistory } from '../interfaces';
import { Modal } from '../components/Modal';
import { SecondaryButton } from '../components/Buttons/SecondaryButton';

export const BottomToolbar = (props: any) => {
  const classes = useStyles();
  const [isModalVisible, setModalVisible] = useState(false);
  const [isBackToJobsModal, setBackToJobsModal] = useState(false);

  const goToList = () => {
    props.history.push(`/${props.resource}`);
  };

  const onBackToJobsClick = () => {
    if (props.pristine) {
      goToList();
    } else {
      setBackToJobsModal(true);
      setModalVisible(true);
    }
  };

  const goToPreviousStep = () => {
    props.setTabValue(props.tabValue - 1);
  };

  const onBackToPreviousStepClick = () => {
    props.pristine ? goToPreviousStep() : setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setBackToJobsModal(false);
  };

  return (
    <Box display="flex">
      <Toolbar {...props} className={classes.root}>
        <SecondaryButton onClick={onBackToJobsClick}>Back to jobs</SecondaryButton>
        <Box>
          <PreviousStepButton {...props} onClick={onBackToPreviousStepClick} />
          <NextStepButton {...props} />
        </Box>
      </Toolbar>
      <Modal
        open={isModalVisible}
        handleClose={closeModal}
        handleConfirm={isBackToJobsModal ? goToList : goToPreviousStep}
        title="Discard changes?"
        confirmText="Discard"
      >
        Your changes won't be saved if you leave now.
      </Modal>
    </Box>
  );
};

const PreviousStepButton = (props: any) => {
  const classes = useStyles();

  return (
    <SecondaryButton
      disabled={props.tabValue === 0}
      startIcon={<ArrowBackOutlined style={{ width: '20px', height: '20px' }} />}
      className={classes.previousButton}
      onClick={props.onClick}
    />
  );
};

const NextStepButton = (props: any) => {
  const buttonClasses = useButtonStyles();
  const notify = useNotify();

  return (
    <SaveButton
      {...props}
      className={buttonClasses.defaultButton}
      icon={<ArrowForwardOutlined style={{ display: 'none' }} />}
      endIcon={<ArrowForwardOutlined style={{ width: '20px', height: '20px' }} />}
      label={isLastTab(props.tabValue) ? 'save' : 'save & next'}
      disabled={props.disabled || props.invalid}
      onSuccess={({ data }: { data: { id: string } }) => {
        isCreateJobPage(props.location)
          ? handleSuccessOnCreateJobPage(props.history, data.id)
          : handleSuccessOnEditJobPage(props, notify);
      }}
    />
  );
};

const isLastTab = (tabValue: number) => tabValue === JobTabs.INVOICE;

const isCreateJobPage = (location: { pathname: string }) => location.pathname === '/jobs/create';

const handleSuccessOnCreateJobPage = (history: IHistory, jobId: string) =>
  history.push({
    pathname: `/jobs/${jobId}`,
    state: { prevLocation: 'create' },
  });

const handleSuccessOnEditJobPage = (
  props: { tabValue: number; setTabValue: any; history: IHistory; id: string },
  notify: any,
) =>
  isLastTab(props.tabValue)
    ? handleSaveOnLastTab(props.history, props.id, notify)
    : switchToNextTab(props);

const switchToNextTab = ({ tabValue, setTabValue }: { tabValue: number; setTabValue: any }) => {
  setTabValue(tabValue + 1);
};

const handleSaveOnLastTab = (history: IHistory, jobId: string, notify: any) => {
  history.replace({ pathname: `/jobs/${jobId}` });
  notify('Job saved');
};

const useStyles = makeStyles(() => ({
  root: {
    background: theme.palette.common.white,
    boxShadow: '0 0 8px rgba(35, 40, 46, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignSelf: 'flex-end',
    marginTop: 0,
  },
  previousButton: {
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: 0,
    minWidth: '40px',
  },
}));
