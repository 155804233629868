import React from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { JobDetailsDescription } from '../components/EditableList';
import { IComment } from '../../IJob';
import { formatDate, formatTime, getDateFromNumber } from '../../../helpers/timeHelpers';
import { UserAvatar } from '../../../components/UserAvatar';
import { useTabStyles } from '../../../styles/commonStyles';
import { useStyles } from './styles';

export const CommentItem = ({ item }: { item: IComment }) => {
  const classes = useStyles();
  return (
    <Box className={classes.itemWrapper}>
      <Box display="flex">
        <UserAvatar name={item.authorName} status="offline" className={classes.avatar} width={20} />
        <JobDetailsDescription title={item.authorName} />
        <CommentDate data={item} />
      </Box>
      <Typography component="span" variant="body2" className={classes.commentText}>
        {item.text}
      </Typography>
    </Box>
  );
};

export const CommentDate = ({
  data: { createdate },
}: {
  data: {
    createdate: number;
  };
}) => {
  const tabClasses = useTabStyles();

  return (
    <Typography component="span" variant="caption">
      {createdate && (
        <span className={tabClasses.date}>{formatDate(getDateFromNumber(createdate))}</span>
      )}{' '}
      {createdate && (
        <span className={classnames(tabClasses.boldBlackText)}>
          {formatTime(getDateFromNumber(createdate))}
        </span>
      )}
    </Typography>
  );
};
