import { COLLECTIONS } from '../utils';
import { useDatabase } from '../DatabaseProvider';
import { IRequest } from '../../../types/src';

export interface IRequestsRepository {
  getJobRequests(jobId: string): Promise<IRequest[]>;
}

export class RequestsRepository implements IRequestsRepository {
  private database = useDatabase().database;
  private static instance: IRequestsRepository | undefined;

  static getRepository(): IRequestsRepository {
    if (!this.instance) {
      this.instance = new RequestsRepository();
    }
    return this.instance;
  }
  async getJobRequests(jobId: string): Promise<IRequest[]> {
    const requestDocs = await this.database
      .collection(COLLECTIONS.REQUESTS)
      .where('jobId', '==', jobId)
      .orderBy('createdate', 'asc')
      .get();

    return requestDocs.docs.map(
      requestDoc => ({ ...requestDoc.data(), id: requestDoc.id } as IRequest),
    );
  }
}
