import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  laborDescription: {
    lineHeight: '18px',
    '& > span:not(:last-child)': {
      marginRight: spacing(0.5),
    },
  },
  avatar: {
    marginRight: spacing(1.5),
  },
  textInput: {
    marginBottom: spacing(1),
    paddingBottom: 0,
    '& > .MuiFilledInput-root.Mui-disabled': {
      color: palette.common.black,
    },
  },
  dateTimeInput: {
    '& > .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0',
    },
  },
  wrapper: {
    marginBottom: spacing(1),
  },
  inputWrapper: {
    display: 'flex',
    marginBottom: spacing(1),
  },
  separator: {
    lineHeight: '40px',
    margin: `0 ${spacing(1)}px`,
  },
}));
