import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Box, Typography } from '@material-ui/core';
import { IJob, IPayment } from '../../IJob';
import { PaymentsRepository } from '../../PaymentsRepository';
import { JobStatus, PaymentMethod, paymentMethods } from '../../JobUtils';
import { TextInput } from '../../../components/Inputs/TextInput';
import { currencyFormat } from '../../../helpers/formatHelpers';
import { CurrencyInput } from '../../../components/Inputs/CurrencyInput';
import { SelectInput } from '../../../components/Inputs/SelectInput';
import { AddPaymentButton } from './AddPaymentButton';
import { CheckoutForm } from './CheckoutForm';
import { useStyles } from './styles';
import { PaymentHistorySection } from './PaymentsHistorySection';
import { useBalance } from '../../hooks/useBalance';
import { Loader } from '../../../components/Loader';

interface FormFieldsProps {
  job: IJob;
}

export const FormFields = ({ job }: FormFieldsProps) => {
  const paymentsRepository = PaymentsRepository.getRepository();
  const [payments, setPayments] = useState<IPayment[]>([]);
  const isJobInProgress = job.status === JobStatus.IN_PROGRESS;
  const { balance, isLoading } = useBalance(job.id, isJobInProgress);

  useEffect(() => {
    const getPayments = async () => {
      const payments = await paymentsRepository.getAllPaymentsForJob(job.id);
      setPayments(payments);
    };

    void getPayments();
  }, [paymentsRepository, job.id]);

  useEffect(() => {
    const unsubscribe = paymentsRepository.subscribeToJobPayments(job.id, setPayments);

    return () => unsubscribe();
  }, [paymentsRepository, job.id]);

  if (isLoading) return <Loader />;

  return (
    <Box display="flex" flexDirection="column">
      <PaymentDetailsSection balance={balance} />
      <ChargeSection jobId={job.id} balance={balance} providerId={job.providerId} />
      <PaymentHistorySection payments={payments} />
    </Box>
  );
};

interface PaymentDetailsSectionProps {
  balance: number;
}

const PaymentDetailsSection = ({ balance }: PaymentDetailsSectionProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" pt={3} px={3}>
      <TextInput
        source="payment.referenceNumber"
        label="Reference number / P.O. number"
        validation={false}
      />
      <SelectInput
        source="payment.method"
        label="Payment method"
        choices={paymentMethods}
        optionValue="value"
        validation={false}
        placeholder="Select payment method"
        defaultValue={paymentMethods[0].value}
      />
      <Typography variant="subtitle2" className={classes.balanceTitle}>
        Balance due
      </Typography>
      <Typography className={classes.balanceAmount}>{currencyFormat(balance)}</Typography>
    </Box>
  );
};

interface ChargeSectionProps {
  jobId: string;
  balance: number;
  providerId?: string | null;
}

const ChargeSection = ({ jobId, balance, providerId }: ChargeSectionProps) => {
  const classes = useStyles();
  const [amountError, setAmountError] = useState<string | null>(null);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);
  const { values } = useFormState();
  const form = useForm();
  const disabled = !values.amount || !!amountError || !providerId;
  const isCash = values.payment?.method === PaymentMethod.CASH;
  const ELEMENTS_OPTIONS = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
      },
    ],
  };

  const validateAmountAndReturnErrorMessage = (amount: string): string | null => {
    if (amount === '') {
      return 'Amount is required';
    }

    if (parseFloat(amount) < 0.5) {
      return 'Amount is too small.';
    }

    return null;
  };

  return (
    <Box display="flex" flexDirection="column" pt={3} px={3}>
      <Box display="flex" alignItems="center">
        <CurrencyInput
          label="Amount to charge"
          className={classes.charge}
          validation={false}
          value={values.amount}
          source="amount"
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            const error = validateAmountAndReturnErrorMessage(event.target.value);
            setAmountError(error);
          }}
        />
        {amountError && (
          <Typography variant="body2" className={classes.amountError}>
            {amountError}
          </Typography>
        )}
      </Box>
      {values.payment?.method !== PaymentMethod.CARD ? (
        <AddPaymentButton
          jobId={jobId}
          amount={values.amount}
          disabled={disabled}
          method={values.payment?.method}
          text={isCash ? 'Add cash' : 'Add payment'}
          providerId={isCash ? providerId : undefined}
          onSubmit={() => {
            form.change('amount', undefined);
          }}
        />
      ) : (
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <CheckoutForm
            amount={values.amount}
            jobId={values.id}
            balance={balance}
            disabled={!values.amount || !!amountError}
            onSubmit={() => {
              form.change('amount', undefined);
            }}
          />
        </Elements>
      )}
    </Box>
  );
};
