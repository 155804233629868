import React, { useState } from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { JobEditForm } from '../JobEditForm';
import { sanitizeWithKeys } from '../../../helpers/formHelpers';
import { FilledLogoIcon } from '../../../components/Icons';
import { PartsSection } from './PartsSection';
import { LaborSection } from './LaborSection';
import { PriceSection } from './PriceSection';
import { GeneralSection } from './GeneralSection';
import { DetailsSection } from './DetailsSection';
import { CommentsSection } from './CommentsSection';
import { useStyles } from './styles';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { InvoiceDataFormatter } from '../../../utils/InvoiceDataFormatter';
import { useInvoiceData } from '../../hooks/useInvoiceData';
import { Loader } from '../../../components/Loader';
import { JobStatus } from '../../JobUtils';
import { downloadInvoice } from '../../../components/Pdf/downloadInvoice';
import { SendInvoiceButton } from './SendInvoiceButton';
import { parseJobNumber } from '../../../helpers/jobHelpers';

export const InvoiceTab = (props: any) => {
  const classes = useStyles();
  const [isProcessingPdf, setProcessingPdf] = useState(false);
  const isJobInProgress = props.job.status === JobStatus.IN_PROGRESS;
  const { job } = props;
  const { invoiceData, isLoading } = useInvoiceData(job.id, isJobInProgress);
  if (isLoading || !invoiceData) return <Loader />;

  const formattedInvoiceData = new InvoiceDataFormatter(invoiceData).getInvoiceData();

  const onDownloadClick = () => {
    setProcessingPdf(true);
    downloadInvoice(formattedInvoiceData, `invoice_${parseJobNumber(props.job.jobNumber)}`)
      .catch(err => {
        console.log(err);
      })
      .finally(() => setProcessingPdf(false));
  };
  const dispatcherEmail: string | undefined = job.dispatcher?.email;
  const driverEmail: string | undefined = job.driver?.email;
  const predefinedEmailToSendInvoice = [dispatcherEmail, driverEmail].filter(x => !!x) as string[];
  return (
    <JobEditForm {...props} transform={sanitizeBeforeSubmit} className={classes.tabWrapper}>
      <Box display="flex" flexDirection="column" px={3}>
        <Box className={classes.tabHeader}>
          <FilledLogoIcon />
          <Box>
            <SendInvoiceButton
              jobId={job.id as string}
              jobNumber={parseJobNumber(job.jobNumber)}
              predefinedEmails={predefinedEmailToSendInvoice}
              invoiceData={formattedInvoiceData}
            />
            <PrimaryButton
              className={classes.downloadInvoiceButton}
              onClick={onDownloadClick}
              isProcessing={isProcessingPdf}
            >
              Download invoice
            </PrimaryButton>
          </Box>
        </Box>
        <PriceSection {...formattedInvoiceData} />
        <GeneralSection {...formattedInvoiceData} />
        <DetailsSection {...formattedInvoiceData} />
        <LaborSection {...formattedInvoiceData} />
        <PartsSection {...formattedInvoiceData} />
        <CommentsSection {...formattedInvoiceData} />
      </Box>
    </JobEditForm>
  );
};

const sanitizeBeforeSubmit = (data: any) => sanitizeWithKeys(data, []);

interface InvoiceBlockProps {
  title?: string;
  className?: string;
}

export const InvoiceBlock: React.FC<InvoiceBlockProps> = ({ title, children, className }) => {
  const classes = useStyles();

  return (
    <Box className={classnames(classes.section, className)}>
      <Typography className={classes.title}>{title}</Typography>
      {children}
    </Box>
  );
};

export const InvoiceListItem = ({
  name,
  description,
  calculations,
  cost,
}: {
  name: string;
  description: string;
  calculations: string;
  cost: string;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.fieldsWrapper}>
      <Box display="flex" flexDirection="column" flex="1 1 50%">
        <Typography className={classnames(classes.item, classes.name)}>{name}</Typography>
        <Typography className={classes.item}>{description}</Typography>
      </Box>
      <Typography className={classes.item}>{calculations}</Typography>
      <Typography className={classnames(classes.item, classes.cost)}>{cost}</Typography>
    </Box>
  );
};
