import React from 'react';
import classnames from 'classnames';
import { AcceptRateIcon } from '../Icons';
import { Statistic } from './Statistic';
import { useStyles } from './styles';

export const AcceptRating = ({ value, ...props }: { value: number; className?: any }) => {
  const classes = useStyles();
  const rate = value || 0;

  return (
    <Statistic
      startIcon={
        <AcceptRateIcon
          className={classnames(
            { [classes.lowAcceptRate]: rate < 60 },
            { [classes.mediumAcceptRate]: rate >= 60 && rate < 80 },
            { [classes.highAcceptRate]: rate >= 80 },
          )}
        />
      }
      label={`${rate.toFixed(0)} %`}
      {...props}
    />
  );
};
