import React from 'react';
import { ReactComponent as AddressIcon } from '../../assets/addressIcon.svg';
import { ReactComponent as MailIcon } from '../../assets/mailIcon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/phoneIcon.svg';
import { ReactComponent as ProPlusBadgeIcon } from '../../assets/proPlusBadgeIcon.svg';
import { ReactComponent as ProBadgeIcon } from '../../assets/proBadgeIcon.svg';
import { ReactComponent as ApprovedIcon } from '../../assets/approvedIcon.svg';
import { ReactComponent as StreakIcon } from '../../assets/streakIcon.svg';
import { ReactComponent as AcceptRateIcon } from '../../assets/acceptRateIcon.svg';
import { ReactComponent as StarIcon } from '../../assets/starIcon.svg';
import { ReactComponent as ActivityIcon } from '../../assets/activityIcon.svg';
import { ReactComponent as RepairIcon } from '../../assets/repairIcon.svg';
import { ReactComponent as AcceptIcon } from '../../assets/acceptIcon.svg';
import { ReactComponent as StarRatingIcon } from '../../assets/starRatingIcon.svg';
import { ReactComponent as JobsDoneIcon } from '../../assets/jobsDoneIcon.svg';
import { ReactComponent as MapIcon } from '../../assets/map.svg';
import { ReactComponent as JobsIcon } from '../../assets/jobs.svg';
import { ReactComponent as ProvidersIcon } from '../../assets/providers.svg';
import { ReactComponent as ServicesIcon } from '../../assets/services.svg';
import { ReactComponent as CreateIcon } from '../../assets/createIcon.svg';
import { ReactComponent as EmptyStateIcon } from '../../assets/emptyStateIcon.svg';
import { ReactComponent as TireIcon } from '../../assets/tireIcon.svg';
import { ReactComponent as EngineIcon } from '../../assets/engineIcon.svg';
import { ReactComponent as OilIcon } from '../../assets/oilIcon.svg';
import { ReactComponent as TrailerIcon } from '../../assets/trailerIcon.svg';
import { ReactComponent as FuelIcon } from '../../assets/fuelIcon.svg';
import { ReactComponent as ServiceAreaIcon } from '../../assets/serviceAreaIcon.svg';
import { ReactComponent as SidebarOpenIcon } from '../../assets/sidebarOpenIcon.svg';
import { ReactComponent as SidebarClosedIcon } from '../../assets/sidebardClosedIcon.svg';
import { ReactComponent as LogoWithSlogan } from '../../assets/logoWithSlogan.svg';
import { ReactComponent as QuestionIcon } from '../../assets/questionMark.svg';
import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as KeyIcon } from '../../assets/keyIcon.svg';
import { ReactComponent as UserIcon } from '../../assets/userIcon.svg';
import { ReactComponent as InfoIcon } from '../../assets/infoIcon.svg';
import { ReactComponent as PadlockIcon } from '../../assets/padlockIcon.svg';
import { ReactComponent as VisibilityOnIcon } from '../../assets/visibilityOnIcon.svg';
import { ReactComponent as VisibilityOffIcon } from '../../assets/visibilityOffIcon.svg';
import { ReactComponent as Profile } from '../../assets/profile.svg';
import { ReactComponent as ServiceAreaNameIcon } from '../../assets/serviceAreaNameIcon.svg';
import { ReactComponent as EpicenterIcon } from '../../assets/epicenterIcon.svg';
import { ReactComponent as PayRateIcon } from '../../assets/payRateIcon.svg';
import { ReactComponent as MapPreviewIcon } from '../../assets/mapPreviewIcon.svg';
import { ReactComponent as DollarIcon } from '../../assets/dollarIcon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settingsIcon.svg';
import { ReactComponent as ClockIcon } from '../../assets/clockIcon.svg';
import { ReactComponent as CommonPartsIcon } from '../../assets/commonPartsIcon.svg';
import { ReactComponent as ServiceQuestionIcon } from '../../assets/serviceQuestionIcon.svg';
import { ReactComponent as SteeringWheelIcon } from '../../assets/steeringWheelIcon.svg';
import { ReactComponent as BillIcon } from '../../assets/billIcon.svg';
import { ReactComponent as MapPinIcon } from '../../assets/mapPin.svg';
import { ReactComponent as NoMapPinIcon } from '../../assets/noMapPin.svg';
import { ReactComponent as TruckIcon } from '../../assets/truck.svg';
import { ReactComponent as LeftArrowsIcon } from '../../assets/leftArrows.svg';
import { ReactComponent as RightArrowsIcon } from '../../assets/rightArrows.svg';
import { ReactComponent as DefaultAvatarIcon } from '../../assets/defaultAvatarIcon.svg';
import { ReactComponent as BatteryReplacementIcon } from '../../assets/batteryReplacementIcon.svg';
import { ReactComponent as BeltReplacementIcon } from '../../assets/beltReplacementIcon.svg';
import { ReactComponent as BrakeAirLineIcon } from '../../assets/brakeAirLineIcon.svg';
import { ReactComponent as BrakeChamberIcon } from '../../assets/brakeChamberIcon.svg';
import { ReactComponent as DotInspectionIcon } from '../../assets/dotInspectionIcon.svg';
import { ReactComponent as GenericIcon } from '../../assets/genericIcon.svg';
import { ReactComponent as JumpstartIcon } from '../../assets/jumpstartIcon.svg';
import { ReactComponent as LockoutIcon } from '../../assets/lockoutIcon.svg';
import { ReactComponent as OutOfDefIcon } from '../../assets/outOfDefIcon.svg';
import { ReactComponent as RefigerationIcon } from '../../assets/refigerationIcon.svg';
import { ReactComponent as TireIncludedIcon } from '../../assets/tireIncludedIcon.svg';
import { ReactComponent as UnknownIcon } from '../../assets/unknownIcon.svg';
import { ReactComponent as TowingIcon } from '../../assets/towingIcon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as HistoryIcon } from '../../assets/historyIcon.svg';
import { ReactComponent as FilledLogoIcon } from '../../assets/filledLogoIcon.svg';
import { ReactComponent as CompanyIcon } from '../../assets/companyIcon.svg';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { useStyles } from './styles';

const CardIcon = () => {
  const classes = useStyles();
  return <CreditCardIcon className={classes.card} />;
};

export {
  AddressIcon,
  MailIcon,
  PhoneIcon,
  ProPlusBadgeIcon,
  ProBadgeIcon,
  ApprovedIcon,
  StreakIcon,
  AcceptRateIcon,
  StarIcon,
  ActivityIcon,
  RepairIcon,
  AcceptIcon,
  StarRatingIcon,
  JobsDoneIcon,
  MapIcon,
  JobsIcon,
  ProvidersIcon,
  ServicesIcon,
  CreateIcon,
  EmptyStateIcon,
  TireIcon,
  EngineIcon,
  OilIcon,
  TrailerIcon,
  FuelIcon,
  ServiceAreaIcon,
  SidebarOpenIcon,
  SidebarClosedIcon,
  LogoWithSlogan,
  QuestionIcon,
  CheckIcon,
  Logo,
  KeyIcon,
  UserIcon,
  InfoIcon,
  PadlockIcon,
  VisibilityOnIcon,
  VisibilityOffIcon,
  Profile,
  ServiceAreaNameIcon,
  EpicenterIcon,
  PayRateIcon,
  MapPreviewIcon,
  DollarIcon,
  SettingsIcon,
  ClockIcon,
  CommonPartsIcon,
  ServiceQuestionIcon,
  SteeringWheelIcon,
  BillIcon,
  MapPinIcon,
  NoMapPinIcon,
  TruckIcon,
  LeftArrowsIcon,
  RightArrowsIcon,
  DefaultAvatarIcon,
  BatteryReplacementIcon,
  BeltReplacementIcon,
  BrakeAirLineIcon,
  BrakeChamberIcon,
  DotInspectionIcon,
  GenericIcon,
  JumpstartIcon,
  LockoutIcon,
  OutOfDefIcon,
  RefigerationIcon,
  TireIncludedIcon,
  UnknownIcon,
  TowingIcon,
  CalendarIcon,
  HistoryIcon,
  FilledLogoIcon,
  CompanyIcon,
  CardIcon,
};
