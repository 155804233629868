import { useForm } from 'react-final-form';
import { IService } from '../../Jobs/IJob';

export function useServiceUpdate() {
  const form = useForm();

  return ({
    name,
    description,
    hourly,
    minHours,
    flatRate,
    commonParts,
    questions,
    disclaimer,
    iconId,
  }: IService) => {
    form.batch(() => {
      form.change('service.name', name);
      form.change('service.description', description);
      form.change('service.hourly', hourly);
      form.change('service.minHours', minHours);
      form.change('service.flatRate', flatRate);
      form.change('service.commonParts', commonParts);
      form.change('service.questions', questions);
      form.change('service.disclaimer', disclaimer);
      form.change('service.iconId', iconId);
    });
  };
}
