import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDatabase } from '../DatabaseProvider';
import { FullNameField } from '../components/FullNameField';
import { colors, font } from '../styles';
import theme from '../styles/theme';
import { Box, Tab, Tabs } from '@material-ui/core';
import { TabLabel } from '../components/TabLabel';
import Typography from '@material-ui/core/Typography';
import {
  getProviderFullName,
  getProviderStatus,
  isProPlusAchieved,
} from '../helpers/providerHelpers';
import { ServiceIcon } from '../Services/ServiceUtils';
import { groupJobsByProvider, groupJobsByServiceArea } from '../helpers/jobHelpers';
import { defaultBorder, separatorBorder } from '../styles/commonStyles';
import { IProvider } from '../Providers/Provider.interface';
import { IJob } from '../Jobs/IJob';
import { parseToLatLngNumbers } from '../utils';

interface IJobsGrouped {
  [key: string]: any[];
}

interface JobsListSidebarProps {
  jobs: any;
  onJobLocationChange: (lat: number, lng: number) => void;
}

export const JobsListSidebar = ({ jobs, onJobLocationChange }: JobsListSidebarProps) => {
  const classes = useStyles();
  const { database } = useDatabase();

  const [providers, setProviders]: [any, any] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);

  // @ts-ignore
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const jobsGroupedByServiceArea = groupJobsByServiceArea(jobs);
  const jobsGroupedByProvider = groupJobsByProvider(jobs);
  const jobsCount = jobs.length;

  useEffect(() => {
    const getProviders = async () => {
      let providersGrouped: { [id: string]: string } = {};
      database
        .collection('providers')
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc: { id: string; data: any }) {
            providersGrouped[doc.id] = { id: doc.id, ...doc.data() };
          });
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
        });
      setProviders(providersGrouped);
    };

    void getProviders();
  }, [database]);

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between" pt={3} px={3} pb={2} alignItems="center">
        <Typography variant="h5" style={{ fontWeight: font.weight.bold }}>
          Jobs List
        </Typography>
        <Typography
          variant="body1"
          style={{ color: theme.palette.secondary.light, fontWeight: font.weight.bold }}
        >
          {jobsCount} results
        </Typography>
      </Box>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab
          value={0}
          label={
            <TabLabel text="Service areas" total={Object.keys(jobsGroupedByServiceArea).length} />
          }
        />
        <Tab
          value={1}
          label={<TabLabel text="Providers" total={Object.keys(jobsGroupedByProvider).length} />}
        />
      </Tabs>
      <Box className={classes.listWrapper}>
        {tabValue === 0 && (
          <JobListItems
            jobGroups={jobsGroupedByServiceArea}
            providers={providers}
            onJobLocationChange={onJobLocationChange}
          />
        )}
        {tabValue === 1 && (
          <JobListItems
            jobGroups={jobsGroupedByProvider}
            providers={providers}
            iteratee="provider"
            onJobLocationChange={onJobLocationChange}
          />
        )}
      </Box>
    </Box>
  );
};

const JobListItems = ({
  jobGroups,
  iteratee,
  providers,
  onJobLocationChange,
}: {
  jobGroups: IJobsGrouped;
  iteratee?: string;
  providers: any;
  onJobLocationChange: (lat: number, lng: number) => void;
}) => {
  const classes = useStyles();

  return (
    <>
      {Object.keys(jobGroups).map((key: string) => {
        return (
          <Box key={key}>
            <Box
              className={classes.group}
              py={1}
              pl={3}
              pr={2}
              display="flex"
              justifyContent="space-between"
              color={theme.palette.secondary.light}
            >
              <Typography variant="overline" style={{ fontWeight: font.weight.bold }}>
                {iteratee === 'provider' ? providerFullName(key, providers) : key}
              </Typography>
              <Typography variant="overline" style={{ fontWeight: font.weight.bold }}>
                {jobGroups[key].length}
              </Typography>
            </Box>
            {jobGroups[key].map((job: IJob) => (
              <JobItem
                key={job.id}
                job={job}
                provider={job.providerId && providers[job.providerId]}
                onJobLocationChange={onJobLocationChange}
              />
            ))}
          </Box>
        );
      })}
    </>
  );
};

const providerFullName = (providerId: string, providers: any) =>
  providers[providerId] ? getProviderFullName(providers[providerId]) : 'UNASSIGNED';

const JobItem = ({
  job,
  provider,
  onJobLocationChange,
}: {
  job: IJob;
  provider: IProvider;
  onJobLocationChange: (lat: number, lng: number) => void;
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.item}
      onClick={() => {
        const parsedCoordinates = parseToLatLngNumbers({
          lat: job.location.lat,
          long: job.location.long,
        });
        onJobLocationChange(parsedCoordinates.lat, parsedCoordinates.lng);
      }}
    >
      <Box pr={2} className={classes.icon}>
        <ServiceIcon service={job.service} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2" className={classes.serviceName}>
          {job.service?.name}
        </Typography>
        <Typography variant="body2" className={classes.address}>
          {job.location?.address}
        </Typography>
        <Box pt={1.5} className={classes.provider}>
          {provider && (
            <FullNameField
              providerFullName={getProviderFullName(provider)}
              providerStatus={getProviderStatus(provider.live, provider.onJob)}
              isProviderProPlus={isProPlusAchieved(provider)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '306px',
    overflow: 'hidden',
    boxShadow: '4px 0 5px -2px rgba(0,0,0,0.3)',
    borderRight: separatorBorder,
    background: palette.common.white,
    zIndex: 1,
  },
  listWrapper: {
    overflowY: 'auto',
  },
  group: {
    background: colors.catskillWhite,
  },
  icon: {
    '& svg': {
      border: defaultBorder,
    },
  },
  serviceName: {
    fontWeight: 'bold',
    color: palette.primary.dark,
  },
  address: {
    borderBottom: defaultBorder,
    color: palette.secondary.light,
    padding: '4px 0 11px 0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '200px',
  },
  provider: {
    '& div': {
      fontWeight: 'normal',
      fontSize: font.size.regular,
      lineHeight: '16px',
      '& span.badge': {
        margin: 0,
      },
    },
  },
  item: {
    display: 'flex',
    borderBottom: defaultBorder,
    padding: spacing(2),
    paddingRight: spacing(2),
    paddingLeft: spacing(3),
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 8px rgba(35, 40, 46, 0.1)',
    },
  },
}));
