import React, { Dispatch } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import { Box, Typography } from '@material-ui/core';
import { IProvider } from '../../Provider.interface';
import { ProviderInfoItem } from '../../ProviderInfoItem';
import { ProviderInfoWrapper } from '../../ProviderInfoWrapper';
import { UserAvatar } from '../../../components/UserAvatar';
import { StarRating } from '../../../components/Ratings/StarRating';
import { StreakRating } from '../../../components/Ratings/StreakRating';
import { AcceptRating } from '../../../components/Ratings/AcceptRating';
import { ProviderStatusBadge } from '../../../components/ProviderStatusBadge';
import { AddressIcon, MailIcon, PhoneIcon } from '../../../components/Icons';
import {
  getProviderFullName,
  getProviderStatus,
  isProPlusAchieved,
  ProBadge,
} from '../../../helpers/providerHelpers';
import { InfoBox } from '../../../components/InfoBox';
import { Loader } from '../../../components/Loader';
import { formatPhoneNumber } from '../../../helpers/formatHelpers';
import { PersonalInfoItem } from './PersonalInfoItem';
import { useStyles } from './styles';

export const InformationCard = ({
  provider,
  setIsEditing,
}: {
  provider: IProvider;
  setIsEditing: Dispatch<boolean>;
}) => {
  const classes = useStyles();

  return (
    <InfoBox
      title="information"
      setIsEditing={setIsEditing}
      icon={<PersonIcon className={classes.icon} />}
    >
      <Box display="flex" justifyContent="center" mb="12px">
        <UserAvatar
          name={getProviderFullName(provider)}
          width={92}
          status={getProviderStatus(provider.live, provider.onJob)}
        />
      </Box>
      <Typography variant="h6" component="h6" className={classes.name}>
        {provider.firstName ? getProviderFullName(provider) : <Loader />}
      </Typography>
      {provider.company && (
        <Typography variant="caption" component="p" className={classes.paragraph}>
          {provider.company}
        </Typography>
      )}
      <ProviderInfoWrapper>
        <ProviderInfoItem>
          {<ProBadge isProviderProPlus={isProPlusAchieved(provider)} />}
        </ProviderInfoItem>
        <ProviderInfoItem>
          <ProviderStatusBadge isBlocked={provider.disabled} withLabel={true} />
        </ProviderInfoItem>
        <ProviderInfoItem className={classes.ratings}>
          <StarRating value={provider.rating} />
          <AcceptRating value={provider.acceptRate} />
          <StreakRating value={provider.proDaysCount} />
        </ProviderInfoItem>
      </ProviderInfoWrapper>
      <PersonalInfoItem
        icon={<AddressIcon />}
        text={
          <>
            {provider.address}
            <br />
            {`${provider.city}, ${provider.state} ${provider.zip}`}
          </>
        }
        buttonText="MAP"
        title="Address"
        href={`https://www.google.com/maps?q=${provider.address}, ${provider.city}`}
      />
      <PersonalInfoItem
        icon={<MailIcon />}
        text={provider.email}
        buttonText="E-MAIL"
        title="E-mail address"
        href={`mailto:${provider.email}`}
      />
      <PersonalInfoItem
        icon={<PhoneIcon />}
        text={formatPhoneNumber(provider.phoneNumber)}
        buttonText="CALL"
        title="Phone number"
        href={`callto:${provider.phoneNumber}`}
      />
    </InfoBox>
  );
};
