import React, { useEffect } from 'react';
import { useDatabase } from '../../DatabaseProvider';
import { Header } from './Header';
import { DetailsTab } from './DetailsTab';
import { PayTab } from './PayTab';
import { Tab, Tabs } from '@material-ui/core';
import { TabLabel } from '../../components/TabLabel';
import { ProfileEdit } from './ProfileEdit';
import { Loader } from '../../components/Loader';
import { DocumentsTab } from './DocumentsTab';
import { ProviderEditTab } from './ProviderEditTab';

export const ProviderEdit: React.FC<{
  isProduction?: boolean;
  id?: string;
}> = ({ isProduction, ...props }) => {
  const { database } = useDatabase();
  const [provider, setProvider]: [any, any] = React.useState(null);
  const [tabValue, setTabValue] = React.useState<ProviderEditTab>(ProviderEditTab.Details);
  const [isProfileEditView, setIsProfileEditView] = React.useState(false);
  // @ts-ignore
  const handleChange = (event, newValue: ProviderEditTab) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const getProvider = async () => {
      const provider = await database.collection('providers').doc(props.id).get();
      setProvider(provider.data());
    };

    void getProvider();
  }, [database, props.id]);

  useEffect(() => {
    const unsubscribe = database
      .collection('providers')
      .doc(props.id)
      .onSnapshot(function (doc) {
        setProvider({ ...doc.data(), id: props.id });
      });
    return () => unsubscribe();
  }, [database, props.id]);

  if (!provider) return <Loader />;

  return isProfileEditView ? (
    <ProfileEdit {...props} provider={provider} setIsProfileEditView={setIsProfileEditView} />
  ) : (
    <>
      <Header provider={provider} />
      <Tabs value={tabValue} onChange={handleChange}>
        <Tab label={<TabLabel text="Details" />} value={ProviderEditTab.Details} />
        <Tab label={<TabLabel text="Pay" />} value={ProviderEditTab.Pay} />
        <Tab label={<TabLabel text="Documents" />} value={ProviderEditTab.Documents} />
      </Tabs>

      {tabValue === ProviderEditTab.Details && (
        <DetailsTab provider={provider} setIsEditing={setIsProfileEditView} />
      )}
      {tabValue === ProviderEditTab.Pay && <PayTab provider={provider} {...props} />}
      {tabValue === ProviderEditTab.Documents && <DocumentsTab providerId={props.id} />}
    </>
  );
};
