import React from 'react';
import { Button, ButtonProps } from './index';
import { useButtonStyles } from '../../styles/commonStyles';
import classnames from 'classnames';

export const SecondaryButton = ({ children, className, ...props }: ButtonProps) => {
  const classes = useButtonStyles();

  return (
    <Button
      className={classnames(classes.defaultButton, classes.reverseButton, className)}
      {...props}
    >
      {children}
    </Button>
  );
};
