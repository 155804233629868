import React from 'react';
import { FormDataConsumer } from 'react-admin';
import { Box } from '@material-ui/core';
import { ClockIcon, PayRateIcon } from '../../components/Icons';
import { CurrencyInput } from '../../components/Inputs/CurrencyInput';
import { SelectInput } from '../../components/Inputs/SelectInput';
import { NumberInput } from '../../components/Inputs/NumberInput';
import { OptionWithIconRenderer } from '../../helpers/formHelpers';
import { InfoBox } from '../../components/InfoBox';

interface ILaborCard {
  values: { flatRate?: number; minHours?: number; hourly?: boolean };
}

export const LaborCard = ({ values }: ILaborCard) => (
  <InfoBox icon={<PayRateIcon />} title="Labor" style={{ width: 'auto' }}>
    <Box px={2} display="flex" flexDirection="column">
      <SelectInput
        label="Pricing"
        value={values.hourly}
        source="hourly"
        choices={[
          { value: true, name: 'Hourly rate', icon: ClockIcon },
          { value: false, name: 'Flat rate', icon: PayRateIcon },
        ]}
        optionText={OptionWithIconRenderer}
        optionValue="value"
        defaultValue="true"
      />
      <FormDataConsumer>
        {({ formData }: any) =>
          formData.hourly ? (
            <NumberInput
              source="minHours"
              label="Minimum job hours"
              value={values.minHours}
              placeholder="e.g. 2"
              allowNegative={false}
            />
          ) : (
            <CurrencyInput source="flatRate" label="Flat rate" value={values.flatRate} />
          )
        }
      </FormDataConsumer>
    </Box>
  </InfoBox>
);
