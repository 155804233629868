import { COLLECTIONS } from '../utils';
import { useDatabase } from '../DatabaseProvider';
import { IPayout } from './IJob';

export interface IPayoutsRepository {
  getProviderPayouts(providerId: string): Promise<IPayout[]>;
}

export class PayoutsRepository implements IPayoutsRepository {
  private static instance: IPayoutsRepository | undefined;
  private database = useDatabase().database;

  static getRepository(): IPayoutsRepository {
    if (!this.instance) {
      this.instance = new PayoutsRepository();
    }
    return this.instance;
  }

  async getProviderPayouts(providerId: string): Promise<IPayout[]> {
    const payoutDocs = await this.database
      .collection(COLLECTIONS.PAYOUTS)
      .where('providerId', '==', providerId)
      .orderBy('createdate', 'desc')
      .get();

    return payoutDocs.docs.map(payoutDoc => payoutDoc.data() as IPayout);
  }
}
